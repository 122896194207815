import fixedCostService from '../services/fixedcost.service';
import moment from 'moment'


let today = new Date();
const getDefaultState = () => {
  return {
    partner_id: "",
    supplier_code: "",
    cost_type: 1,
    issue_date: moment(today).format("YYYY-MM-DD"),
    category_cost: "",
    supplier_inv_no: null,
    supplier_inv_date: null,
    cost_name: "",
    cost_amount: "",
    cost_tax: "",
    payment_due_date: "",
    actual_payment_date: null,
    cost_actual_amount: null,
    remark: "",
    status: 1,
    list_suppliers: []
  }
}

export const fixedCostAdd = {
    namespaced: true,
    state: {
        stateData: getDefaultState()
    },
    actions: {
      resetState({commit}) {
        commit("resetState");
      },
      saveFixedCost({commit}, data) {
        return fixedCostService.saveFixedCost(data).then(
          (response) => {
            return Promise.resolve(response);
          },
          (error) => {
            commit("saveFixedCostFailure");
            return Promise.reject(error);
          }
        );
      },
      getListSupplier({commit}, page=1, size=-1, type=1) {
        return fixedCostService.getListSupplier(page, size, type).then(
          (response) => {
            commit('getListSupplierSuccess', response);
            return Promise.resolve(response);
          },
          (error) => {
            commit('getListSupplierFailure');
            return Promise.reject(error);
          }
        );
      },
      getFxiedCostInfo({commit}, id) {
        return fixedCostService.getFxiedCostInfo(id).then(
          (response) => {
            commit('getFxiedCostInfoSuccess', response);
          },
          (error) => {
            commit('getFxiedCostInfoFailure');
            return Promise.reject(error);
          }
        );
      },
      updateFixedCost({commit}, data) {
        return fixedCostService.updateFixedCost(data).then(
          (response) => {
            return Promise.resolve(response);
          },
          (error) => {
            commit("updateFixedCostFailure");
            return Promise.reject(error);
          }
        );
      }
    },
    mutations: {
      resetState (state) {
        console.log("run resetState");
        Object.assign(state.stateData, getDefaultState());
      },
      getListSupplierSuccess(state, response) {
        state.stateData.list_suppliers = response.listItems
      },
      getListSupplierFailure(state) {
        state.stateData.list_suppliers = [];
      },
      getFxiedCostInfoSuccess(state, response) {
        state.stateData = {...state.stateData, ...response};
        state.stateData.supplier_id = response.partner_id;
      },
      getFxiedCostInfoFailure(state) {
        Object.assign(state.stateData, getDefaultState())
      }
    },
    getters: {

    }

}