<template>
  <!-- Content -->
  <div class="container-xxl flex-grow-1 container-p-y">
    <h4 class="fw-bold py-3 mb-4">
        <span class="text-muted fw-light">Partner /</span> Add New
    </h4>
    <div class="card mb-4">
      <h5 class="card-header">General Information</h5>
      <hr class="my-0">
      <div class="card-body">
        <form id="formAccountSettings" method="POST" onsubmit="return false" class="fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate">
          <!-- Custom Option Select-->
          <div class="row justify-content-center">
            <div class="col-xl-12 mb-3">
              <div class="row">
                <div class="col-md mb-md-0 mb-2">
                  <div class="form-check custom-option custom-option-basic" :class="{'checked': isSupplier}">
                    <label class="form-check-label custom-option-content" for="radioSupplier">
                      <input name="customer-supplier" 
                        class="form-check-input" 
                        type="radio" 
                        id="radioSupplier" 
                        v-bind:checked="isSupplier"
                        @change="isSupplierChecked"
                        v-bind:disabled="isDisabled"
                      >
                      <span class="custom-option-header">
                        <span class="h6 mb-0">SUPPLIER</span>
                      </span>
                      <span class="custom-option-body">
                        <small>The Bank information is required</small>
                      </span>
                    </label>
                  </div>
                </div>
                <div class="col-md">
                  <div class="form-check custom-option custom-option-basic" :class="{'checked': isCustomer}">
                    <label class="form-check-label custom-option-content" for="radioCustomer">
                      <input 
                        name="customer-supplier" 
                        class="form-check-input" 
                        type="radio" 
                        id="radioCustomer" 
                        v-bind:checked="isCustomer"
                        @change="isCustomerChecked"
                        v-bind:disabled="isDisabled"
                      >
                      <span class="custom-option-header">
                        <span class="h6 mb-0">CUSTOMER</span>
                      </span>
                      <span class="custom-option-body">
                        <small>The Bank information is NOT required</small>
                      </span>
                    </label>
                  </div>
                </div>
                <div class="col-md">
                  <div class="form-check custom-option custom-option-basic" :class="{'checked': isService}">
                    <label class="form-check-label custom-option-content" for="radioService">
                      <input 
                        name="customer-supplier" 
                        class="form-check-input" 
                        type="radio" 
                        id="radioService" 
                        v-bind:checked="isService"
                        @change="isServiceChecked"
                        v-bind:disabled="isDisabled"
                      >
                      <span class="custom-option-header">
                        <span class="h6 mb-0">SERVICE</span>
                      </span>
                      <span class="custom-option-body">
                        <small>The Bank informtion is required</small>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- / Custom Option Select-->
          <div class="row">
            <div class="mb-3 col-md-6 fv-plugins-icon-container">
              <label class="form-label">Partner Name</label> 
              <span class="m-1">(*)</span>
              <input class="form-control" type="text" name="partner-name" v-model="stateData.partner_name">
              <div class="error-msg" v-if="v$.stateData.partner_name.$error"> 
                {{ v$.stateData.partner_name.$errors[0].$message }}
              </div>
            </div>
            <div class="mb-3 col-md-3 fv-plugins-icon-container">
              <label for="lastName" class="form-label">Partner Short Name</label>
              <span class="m-1">(*)</span>
              <input class="form-control" type="text" name="partner-name" v-model="stateData.partner_short_name">
              <div class="error-msg" v-if="v$.stateData.partner_short_name.$error"> 
                {{ v$.stateData.partner_short_name.$errors[0].$message }}
              </div>
            </div>
            <div class="mb-3 col-md-3 fv-plugins-icon-container">
              <label class="form-label">Partner Code</label>
              <span class="m-1">(*)</span>
              <cleave placeholder="PM220101-001" 
                v-model="stateData.partner_code"
                :options="cleaveOptions.partnerCodeOpt"
                class="form-control"
                name="partner-code"
                @change="getPartnerCodeId(stateData.partner_code)"
                v-bind:disabled="isDisabled"
              />
              <div class="error-msg" v-if="v$.stateData.partner_code.$error"> 
                {{ v$.stateData.partner_code.$errors[0].$message }}
              </div>
              <input type="hidden" name="partner-code-id" v-model="stateData.partner_code_id">
              <div class="error-msg" v-if="v$.stateData.partner_code_id.$error"> 
                {{ v$.stateData.partner_code_id.$errors[0].$message }}
              </div>
            </div>
            <div class="mb-3 col-md-6 fv-plugins-icon-container">
              <label class="form-label">Person In Charge</label>
              <span class="m-1">(*)</span>
              <input class="form-control" type="text" name="person-in-charge" v-model="stateData.person_in_charge">
              <div class="error-msg" v-if="v$.stateData.person_in_charge.$error"> 
                {{ v$.stateData.person_in_charge.$errors[0].$message }}
              </div>
            </div>
            <div class="mb-3 col-md-6 fv-plugins-icon-container">
              <label class="form-label">TAX CODE</label>
              <input class="form-control" type="text" name="tax-code" v-model="stateData.tax_code">
            </div>
            <div class="mb-3 col-md-6">
              <label class="form-label">E-mail</label>
              <span class="m-1">(*)</span>
              <input class="form-control" 
                type="text" 
                name="email" 
                placeholder="partner-email@sample.com"
                v-model="stateData.email"
              >
              <div class="error-msg" v-if="v$.stateData.email.$error"> 
                {{ v$.stateData.email.$errors[0].$message }}
              </div>
            </div>
            <div class="mb-3 col-md-6">
              <label class="form-label" for="phoneNumber">Contact Number</label>
              <div class="input-group input-group-merge">
                <input class="form-control" 
                  type="text" 
                  name="phone" 
                  v-model="stateData.phone"
                >
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <!-- Address Information -->
    <div class="card mb-4">
      <h5 class="card-header">Address Information</h5>
      <hr class="my-0">
      <div class="card-body">
        <div class="row">
          <div class="mb-3 col-md-12 fv-plugins-icon-container">
            <label class="form-label">Address</label>
            <input class="form-control" 
              type="text" 
              name="address" 
              placeholder="AB Tower, Le Lai Street"
              v-model="stateData.address"
            >
          </div>
          <div class="mb-3 col-md-4 fv-plugins-icon-container">
            <label class="form-label">City</label>
            <VueMultiselect 
              v-model="citySelected" 
              :options="stateData.list_city" 
              placeholder="Please choose city" 
              label="name" 
              :close-on-select="true"
              :clear-on-select="false"
            />
          </div>
          <div class="mb-3 col-md-4 fv-plugins-icon-container">
            <label class="form-label">District</label>
            <VueMultiselect 
              v-model="districtSelected" 
              :options="stateData.list_district" 
              placeholder="Please choose district" 
              label="name" 
              :close-on-select="true"
              :clear-on-select="false"
            /> 
          </div>
          <div class="mb-3 col-md-4 fv-plugins-icon-container">
            <label for="lastName" class="form-label">Ward</label>
            <VueMultiselect 
              v-model="wardSelected" 
              :options="stateData.list_ward" 
              placeholder="Please choose ward" 
              label="name" 
              :close-on-select="true"
              :clear-on-select="false"
            />
          </div>
          <div class="mb-3 col-md-4 fv-plugins-icon-container">
            <label class="form-label">Country</label>
            <input 
              class="form-control" 
              type="text" 
              name="country"
              placeholder="Viet Nam"
              v-model="stateData.country"
            >
          </div>
          <div class="mb-3 col-md-4 fv-plugins-icon-container">
            <label class="form-label">Zip Code</label>
            <input 
              class="form-control" 
              type="text" 
              name="zipcode"
              placeholder="70000" 
              v-model="stateData.zipcode"
            >
          </div>

        </div>
      </div>
    </div>
    <!-- / Address Information -->
    <!-- Payment Information -->
    <div class="card mb-4">
      <h5 class="card-header">Payment Information</h5>
      <hr class="my-0">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12 mb-4">
            <label class="switch">
              <input 
                type="checkbox" 
                class="switch-input" 
                v-model="isDeposit"
                v-bind:checked="isDeposit"
                @change="onChangeIsDeposit"
              >
              <span class="switch-toggle-slider">
                <span class="switch-on">
                  <i class="bx bx-check"></i>
                </span>
                <span class="switch-off">
                  <i class="bx bx-x"></i>
                </span>
              </span>
              <span class="switch-label">HAS DEPOSIT</span>
            </label>
          </div>
          <!-- HAS NO DEPOSIT -->
          <div class="mb-3 col-md-4 fv-plugins-icon-container">
            <label class="form-label">Payment</label>
            <span class="m-1">(*)</span>
            <select 
              class="form-select" 
              name="select-payment-method"
              v-model="stateData.payment_method"
              @change="onChangePayment"
            >
              <option 
                v-for="(ele, index) in paymentMethodOptions" :key="index" :value="ele.value">
                {{ ele.name_en }}
              </option>
            </select>
            <div class="error-msg" v-if="v$.stateData.payment_method.$error"> 
              {{ v$.stateData.payment_method.$errors[0].$message }}
            </div>
          </div>
          <div class="mb-3 col-md-2 fv-plugins-icon-container" v-if="!isDeposit">
            <label for="firstName" class="form-label">Term</label>
            <span class="m-1">(*)</span>
            <input 
              class="form-control" 
              type="text" 
              name="term" 
              placeholder="20" 
              v-model="stateData.term"
              style="text-align:right;"
            >
            <div class="error-msg" v-if="v$.stateData.term.$error"> 
              {{ v$.stateData.term.$errors[0].$message }}
            </div>
          </div>
          <div class="mb-3 col-md-4 fv-plugins-icon-container" v-if="!isDeposit">
            <label for="lastName" class="form-label">Closing Time</label>
            <span class="m-1">(*)</span>
            <select 
              class="form-select" 
              name="select-closing-time"
              v-model="stateData.closing_time"
              @change="onChangeClosingTime"
            >
              <option 
                v-for="(ele, index) in closingTimeOptions" 
                :key="index" 
                :value="ele.id"
              >
                {{ ele.name }}
              </option>
            </select>
            <div class="error-msg" v-if="v$.stateData.closing_time.$error"> 
              {{ v$.stateData.closing_time.$errors[0].$message }}
            </div>
          </div>
          <div class="mb-3 col-md-2 fv-plugins-icon-container" v-if="!isDeposit && stateData.closing_time == 7">
            <label class="form-label">On Exact Date</label>
            <span class="m-1">(*)</span>
            <input 
              class="form-control" 
              type="text" 
              name="term" 
              placeholder="25" 
              style="text-align:right;"
              v-model="stateData.exact_date"
            >
            <div class="error-msg" v-if="v$.stateData.exact_date.$error"> 
              {{ v$.stateData.exact_date.$errors[0].$message }}
            </div>
          </div>
          <!-- /HAS NO DEPOSIT -->
          <!-- HAS DEPOSIT -->
          <div class="row" v-if="isDeposit">
            <div class="mb-3 col-md-3 fv-plugins-icon-container">
              <label class="form-label">Deposit</label>
              <span class="m-1">(*)</span>
              <div class="input-group">
                <input 
                  class="form-control" 
                  type="text" 
                  name="deposit" 
                  placeholder="% of deposit" 
                  style="text-align:right;"
                  v-model="stateData.percent_deposit"
                  @change="onchangePercentDeposit(stateData.percent_deposit)"
                >
                <span class="input-group-text">%</span>
              </div>
              <div class="error-msg" v-if="v$.stateData.percent_deposit.$error"> 
                {{ v$.stateData.percent_deposit.$errors[0].$message }}
              </div>
            </div>
            <div class="col-md-9"></div>
            <div class="mb-3 col-md-3 fv-plugins-icon-container">
              <label class="form-label">Balance</label>
              <span class="m-1">(*)</span>
              <div class="input-group">
                <input 
                  class="form-control" 
                  type="text" 
                  name="term-balance" 
                  placeholder="% of balance" 
                  style="text-align:right;"
                  v-model="stateData.percent_balance"
                  @change="onchangePercentBalance(stateData.percent_balance)"
                >
                <span class="input-group-text">%</span>
              </div>
              <div class="error-msg" v-if="v$.stateData.percent_balance.$error"> 
                {{ v$.stateData.percent_balance.$errors[0].$message }}
              </div>
            </div>
            <div class="mb-3 col-md-3 fv-plugins-icon-container">
              <label class="form-label">Term</label>
              <span class="m-1">(*)</span>
              <div class="input-group">
                <input 
                  class="form-control" 
                  type="text" 
                  name="term" 
                  placeholder="25" 
                  style="text-align:right;"
                  v-model="stateData.term"
                >
                <span class="input-group-text">days</span>
              </div>
              <div class="error-msg" v-if="v$.stateData.term.$error"> 
                {{ v$.stateData.term.$errors[0].$message }}
              </div>
            </div>
            <div class="mb-3 col-md-4 fv-plugins-icon-container">
              <label class="form-label">Closing Time</label>
              <span class="m-1">(*)</span>
              <select 
                class="form-select" 
                name="select-closing-time"
                v-model="stateData.closing_time"
                @change="onChangeClosingTime"
              >
                <option 
                  v-for="(ele, index) in closingTimeOptions" 
                  :key="index" 
                  :value="ele.id"
                >
                  {{ ele.name }}
                </option>
              </select>
              <div class="error-msg" v-if="v$.stateData.closing_time.$error"> 
                {{ v$.stateData.closing_time.$errors[0].$message }}
              </div>
            </div>
            <div class="mb-3 col-md-2 fv-plugins-icon-container" v-if="stateData.closing_time == 7">
              <label class="form-label">On Exact Date</label>
              <span class="m-1">(*)</span>
              <input 
                class="form-control" 
                type="text" 
                name="term" 
                placeholder="25" 
                style="text-align:right;"
                v-model="stateData.exact_date"
              >
              <div class="error-msg" v-if="v$.stateData.exact_date.$error"> 
                {{ v$.stateData.exact_date.$errors[0].$message }}
              </div>
            </div>
          </div>
          <!-- /HAS DEPOSIT -->
          <div class="col-md-12 mt-2">
            <div class="alert alert-primary" role="alert">
              <span class="alert-heading fw-bold">BANK INFORMATION</span>
              <div class="col-md-6 mt-3 mb-2">
                <label class="switch">
                  <input 
                    type="checkbox" 
                    class="switch-input" 
                    v-model="isVNBank"
                    v-bind:checked="isVNBank"
                  >
                  <span class="switch-toggle-slider">
                    <span class="switch-on">
                      <i class="bx bx-check"></i>
                    </span>
                    <span class="switch-off">
                      <i class="bx bx-x"></i>
                    </span>
                  </span>
                  <span class="switch-label" style="color:#696cff;">BANK OF VIETNAM</span>
                </label>
              </div>
              <div class="row">
                <div class="mt-3 col-md-6 fv-plugins-icon-container">
                  <VueMultiselect 
                    v-if="isVNBank"
                    v-model="bankSelected" 
                    :options="bankOptions" 
                    placeholder="Please choose bank name" 
                    label="name" 
                    :custom-label="nameWithBankCode"
                    :close-on-select="true"
                    :clear-on-select="false"
                  /> 
                  <input 
                    v-else
                    class="form-control" 
                    type="text" 
                    name="bank-name-foreign" 
                    placeholder="Fill the foreign bank name"
                    v-model="stateData.bank_name_foreign"
                  >
                </div>
                <div class="mt-3 col-md-6 fv-plugins-icon-container">
                  <input 
                    class="form-control" 
                    type="text" 
                    name="swift-code" 
                    placeholder="Fill the Swift Code Number"
                    v-model="stateData.swift_code"
                  >
                  <div class="error-msg" v-if="v$.stateData.swift_code.$error"> 
                    {{ v$.stateData.swift_code.$errors[0].$message }}
                  </div>
                </div>
                <div class="mt-3 col-md-6 fv-plugins-icon-container">
                  <input 
                    class="form-control" 
                    type="text" 
                    name="bank-account-name" 
                    placeholder="Fill your bank account name"
                    v-model="stateData.bank_account_name"
                  >
                  <div class="error-msg" v-if="v$.stateData.bank_account_name.$error"> 
                    {{ v$.stateData.bank_account_name.$errors[0].$message }}
                  </div>
                </div>
                <div class="mt-3 col-md-6 fv-plugins-icon-container">
                  <input 
                    class="form-control" 
                    type="text" 
                    name="bank-account-number" 
                    placeholder="Fill your bank account number"
                    v-model="stateData.bank_account_number"
                  >
                  <div class="error-msg" v-if="v$.stateData.bank_account_number.$error"> 
                    {{ v$.stateData.bank_account_number.$errors[0].$message }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="row"></div> -->
        <form id="" @submit.prevent="handleSave">
          <div class="row mt-5">
            <div class="col-sm-6">
              <button type="submit" class="btn btn-primary me-3">Save Partner</button>
              <router-link to="/partner" data-i18n="Add Product" class="btn btn-outline-secondary w-45">
                <i class='bx bx-arrow-back' ></i>
                Back To List
              </router-link>
            </div>
          </div>
        </form>
      </div>
    </div>
    <!-- / Payment Information -->
  </div>
  <!-- / Content -->
</template>

<script>
  import banks from "../../../public/assets/vendor/js/bank.js"
  import { CLOSING_TIME, PAYMENT_METHOD } from "../../config/common.js"
  import _ from 'lodash';
  import useValidate from '@vuelidate/core'
  import { required, requiredIf, numeric, helpers } from '@vuelidate/validators'
  import { mapState } from "vuex";
  import VueMultiselect from 'vue-multiselect'


  export default {
    name: 'PartnerAdd',
    components: {
        VueMultiselect
    },
    props: ["value"],
    data() {
      return {
        v$: useValidate(),
        isSupplier: true,
        isCustomer: false,
        isService: false,
        isVNBank: true,
        isDisabled: false,
        cleaveOptions: {
          partnerCodeOpt: {
            blocks: [8,3],
            delimiter: '-',
            uppercase: true
          }
        },
        bankSelected: null,
        citySelected: null,
        districtSelected: null,
        wardSelected: null,
        isDeposit: false
      }
    },
    computed: {
      ...mapState({
        stateData: state => state.partnerAdd.stateData
      }),
      bankOptions() {
        return banks;
      },
      closingTimeOptions() {
        return CLOSING_TIME;
      },
      paymentMethodOptions() {
        return PAYMENT_METHOD;
      }
    },
    watch: {
      'citySelected' (newVal) {
        this.stateData.list_district = [];
        this.stateData.list_ward = [];
        this.districtSelected = "";
        this.wardSelected = "";

        if (!newVal) {
          return true;
        }
        this.stateData.city = newVal.code;
        this.stateData.city_name = newVal.name;
        this.$store.dispatch('partnerAdd/getDistrict', newVal.code).then(
          () => { 
            let selectedDistrict = this.stateData.list_district.filter((district) => {
              return district.code == this.stateData.district;
            });
            this.districtSelected = selectedDistrict[0];
          },
          (error) => {
            console.log(error);
            this.$swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Can not get the District data !'
            });
          }
        );
      },
      'districtSelected' (newVal) {
        this.stateData.list_ward = [];
        this.wardSelected = "";

        if (!newVal) {
          return true;
        }
        this.stateData.district = newVal.code;
        this.stateData.district_name = newVal.name;
        this.$store.dispatch('partnerAdd/getWard', newVal.code).then(
          () => { 
            let selectedWard = this.stateData.list_ward.filter((ward) => {
              return ward.code == this.stateData.ward;
            });
            this.wardSelected = selectedWard[0]
          },
          (error) => {
            console.log(error);
            this.$swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Can not get the Ward data !'
            });
          }
        );
      },
      'wardSelected' (newVal) {
        if (!newVal) {
          return true;
        }
        this.stateData.ward = newVal.code;
        this.stateData.ward_name = newVal.name;
      },
      "isVNBank" (newVal) {
        if (!newVal) {
          this.stateData.bank_name_vn = "";
          this.stateData.swift_code = ""
        } else {
          this.stateData.bank_name_foreign = "";
          this.bankSelected = "";
        }
      },
      "bankSelected" (newVal) {
        if (newVal){
          this.stateData.swift_code = newVal.swift_code;
          this.stateData.bank_name_vn = newVal.code;
        }else {
          this.stateData.swift_code = ""
        }
      },
      "stateData.bank_name_foreign" (newVal) {
        if (newVal){
          this.stateData.bank_name = newVal;
        }
      },
      "isDeposit" (newVal) {
        this.stateData.has_deposit = newVal ? 1 : 0;
      }
    },
    beforeCreate() {
      this.$store.dispatch('partnerAdd/resetState');
    },
    created() {
      let query_param = this.$router.currentRoute._value.query;
      if (!_.isEmpty(query_param)){
        if (_.has(query_param, "partner_code")){
          this.stateData.partner_code = query_param.partner_code
          let partnerType = this.stateData.partner_code.charAt(1);
          if (partnerType == "M") {
            this.isSupplier = true;
            this.isCustomer = false;
            this.isService = false;
            this.stateData.type = 1;
          } 
          if (partnerType == "C") {
            this.isSupplier = false;
            this.isCustomer = true;
            this.isService = false;
            this.stateData.type = 2;
          }
          if (partnerType == "S") {
            this.isSupplier = false;
            this.isCustomer = false;
            this.isService = true;
            this.stateData.type = 3;
          }
          this.isDisabled = true;
        }
      }
      else {
        this.isSupplier = true;
        this.isCustomer = false;
        this.isService = false;
        this.stateData.type = 1;
      }
      this.$store.dispatch('partnerAdd/getCityList').then(
        () => { 
          console.log("Get City data successfully !!!");
        },
        (error) => {
          console.log(error);
          this.$swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Can not get the City code value !'
          });
        }
      );

      if (query_param.partner_code !== undefined){
        this.getPartnerCodeId(this.stateData.partner_code);
      }

    },
    methods: {
      // fuseSearch(options, search){
      //   const fuse = new Fuse(options, {
      //     keys: ['name', 'code'],
      //     shouldSort: true,
      //   });
      //   return search.length
      //     ? fuse.search(search).map(({ item }) => item)
      //     : fuse.list
      // },
      nameWithBankCode ({ name, code }) {
        return `${name} — [${code}]`
      },
      isSupplierChecked () {
        this.isSupplier = true;
        this.isCustomer = false;
        this.isService = false;
        this.stateData.type = 1;
        if (this.stateData.partner_code) {
          this.stateData.partner_code_id = "";
          this.stateData.partner_code = "";
        }
      },
      isCustomerChecked () {
        this.isSupplier = false;
        this.isCustomer = true;
        this.isService = false;
        this.stateData.type = 2;
        if (this.stateData.partner_code) {
          this.stateData.partner_code_id = "";
          this.stateData.partner_code = "";
        }
      },
      isServiceChecked() {
        this.isSupplier = false;
        this.isCustomer = false;
        this.isService = true;
        this.stateData.type = 3;
        if (this.stateData.partner_code) {
          this.stateData.partner_code_id = "";
          this.stateData.partner_code = "";
        }
      },
      getPartnerCodeId(code) {
        let partner_code = code
        if (!code.includes("-")){
          partner_code = code.slice(0,8) + "-" + code.slice(8);
        }
        this.$store.dispatch('partnerAdd/getPartnerRegByCode', {"page": 1, "searchKey":  partner_code})
        .then(
          (response) => { 
            if (response.listItems.length !== 1){
              this.stateData.partner_code_id = "";
              this.$swal.fire({
                icon: 'warning',
                title: 'Not Found',
                text: 'Not found the Partner Code ' + partner_code
              });
            }
            else {
              if (!this.checkPartnerCode(this.stateData.type, response.listItems[0].partner_code)) {
                this.stateData.partner_code_id = "";
                this.$swal.fire({
                  icon: 'warning',
                  title: 'Wrong Partner Type',
                  text: `The Partner Code ${partner_code} is NOT suitable for the partner type selected`
                });
              }
              else {
                let query_param = this.$router.currentRoute._value.query;
                if (query_param.partner_code == response.listItems[0].partner_code) {
                  this.isDisabled = true;
                }
              }
            }
          },
          (error) => {
            console.log(error);
            this.isDisabled = false;
            this.$swal.fire({
              icon: 'error',
              title: 'Network Error',
              text: 'Can not get the partner code ID !'
            });
          }
        );
      },
      checkPartnerCode(partner_type, partner_code) {
        if (partner_type && partner_code) {
          let typeCheck = partner_code.charAt(1) === "M" ? 1 
            : partner_code.charAt(1) === "C" ? 2 : 3
          if (typeCheck == partner_type) {
            return true;
          }
          return false;
        }
        return false;
      },
      handleSave() {
        this.v$.$validate();
        // Check partner type is suitable for partner code
        if (!this.checkPartnerCode(this.stateData.type, this.stateData.partner_code)) {
          this.stateData.partner_code_id = "";
        }
        if (!this.v$.$error) {
          
          if (this.isVNBank){
            this.stateData.bank_name = this.stateData.bank_name_vn;
          }
          else {
            this.stateData.bank_name = this.stateData.bank_name_foreign;
          }

          // Check The Deposit State
          this.isDeposit = this.stateData.has_deposit == 1 ? true : false;
          if (!this.isDeposit) {
            this.stateData.percent_deposit = null;
            this.stateData.percent_balance = null;
          }

          let request = {
            ...this.stateData
          }
          request = _.omit(request, ["list_city", "list_district", "list_ward"]);
          this.$store.dispatch('partnerAdd/savePartner', request).then(
            () => {
              let self = this;
              this.$swal.fire(
                'Saved',
                'Partner information is saved sucessfully !',
                'success'
              ).then(function() {
                self.$router.push('/partner');
              });
            },
            error => {
              console.log(error);
              this.$swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Failed to save Partner information !'
              });
            }
          );
        }
        else {
          window.scrollTo(0,0);
        }
      },
      onchangePercentDeposit (value){
        let parseVal = parseInt(value);
        if (isNaN(parseVal)) return;
        if (parseVal > 100 || parseVal <= 0) {
          this.stateData.percent_balance = "";
        }
        else {
          this.stateData.percent_balance = 100 - value;
        }
        
      },
      onchangePercentBalance (value){
        let parseVal = parseInt(value);
        if (isNaN(parseVal)) return;
        if (parseVal > 100 || parseVal <= 0) {
          this.stateData.percent_deposit = ""
        }
        else {
          this.stateData.percent_deposit = 100 - value;
        }
      },
      onChangeClosingTime () {
        this.stateData.exact_date = null;
      },
      onChangeIsDeposit () {
        this.isDisabled = !this.isDeposit;
        this.stateData.percent_deposit = null;
        this.stateData.percent_balance = null;
        this.stateData.term = null;
        this.stateData.closing_time = null;
      }
    },
    validations() {
      return {
        stateData: {
          partner_name: {
            required: helpers.withMessage('PARTNER NAME can not be empty', required)
          },
          partner_short_name: {
            required: helpers.withMessage('PARTNER SHORT NAME can not be empty', required)
          },
          partner_code: {
            required: helpers.withMessage('PARTNER CODE can not be empty', required)
          },
          partner_code_id: {
            required: helpers.withMessage('PARTNER CODE is not found', required)
          },
          person_in_charge: {
            required: helpers.withMessage('PARTNER CODE can not be empty', required)
          },
          // tax_code: {
          //   required: helpers.withMessage('TAX CODE can not be empty', required),
          //   numeric: helpers.withMessage('TAX CODE must be number', numeric),
          //   maxLength: helpers.withMessage('TAX CODE has maximum 10 numbers', maxLength(10)),
          // },
          email: {
            required: helpers.withMessage('EMAIL can not be empty', required)
          },
          // phone: {
          //   numeric: helpers.withMessage('CONTACT NUMBER must be number', numeric)
          // },
          payment_method: {
            required: helpers.withMessage('PAYMENT METHOD can not be empty', required)
          },
          term: {
            required: helpers.withMessage('TERM can not be empty', required),
            numeric: helpers.withMessage('TERM must be number', numeric)
          },
          closing_time: {
            required: helpers.withMessage('CLOSING TIME can not be empty', required),
            numeric: helpers.withMessage('CLOSING TIME must be number', numeric)
          },
          exact_date: {
            required: helpers.withMessage(
              'EXACT DATE can not be empty', 
              requiredIf (() => { return this.stateData.closing_time == 7; })
            ),
            numeric: helpers.withMessage('EXACT DATE must be number', numeric),
          },
          percent_deposit: {
            required: helpers.withMessage(
              'DEPOSIT can not be empty', 
              requiredIf (() => { return this.isDeposit; })
            )
          },
          percent_balance: {
            required: helpers.withMessage(
              'DEPOSIT can not be empty', 
              requiredIf (() => { return this.isDeposit; })
            )
          },
          bank_account_number: {
            required: helpers.withMessage(
              'BANK ACCOUNT NUMBER can not be empty', 
              requiredIf (() => { return this.isSupplier; })
            ),
            numeric: helpers.withMessage('BANK ACCOUNT NUMBER must be number', numeric),
          },
          bank_account_name: {
            required: helpers.withMessage(
              'BANK ACCOUNT NAME can not be empty', 
              requiredIf (() => { return this.isSupplier; })
            )
          },
          swift_code: {
            required: helpers.withMessage(
              'SWIFT CODE can not be empty', 
              requiredIf (() => { return this.isSupplier; })
            )
          }
        }
      }
    }
  }
</script>

<style>
  div.vs__dropdown-toggle {
    padding: 0 0 8px;
  }

  div.select-bank {
    background: white;
    border-radius: 0.375rem;
    color: #697a8d;
  }
  .error-msg {
    /* display: none; */
    width: 100%;
    margin-top: 0.3rem;
    font-size: 90%;
    color: #ff3e1d;
  }
  div.vs__dropdown-toggle {
    height: 2.5rem;
  }
</style>