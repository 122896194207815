import fixedCostService from '../services/fixedcost.service';
// import moment from 'moment'


const getDefaultState = () => {
  return {
    currentPage: 1,
    totalPages: 0,
    totalItems: 0,
    listItems: [],
    list_suppliers: [],
    total_lists_amount: {}
  }
}

export const fixedCostList = {
  namespaced: true,
  state: {
    stateData: getDefaultState()
  },
  actions: {
    resetState({commit}) {
      commit("resetState");
    },
    getListSupplier({commit}, page=1, size=-1, type=1) {
			return fixedCostService.getListSupplier(page, size, type).then(
				(response) => {
					// commit('getListSupplierSuccess', response);
					return Promise.resolve(response);
				},
				(error) => {
					commit('getListSupplierFailure');
					return Promise.reject(error);
				}
			);
		},
    searchFixedCost({commit}, conditions) {
      return fixedCostService.searchFixedCost(conditions).then(
        (response) => {
          // commit('searchFixedCostSuccess', response);
          return Promise.resolve(response);
        },
        (error) => {
          commit('searchFixedCostFailure');
          return Promise.reject(error);
        }
      );
    },
    deleteFixedCost ({commit}, id="") {
      return fixedCostService.deleteFixedCost(id).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          commit('deleteFailure');
          return Promise.reject(error);
        }
      );
    },
    getFxiedCostInfo({commit}, id) {
      return fixedCostService.getFxiedCostInfo(id).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          commit('getFxiedCostInfoFailure');
          return Promise.reject(error);
        }
      );
    }
  },
  mutations: {
    resetState (state) {
      console.log("run resetState");
      Object.assign(state.stateData, getDefaultState());
    },
		getListSupplierFailure(state) {
			state.stateData.list_suppliers = [];
		},
    searchFixedCostFailure() {
      console.log("run searchFixedCostFailure");
    },
    getFxiedCostInfoFailure(state) {
      Object.assign(state.stateData, getDefaultState())
    }
  },
  getters: {

  }
}