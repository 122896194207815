<template>
  <!-- Content -->
	<div class="">
		<ul class="list-group list-group-flush">
			<li class="list-group-item text-end total-cost">
				<span class="float-start fw-bold">#{{inv_detail.invoice_code}}</span>
				<span class="fw-bold">{{ formatCurrency(calculateTotalCost()) }}</span>
			</li>
			<li class="list-group-item text-end active">
				<span class="fw-bold">{{ formatCurrency(getImportExportFee(invoiceCostDetail)) }}</span>
			</li>
			<li class="list-group-item justify-content-between align-items-center p-0">
				<div class="input-group input-group-merge">
					<span class="input-group-text cost-input p-1" title="View" 
						data-bs-toggle="modal" data-bs-target="#viewContractCost"
						@click="viewContractCost(1)"
						v-if="fee_customs_duty.isViewIcon"
					>
						<i class="bx bxs-show fs-4 cursor-pointer"></i>
					</span>
					<span class="input-group-text cost-input p-1 cost-icon" title="Edit" 
						data-bs-toggle="modal" data-bs-target="#addContractCost"
						@click="addContractCost(1)"
						v-if="fee_customs_duty.isEditIcon"
					>
						<i class="bx bxs-edit fs-4 cursor-pointer"></i>
					</span>
					<span class="input-group-text cost-input p-1 cost-icon"
						title="Save"
						v-if="fee_customs_duty.isSaveIcon"
						@click="saveContractCost(1)"
					>
						<i class="bx bxs-save fs-4 cursor-pointer"></i>
					</span>
					<span class="input-group-text cost-input p-1 cost-icon-delete"
						title="Delete"
						v-if="fee_customs_duty.isDeleteIcon"
						@click="deleteContractCost(1)"
					>
						<i class="bx bx-x-circle fs-4 cursor-pointer"></i>
					</span>
					<cleave
						class="form-control text-end cost-input px-2"
						placeholder="0"
						v-model="fee_customs_duty.value"
						:options="cleaveOptions.fee"
					/>
					<span class="input-group-text cost-input p-1 text-warning" 
						title="Missing Information"
						v-if="fee_customs_duty.isMissingInfoIcon"
					>
						<span v-tooltip="'Missing Cost Information'">
							<i class="bx bx-info-circle fs-4 cursor-pointer"></i>
						</span>
					</span>
					<span class="input-group-text cost-input p-1 text-success" 
						title="Completed Information"
						v-if="fee_customs_duty.isAddedInfoIcon"
					>
						<span v-tooltip="'Completed Cost Information'">
							<i class="bx bxs-check-circle fs-4 cursor-pointer"></i>
						</span>
					</span>
				</div>
			</li>
			<li class="list-group-item justify-content-between align-items-center p-0">
				<div class="input-group input-group-merge">
					<span class="input-group-text cost-input p-1" title="View" 
						data-bs-toggle="modal" data-bs-target="#viewContractCost"
						@click="viewContractCost(2)"
						v-if="fee_freight.isViewIcon"
					>
						<i class="bx bxs-show fs-4 cursor-pointer"></i>
					</span>
					<span class="input-group-text cost-input p-1 cost-icon" title="Edit" 
						data-bs-toggle="modal" data-bs-target="#addContractCost"
						@click="addContractCost(2)"
						v-if="fee_freight.isEditIcon"
					>
						<i class="bx bxs-edit fs-4 cursor-pointer"></i>
					</span>
					<span class="input-group-text cost-input p-1 cost-icon-delete"
						title="Delete"
						v-if="fee_freight.isDeleteIcon"
						@click="deleteContractCost(2)"
					>
						<i class="bx bx-x-circle fs-4 cursor-pointer"></i>
					</span>
					<span class="input-group-text cost-input p-1 cost-icon" 
						title="Save"
						v-if="fee_freight.isSaveIcon"
						@click="saveContractCost(2)"
					>
						<i class="bx bxs-save fs-4 cursor-pointer"></i>
					</span>
					<cleave
						class="form-control text-end cost-input px-2"
						placeholder="0"
						v-model="fee_freight.value"
						:options="cleaveOptions.fee"
					/>
					<span class="input-group-text cost-input p-1 text-warning" 
						title="Missing Information"
						v-if="fee_freight.isMissingInfoIcon"
					>
						<span v-tooltip="'Missing Cost Information'">
							<i class="bx bx-info-circle fs-4 cursor-pointer"></i>
						</span>
					</span>
					<span class="input-group-text cost-input p-1 text-success" 
						title="Completed Information"
						v-if="fee_freight.isAddedInfoIcon"
					>
						<span v-tooltip="'Completed Cost Information'">
							<i class="bx bxs-check-circle fs-4 cursor-pointer"></i>
						</span>
					</span>
				</div>
			</li>
			<li class="list-group-item justify-content-between align-items-center p-0">
				<div class="input-group input-group-merge">
					<span class="input-group-text cost-input p-1" title="View" 
						data-bs-toggle="modal" data-bs-target="#viewContractCost"
						@click="viewContractCost(3)"
						v-if="fee_customs_lcc.isViewIcon"
					>
						<i class="bx bxs-show fs-4 cursor-pointer"></i>
					</span>
					<span class="input-group-text cost-input p-1 cost-icon" title="Edit" 
						data-bs-toggle="modal" data-bs-target="#addContractCost"
						@click="addContractCost(3)"
						v-if="fee_customs_lcc.isEditIcon"
					>
						<i class="bx bxs-edit fs-4 cursor-pointer"></i>
					</span>
					<span class="input-group-text cost-input p-1 cost-icon-delete"
						title="Delete"
						v-if="fee_customs_lcc.isDeleteIcon"
						@click="deleteContractCost(3)"
					>
						<i class="bx bx-x-circle fs-4 cursor-pointer"></i>
					</span>
					<span class="input-group-text cost-input p-1 cost-icon" 
						title="Save"
						v-if="fee_customs_lcc.isSaveIcon"
						@click="saveContractCost(3)"
					>
						<i class="bx bxs-save fs-4 cursor-pointer"></i>
					</span>
					<cleave
						class="form-control text-end cost-input-last px-2"
						placeholder="0"
						v-model="fee_customs_lcc.value"
						:options="cleaveOptions.fee"
					/>
					<span class="input-group-text cost-input p-1 text-warning" 
						title="Missing Information"
						v-if="fee_customs_lcc.isMissingInfoIcon"
					>
						<span v-tooltip="'Missing Cost Information'">
							<i class="bx bx-info-circle fs-4 cursor-pointer"></i>
						</span>
					</span>
					<span class="input-group-text cost-input p-1 text-success" 
						title="Completed Information"
						v-if="fee_customs_lcc.isAddedInfoIcon"
					>
						<span v-tooltip="'Completed Cost Information'">
							<i class="bx bxs-check-circle fs-4 cursor-pointer"></i>
						</span>
					</span>
				</div>
			</li>
		</ul>
		<ul class="list-group list-group-flush">
			<li class="list-group-item text-end active">
				<span class="fw-bold text-end">{{ formatCurrency(getTransportWarehouseFee(invoiceCostDetail)) }}</span>
			</li>
			<li class="list-group-item justify-content-between align-items-center p-0">
				<div class="input-group input-group-merge">
					<span class="input-group-text cost-input p-1" title="View" 
						data-bs-toggle="modal" data-bs-target="#viewContractCost"
						@click="viewContractCost(4)"
						v-if="fee_trucking.isViewIcon"
					>
						<i class="bx bxs-show fs-4 cursor-pointer"></i>
					</span>
					<span class="input-group-text cost-input p-1 cost-icon" title="Edit" 
						data-bs-toggle="modal" data-bs-target="#addContractCost"
						@click="addContractCost(4)"
						v-if="fee_trucking.isEditIcon"
					>
						<i class="bx bxs-edit fs-4 cursor-pointer"></i>
					</span>
					<span class="input-group-text cost-input p-1 cost-icon-delete"
						title="Delete"
						v-if="fee_trucking.isDeleteIcon"
						@click="deleteContractCost(4)"
					>
						<i class="bx bx-x-circle fs-4 cursor-pointer"></i>
					</span>
					<span class="input-group-text cost-input p-1 cost-icon" 
						title="Save"
						v-if="fee_trucking.isSaveIcon"
						@click="saveContractCost(4)"
					>
						<i class="bx bxs-save fs-4 cursor-pointer"></i>
					</span>
					<cleave
						class="form-control text-end cost-input px-2"
						placeholder="0"
						v-model="fee_trucking.value"
						:options="cleaveOptions.fee"
					/>
					<span class="input-group-text cost-input p-1 text-warning" 
						title="Missing Information"
						v-if="fee_trucking.isMissingInfoIcon"
					>
						<span v-tooltip="'Missing Cost Information'">
							<i class="bx bx-info-circle fs-4 cursor-pointer"></i>
						</span>
					</span>
					<span class="input-group-text cost-input p-1 text-success" 
						title="Completed Information"
						v-if="fee_trucking.isAddedInfoIcon"
					>
						<span v-tooltip="'Completed Cost Information'">
							<i class="bx bxs-check-circle fs-4 cursor-pointer"></i>
						</span>
					</span>
				</div>
			</li>
			<li class="list-group-item justify-content-between align-items-center p-0">
				<div class="input-group input-group-merge">
					<span class="input-group-text cost-input p-1" title="View" 
						data-bs-toggle="modal" data-bs-target="#viewContractCost"
						@click="viewContractCost(5)"
						v-if="fee_storage.isViewIcon"
					>
						<i class="bx bxs-show fs-4 cursor-pointer"></i>
					</span>
					<span class="input-group-text cost-input p-1 cost-icon" title="Edit" 
						data-bs-toggle="modal" data-bs-target="#addContractCost"
						@click="addContractCost(5)"
						v-if="fee_storage.isEditIcon"
					>
						<i class="bx bxs-edit fs-4 cursor-pointer"></i>
					</span>
					<span class="input-group-text cost-input p-1 cost-icon-delete"
						title="Delete"
						v-if="fee_storage.isDeleteIcon"
						@click="deleteContractCost(5)"
					>
						<i class="bx bx-x-circle fs-4 cursor-pointer"></i>
					</span>
					<span class="input-group-text cost-input p-1 cost-icon" 
						title="Save"
						v-if="fee_storage.isSaveIcon"
						@click="saveContractCost(5)"
					>
						<i class="bx bxs-save fs-4 cursor-pointer"></i>
					</span>
					<cleave
						class="form-control text-end cost-input px-2"
						placeholder="0"
						v-model="fee_storage.value"
						:options="cleaveOptions.fee"
					/>
					<span class="input-group-text cost-input p-1 text-warning" 
						title="Missing Information"
						v-if="fee_storage.isMissingInfoIcon"
					>
						<span v-tooltip="'Missing Cost Information'">
							<i class="bx bx-info-circle fs-4 cursor-pointer"></i>
						</span>
					</span>
					<span class="input-group-text cost-input p-1 text-success" 
						title="Completed Information"
						v-if="fee_storage.isAddedInfoIcon"
					>
						<span v-tooltip="'Completed Cost Information'">
							<i class="bx bxs-check-circle fs-4 cursor-pointer"></i>
						</span>
					</span>
				</div>
			</li>
			<li class="list-group-item justify-content-between align-items-center p-0">
				<div class="input-group input-group-merge">
					<span class="input-group-text cost-input p-1" title="View" 
						data-bs-toggle="modal" data-bs-target="#viewContractCost"
						@click="viewContractCost(6)"
						v-if="fee_labeling.isViewIcon"
					>
						<i class="bx bxs-show fs-4 cursor-pointer"></i>
					</span>
					<span class="input-group-text cost-input p-1 cost-icon" title="Edit" 
						data-bs-toggle="modal" data-bs-target="#addContractCost"
						@click="addContractCost(6)"
						v-if="fee_labeling.isEditIcon"
					>
						<i class="bx bxs-edit fs-4 cursor-pointer"></i>
					</span>
					<span class="input-group-text cost-input p-1 cost-icon-delete"
						title="Delete"
						v-if="fee_labeling.isDeleteIcon"
						@click="deleteContractCost(6)"
					>
						<i class="bx bx-x-circle fs-4 cursor-pointer"></i>
					</span>
					<span class="input-group-text cost-input p-1 cost-icon" 
						title="Save"
						v-if="fee_labeling.isSaveIcon"
						@click="saveContractCost(6)"
					>
						<i class="bx bxs-save fs-4 cursor-pointer"></i>
					</span>
					<cleave
						class="form-control text-end cost-input-last px-2"
						placeholder="0"
						v-model="fee_labeling.value"
						:options="cleaveOptions.fee"
					/>
					<span class="input-group-text cost-input p-1 text-warning" 
						title="Missing Information"
						v-if="fee_labeling.isMissingInfoIcon"
					>
						<span v-tooltip="'Missing Cost Information'">
							<i class="bx bx-info-circle fs-4 cursor-pointer"></i>
						</span>
					</span>
					<span class="input-group-text cost-input p-1 text-success" 
						title="Completed Information"
						v-if="fee_labeling.isAddedInfoIcon"
					>
						<span v-tooltip="'Completed Cost Information'">
							<i class="bx bxs-check-circle fs-4 cursor-pointer"></i>
						</span>
					</span>
				</div>
			</li>
		</ul>
		<ul class="list-group list-group-flush">
			<li class="list-group-item text-end active">
				<span class="fw-bold text-end">{{ formatCurrency(getTestingInsuranceFee(invoiceCostDetail)) }}</span>
			</li>
			<li class="list-group-item justify-content-between align-items-center p-0">
				<div class="input-group input-group-merge">
					<span class="input-group-text cost-input p-1" title="View" 
						data-bs-toggle="modal" data-bs-target="#viewContractCost"
						@click="viewContractCost(7)"
						v-if="fee_inspection.isViewIcon"
					>
						<i class="bx bxs-show fs-4 cursor-pointer"></i>
					</span>
					<span class="input-group-text cost-input p-1 cost-icon" title="Edit" 
						data-bs-toggle="modal" data-bs-target="#addContractCost"
						@click="addContractCost(7)"
						v-if="fee_inspection.isEditIcon"
					>
						<i class="bx bxs-edit fs-4 cursor-pointer"></i>
					</span>
					<span class="input-group-text cost-input p-1 cost-icon-delete"
						title="Delete"
						v-if="fee_inspection.isDeleteIcon"
						@click="deleteContractCost(7)"
					>
						<i class="bx bx-x-circle fs-4 cursor-pointer"></i>
					</span>
					<span class="input-group-text cost-input p-1 cost-icon" 
						title="Save"
						v-if="fee_inspection.isSaveIcon"
						@click="saveContractCost(7)"
					>
						<i class="bx bxs-save fs-4 cursor-pointer"></i>
					</span>
					<cleave
						class="form-control text-end cost-input px-2"
						placeholder="0"
						v-model="fee_inspection.value"
						:options="cleaveOptions.fee"
					/>
					<span class="input-group-text cost-input p-1 text-warning" 
						title="Missing Information"
						v-if="fee_inspection.isMissingInfoIcon"
					>
						<span v-tooltip="'Missing Cost Information'">
							<i class="bx bx-info-circle fs-4 cursor-pointer"></i>
						</span>
					</span>
					<span class="input-group-text cost-input p-1 text-success" 
						title="Completed Information"
						v-if="fee_inspection.isAddedInfoIcon"
					>
						<span v-tooltip="'Completed Cost Information'">
							<i class="bx bxs-check-circle fs-4 cursor-pointer"></i>
						</span>
					</span>
				</div>
			</li>
			<li class="list-group-item justify-content-between align-items-center p-0">
				<div class="input-group input-group-merge">
					<span class="input-group-text cost-input p-1" title="View" 
						data-bs-toggle="modal" data-bs-target="#viewContractCost"
						@click="viewContractCost(8)"
						v-if="fee_testing.isViewIcon"
					>
						<i class="bx bxs-show fs-4 cursor-pointer"></i>
					</span>
					<span class="input-group-text cost-input p-1 cost-icon" title="Edit" 
						data-bs-toggle="modal" data-bs-target="#addContractCost"
						@click="addContractCost(8)"
						v-if="fee_testing.isEditIcon"
					>
						<i class="bx bxs-edit fs-4 cursor-pointer"></i>
					</span>
					<span class="input-group-text cost-input p-1 cost-icon-delete"
						title="Delete"
						v-if="fee_testing.isDeleteIcon"
						@click="deleteContractCost(8)"
					>
						<i class="bx bx-x-circle fs-4 cursor-pointer"></i>
					</span>
					<span class="input-group-text cost-input p-1 cost-icon" 
						title="Save"
						v-if="fee_testing.isSaveIcon"
						@click="saveContractCost(8)"
					>
						<i class="bx bxs-save fs-4 cursor-pointer"></i>
					</span>
					<cleave
						class="form-control text-end cost-input px-2"
						placeholder="0"
						v-model="fee_testing.value"
						:options="cleaveOptions.fee"
					/>
					<span class="input-group-text cost-input p-1 text-warning" 
						title="Missing Information"
						v-if="fee_testing.isMissingInfoIcon"
					>
						<span v-tooltip="'Missing Cost Information'">
							<i class="bx bx-info-circle fs-4 cursor-pointer"></i>
						</span>
					</span>
					<span class="input-group-text cost-input p-1 text-success" 
						title="Completed Information"
						v-if="fee_testing.isAddedInfoIcon"
					>
						<span v-tooltip="'Completed Cost Information'">
							<i class="bx bxs-check-circle fs-4 cursor-pointer"></i>
						</span>
					</span>
				</div>
			</li>
			<li class="list-group-item justify-content-between align-items-center p-0">
				<div class="input-group input-group-merge">
					<span class="input-group-text cost-input p-1" title="View" 
						data-bs-toggle="modal" data-bs-target="#viewContractCost"
						@click="viewContractCost(9)"
						v-if="fee_sample.isViewIcon"
					>
						<i class="bx bxs-show fs-4 cursor-pointer"></i>
					</span>
					<span class="input-group-text cost-input p-1 cost-icon" title="Edit" 
						data-bs-toggle="modal" data-bs-target="#addContractCost"
						@click="addContractCost(9)"
						v-if="fee_sample.isEditIcon"
					>
						<i class="bx bxs-edit fs-4 cursor-pointer"></i>
					</span>
					<span class="input-group-text cost-input p-1 cost-icon-delete"
						title="Delete"
						v-if="fee_sample.isDeleteIcon"
						@click="deleteContractCost(9)"
					>
						<i class="bx bx-x-circle fs-4 cursor-pointer"></i>
					</span>
					<span class="input-group-text cost-input p-1 cost-icon" 
						title="Save"
						v-if="fee_sample.isSaveIcon"
						@click="saveContractCost(9)"
					>
						<i class="bx bxs-save fs-4 cursor-pointer"></i>
					</span>
					<cleave
						class="form-control text-end cost-input px-2"
						placeholder="0"
						v-model="fee_sample.value"
						:options="cleaveOptions.fee"
					/>
					<span class="input-group-text cost-input p-1 text-warning" 
						title="Missing Information"
						v-if="fee_sample.isMissingInfoIcon"
					>
						<span v-tooltip="'Missing Cost Information'">
							<i class="bx bx-info-circle fs-4 cursor-pointer"></i>
						</span>
					</span>
					<span class="input-group-text cost-input p-1 text-success" 
						title="Completed Information"
						v-if="fee_sample.isAddedInfoIcon"
					>
						<span v-tooltip="'Completed Cost Information'">
							<i class="bx bxs-check-circle fs-4 cursor-pointer"></i>
						</span>
					</span>
				</div>
			</li>
			<li class="list-group-item justify-content-between align-items-center p-0">
				<div class="input-group input-group-merge">
					<span class="input-group-text cost-input p-1" title="View" 
						data-bs-toggle="modal" data-bs-target="#viewContractCost"
						@click="viewContractCost(10)"
						v-if="fee_ocean_marine.isViewIcon"
					>
						<i class="bx bxs-show fs-4 cursor-pointer"></i>
					</span>
					<span class="input-group-text cost-input p-1 cost-icon" title="Edit" 
						data-bs-toggle="modal" data-bs-target="#addContractCost"
						@click="addContractCost(10)"
						v-if="fee_ocean_marine.isEditIcon"
					>
						<i class="bx bxs-edit fs-4 cursor-pointer"></i>
					</span>
					<span class="input-group-text cost-input p-1 cost-icon-delete"
						title="Delete"
						v-if="fee_ocean_marine.isDeleteIcon"
						@click="deleteContractCost(10)"
					>
						<i class="bx bx-x-circle fs-4 cursor-pointer"></i>
					</span>
					<span class="input-group-text cost-input p-1 cost-icon" 
						title="Save"
						v-if="fee_ocean_marine.isSaveIcon"
						@click="saveContractCost(10)"
					>
						<i class="bx bxs-save fs-4 cursor-pointer"></i>
					</span>
					<cleave
						class="form-control text-end cost-input px-2"
						placeholder="0"
						v-model="fee_ocean_marine.value"
						:options="cleaveOptions.fee"
					/>
					<span class="input-group-text cost-input p-1 text-warning" 
						title="Missing Information"
						v-if="fee_ocean_marine.isMissingInfoIcon"
					>
						<span v-tooltip="'Missing Cost Information'">
							<i class="bx bx-info-circle fs-4 cursor-pointer"></i>
						</span>
					</span>
					<span class="input-group-text cost-input p-1 text-success" 
						title="Completed Information"
						v-if="fee_ocean_marine.isAddedInfoIcon"
					>
						<span v-tooltip="'Completed Cost Information'">
							<i class="bx bxs-check-circle fs-4 cursor-pointer"></i>
						</span>
					</span>
				</div>
			</li>
			<li class="list-group-item justify-content-between align-items-center p-0">
				<div class="input-group input-group-merge">
					<span class="input-group-text cost-input p-1" title="View" 
						data-bs-toggle="modal" data-bs-target="#viewContractCost"
						@click="viewContractCost(11)"
						v-if="fee_inland_logistic.isViewIcon"
					>
						<i class="bx bxs-show fs-4 cursor-pointer"></i>
					</span>
					<span class="input-group-text cost-input p-1 cost-icon" title="Edit" 
						data-bs-toggle="modal" data-bs-target="#addContractCost"
						@click="addContractCost(11)"
						v-if="fee_inland_logistic.isEditIcon"
					>
						<i class="bx bxs-edit fs-4 cursor-pointer"></i>
					</span>
					<span class="input-group-text cost-input p-1 cost-icon-delete"
						title="Delete"
						v-if="fee_inland_logistic.isDeleteIcon"
						@click="deleteContractCost(11)"
					>
						<i class="bx bx-x-circle fs-4 cursor-pointer"></i>
					</span>
					<span class="input-group-text cost-input p-1 cost-icon" 
						title="Save"
						v-if="fee_inland_logistic.isSaveIcon"
						@click="saveContractCost(11)"
					>
						<i class="bx bxs-save fs-4 cursor-pointer"></i>
					</span>
					<cleave
						class="form-control text-end cost-input px-2"
						placeholder="0"
						v-model="fee_inland_logistic.value"
						:options="cleaveOptions.fee"
					/>
					<span class="input-group-text cost-input p-1 text-warning" 
						title="Missing Information"
						v-if="fee_inland_logistic.isMissingInfoIcon"
					>
						<span v-tooltip="'Missing Cost Information'">
							<i class="bx bx-info-circle fs-4 cursor-pointer"></i>
						</span>
					</span>
					<span class="input-group-text cost-input p-1 text-success" 
						title="Completed Information"
						v-if="fee_inland_logistic.isAddedInfoIcon"
					>
						<span v-tooltip="'Completed Cost Information'">
							<i class="bx bxs-check-circle fs-4 cursor-pointer"></i>
						</span>
					</span>
				</div>
			</li>
		</ul>
		<ul class="list-group list-group-flush">
			<li class="list-group-item text-end active">
				<span class="fw-bold text-end">{{ formatCurrency(getOtherFee(invoiceCostDetail)) }}</span>
			</li>
			<li class="list-group-item justify-content-between align-items-center p-0">
				<div class="input-group input-group-merge">
					<span class="input-group-text cost-input p-1" title="View" 
						data-bs-toggle="modal" data-bs-target="#viewContractCost"
						@click="viewContractCost(12)"
						v-if="fee_design.isViewIcon"
					>
						<i class="bx bxs-show fs-4 cursor-pointer"></i>
					</span>
					<span class="input-group-text cost-input p-1 cost-icon" title="Edit" 
						data-bs-toggle="modal" data-bs-target="#addContractCost"
						@click="addContractCost(12)"
						v-if="fee_design.isEditIcon"
					>
						<i class="bx bxs-edit fs-4 cursor-pointer"></i>
					</span>
					<span class="input-group-text cost-input p-1 cost-icon-delete"
						title="Delete"
						v-if="fee_design.isDeleteIcon"
						@click="deleteContractCost(12)"
					>
						<i class="bx bx-x-circle fs-4 cursor-pointer"></i>
					</span>
					<span class="input-group-text cost-input p-1 cost-icon" 
						title="Save"
						v-if="fee_design.isSaveIcon"
						@click="saveContractCost(12)"
					>
						<i class="bx bxs-save fs-4 cursor-pointer"></i>
					</span>
					<cleave
						class="form-control text-end cost-input px-2"
						placeholder="0"
						v-model="fee_design.value"
						:options="cleaveOptions.fee"
					/>
					<span class="input-group-text cost-input p-1 text-warning" 
						title="Missing Information"
						v-if="fee_design.isMissingInfoIcon"
					>
						<span v-tooltip="'Missing Cost Information'">
							<i class="bx bx-info-circle fs-4 cursor-pointer"></i>
						</span>
					</span>
					<span class="input-group-text cost-input p-1 text-success" 
						title="Completed Information"
						v-if="fee_design.isAddedInfoIcon"
					>
						<span v-tooltip="'Completed Cost Information'">
							<i class="bx bxs-check-circle fs-4 cursor-pointer"></i>
						</span>
					</span>
				</div>
			</li>
			<li class="list-group-item justify-content-between align-items-center p-0">
				<div class="input-group input-group-merge">
					<span class="input-group-text cost-input p-1" title="View" 
						data-bs-toggle="modal" data-bs-target="#viewContractCost"
						@click="viewContractCost(13)"
						v-if="fee_accesories.isViewIcon"
					>
						<i class="bx bxs-show fs-4 cursor-pointer"></i>
					</span>
					<span class="input-group-text cost-input p-1 cost-icon" title="Edit" 
						data-bs-toggle="modal" data-bs-target="#addContractCost"
						@click="addContractCost(13)"
						v-if="fee_accesories.isEditIcon"
					>
						<i class="bx bxs-edit fs-4 cursor-pointer"></i>
					</span>
					<span class="input-group-text cost-input p-1 cost-icon-delete"
						title="Delete"
						v-if="fee_accesories.isDeleteIcon"
						@click="deleteContractCost(13)"
					>
						<i class="bx bx-x-circle fs-4 cursor-pointer"></i>
					</span>
					<span class="input-group-text cost-input p-1 cost-icon" 
						title="Save"
						v-if="fee_accesories.isSaveIcon"
						@click="saveContractCost(13)"
					>
						<i class="bx bxs-save fs-4 cursor-pointer"></i>
					</span>
					<cleave
						class="form-control text-end cost-input px-2"
						placeholder="0"
						v-model="fee_accesories.value"
						:options="cleaveOptions.fee"
					/>
					<span class="input-group-text cost-input p-1 text-warning" 
						title="Missing Information"
						v-if="fee_accesories.isMissingInfoIcon"
					>
						<span v-tooltip="'Missing Cost Information'">
							<i class="bx bx-info-circle fs-4 cursor-pointer"></i>
						</span>
					</span>
					<span class="input-group-text cost-input p-1 text-success" 
						title="Completed Information"
						v-if="fee_accesories.isAddedInfoIcon"
					>
						<span v-tooltip="'Completed Cost Information'">
							<i class="bx bxs-check-circle fs-4 cursor-pointer"></i>
						</span>
					</span>
				</div>
			</li>
			<li class="list-group-item justify-content-between align-items-center p-0">
				<div class="input-group input-group-merge">
					<span class="input-group-text cost-input p-1" title="View" 
						data-bs-toggle="modal" data-bs-target="#viewContractCost"
						@click="viewContractCost(14)"
						v-if="fee_develop.isViewIcon"
					>
						<i class="bx bxs-show fs-4 cursor-pointer"></i>
					</span>
					<span class="input-group-text cost-input p-1 cost-icon" title="Edit" 
						data-bs-toggle="modal" data-bs-target="#addContractCost"
						@click="addContractCost(14)"
						v-if="fee_develop.isEditIcon"
					>
						<i class="bx bxs-edit fs-4 cursor-pointer"></i>
					</span>
					<span class="input-group-text cost-input p-1 cost-icon-delete"
						title="Delete"
						v-if="fee_develop.isDeleteIcon"
						@click="deleteContractCost(14)"
					>
						<i class="bx bx-x-circle fs-4 cursor-pointer"></i>
					</span>
					<span class="input-group-text cost-input p-1 cost-icon" 
						title="Save"
						v-if="fee_develop.isSaveIcon"
						@click="saveContractCost(14)"
					>
						<i class="bx bxs-save fs-4 cursor-pointer"></i>
					</span>
						<cleave
						class="form-control text-end cost-input px-2"
						placeholder="0"
						v-model="fee_develop.value"
						:options="cleaveOptions.fee"
					/>
					<span class="input-group-text cost-input p-1 text-warning" 
						title="Missing Information"
						v-if="fee_develop.isMissingInfoIcon"
					>
						<span v-tooltip="'Missing Cost Information'">
							<i class="bx bx-info-circle fs-4 cursor-pointer"></i>
						</span>
					</span>
					<span class="input-group-text cost-input p-1 text-success" 
						title="Completed Information"
						v-if="fee_develop.isAddedInfoIcon"
					>
						<span v-tooltip="'Completed Cost Information'">
							<i class="bx bxs-check-circle fs-4 cursor-pointer"></i>
						</span>
					</span>
				</div>
			</li>
			<li class="list-group-item justify-content-between align-items-center p-0">
				<div class="input-group input-group-merge">
					<span class="input-group-text cost-input p-1" title="View" 
						data-bs-toggle="modal" data-bs-target="#viewContractCost"
						@click="viewContractCost(15)"
						v-if="fee_commission.isViewIcon"
					>
						<i class="bx bxs-show fs-4 cursor-pointer"></i>
					</span>
					<span class="input-group-text cost-input p-1 cost-icon" title="Edit" 
						data-bs-toggle="modal" data-bs-target="#addContractCost"
						@click="addContractCost(15)"
						v-if="fee_commission.isEditIcon"
					>
						<i class="bx bxs-edit fs-4 cursor-pointer"></i>
					</span>
					<span class="input-group-text cost-input p-1 cost-icon-delete"
						title="Delete"
						v-if="fee_commission.isDeleteIcon"
						@click="deleteContractCost(15)"
					>
						<i class="bx bx-x-circle fs-4 cursor-pointer"></i>
					</span>
					<span class="input-group-text cost-input p-1 cost-icon" 
						title="Save"
						v-if="fee_commission.isSaveIcon"
						@click="saveContractCost(15)"
					>
						<i class="bx bxs-save fs-4 cursor-pointer"></i>
					</span>
					<cleave
						class="form-control text-end cost-input px-2"
						placeholder="0"
						v-model="fee_commission.value"
						:options="cleaveOptions.fee"
					/>
					<span class="input-group-text cost-input p-1 text-warning" 
						title="Missing Information"
						v-if="fee_commission.isMissingInfoIcon"
					>
						<span v-tooltip="'Missing Cost Information'">
							<i class="bx bx-info-circle fs-4 cursor-pointer"></i>
						</span>
					</span>
					<span class="input-group-text cost-input p-1 text-success" 
						title="Completed Information"
						v-if="fee_commission.isAddedInfoIcon"
					>
						<span v-tooltip="'Completed Cost Information'">
							<i class="bx bxs-check-circle fs-4 cursor-pointer"></i>
						</span>
					</span>
				</div>
			</li>
			<li class="list-group-item justify-content-between align-items-center p-0">
				<div class="input-group input-group-merge">
					<span class="input-group-text cost-input p-1" title="View" 
						data-bs-toggle="modal" data-bs-target="#viewContractCost"
						@click="viewContractCost(16)"
						v-if="fee_royalty.isViewIcon"
					>
						<i class="bx bxs-show fs-4 cursor-pointer"></i>
					</span>
					<span class="input-group-text cost-input p-1 cost-icon" title="Edit" 
						data-bs-toggle="modal" data-bs-target="#addContractCost"
						@click="addContractCost(16)"
						v-if="fee_royalty.isEditIcon"
					>
						<i class="bx bxs-edit fs-4 cursor-pointer"></i>
					</span>
					<span class="input-group-text cost-input p-1 cost-icon-delete"
						title="Delete"
						v-if="fee_royalty.isDeleteIcon"
						@click="deleteContractCost(16)"
					>
						<i class="bx bx-x-circle fs-4 cursor-pointer"></i>
					</span>
					<span class="input-group-text cost-input p-1 cost-icon" 
						title="Save"
						v-if="fee_royalty.isSaveIcon"
						@click="saveContractCost(16)"
					>
						<i class="bx bxs-save fs-4 cursor-pointer"></i>
					</span>
					<cleave
						class="form-control text-end cost-input-last px-2"
						style="border-bottom: solid 1px lightgrey;"
						placeholder="0"
						v-model="fee_royalty.value"
						:options="cleaveOptions.fee"
					/>
					<span class="input-group-text cost-input p-1 text-warning" 
						title="Missing Information"
						v-if="fee_royalty.isMissingInfoIcon"
					>
						<span v-tooltip="'Missing Cost Information'">
							<i class="bx bx-info-circle fs-4 cursor-pointer"></i>
						</span>
					</span>
					<span class="input-group-text cost-input p-1 text-success" 
						title="Completed Information"
						v-if="fee_royalty.isAddedInfoIcon"
					>
						<span v-tooltip="'Completed Cost Information'">
							<i class="bx bxs-check-circle fs-4 cursor-pointer"></i>
						</span>
					</span>
				</div>
			</li>
		</ul>
	</div>
  <!-- / Content -->
</template>

<script>
import { mapState } from "vuex";
import _ from 'lodash';
import { utilities } from "../../services/utilities.js";
import { CATEGORY_CONTRACT_COST } from "../../config/common.js"
import showConfirmDeleteAlert from "../../helper/error.js"
import {errorAlert, deletedAlert} from "../../helper/error.js"
// import moment from 'moment';

export default {
  name: 'ShipmentCostDetail',
  components: {
  },
	props: {
    "cost_detail": {
			type: Object,
			required: true
		},
		"invoice_detail": {
			type: Object,
			required: true
		}
	},
	emits: {
		addContractCost: null,
		changeTotalCostShipment: null,
		viewContractCost: null,
		saveContractCostProp: null,
		deleteContractCostProp: null
		// newContractCost: null
	},
  data(){
    return {
			invoiceCostDetail: this.cost_detail,
			inv_detail: this.invoice_detail,
			// costId: this.contract_id,
			now: new Date(),
			// contractCostDetail: {},
			fee_customs_duty: {
				value: null,
				isViewIcon: false,
				isEditIcon: false,
				isSaveIcon: false,
				isDeleteIcon: false,
				isMissingInfoIcon: false,
				isAddedInfoIcon: false
			},
			fee_freight: {
				value: null,
				isViewIcon: false,
				isEditIcon: false,
				isSaveIcon: false,
				isDeleteIcon: false,
				isMissingInfoIcon: false,
				isAddedInfoIcon: false
			},
			fee_customs_lcc: {
				value: null,
				isViewIcon: false,
				isEditIcon: false,
				isSaveIcon: false,
				isDeleteIcon: false,
				isMissingInfoIcon: false,
				isAddedInfoIcon: false
			},
			fee_trucking: {
				value: null,
				isViewIcon: false,
				isEditIcon: false,
				isSaveIcon: false,
				isDeleteIcon: false,
				isMissingInfoIcon: false,
				isAddedInfoIcon: false
			},
			fee_storage: {
				value: null,
				isViewIcon: false,
				isEditIcon: false,
				isSaveIcon: false,
				isDeleteIcon: false,
				isMissingInfoIcon: false,
				isAddedInfoIcon: false
			},
			fee_labeling: {
				value: null,
				isViewIcon: false,
				isEditIcon: false,
				isSaveIcon: false,
				isDeleteIcon: false,
				isMissingInfoIcon: false,
				isAddedInfoIcon: false
			},
			fee_ocean_marine: {
				value: null,
				isViewIcon: false,
				isEditIcon: false,
				isSaveIcon: false,
				isDeleteIcon: false,
				isMissingInfoIcon: false,
				isAddedInfoIcon: false
			},
			fee_inland_logistic: {
				value: null,
				isViewIcon: false,
				isEditIcon: false,
				isSaveIcon: false,
				isDeleteIcon: false,
				isMissingInfoIcon: false,
				isAddedInfoIcon: false
			},
			fee_inspection: {
				value: null,
				isViewIcon: false,
				isEditIcon: false,
				isSaveIcon: false,
				isDeleteIcon: false,
				isMissingInfoIcon: false,
				isAddedInfoIcon: false
			},
			fee_testing: {
				value: null,
				isViewIcon: false,
				isEditIcon: false,
				isSaveIcon: false,
				isDeleteIcon: false,
				isMissingInfoIcon: false,
				isAddedInfoIcon: false
			},
			fee_sample: {
				value: null,
				isViewIcon: false,
				isEditIcon: false,
				isSaveIcon: false,
				isDeleteIcon: false,
				isMissingInfoIcon: false,
				isAddedInfoIcon: false
			},
			fee_design: {
				value: null,
				isViewIcon: false,
				isEditIcon: false,
				isSaveIcon: false,
				isDeleteIcon: false,
				isMissingInfoIcon: false,
				isAddedInfoIcon: false
			},
			fee_accesories: {
				value: null,
				isViewIcon: false,
				isEditIcon: false,
				isSaveIcon: false,
				isDeleteIcon: false,
				isMissingInfoIcon: false,
				isAddedInfoIcon: false
			},
			fee_develop: {
				value: null,
				isViewIcon: false,
				isEditIcon: false,
				isSaveIcon: false,
				isDeleteIcon: false,
				isMissingInfoIcon: false,
				isAddedInfoIcon: false
			},
			fee_commission: {
				value: null,
				isViewIcon: false,
				isEditIcon: false,
				isSaveIcon: false,
				isDeleteIcon: false,
				isMissingInfoIcon: false,
				isAddedInfoIcon: false
			},
			fee_royalty: {
				value: null,
				isViewIcon: false,
				isEditIcon: false,
				isSaveIcon: false,
				isDeleteIcon: false,
				isMissingInfoIcon: false,
				isAddedInfoIcon: false
			},
			cleaveOptions: {
        fee: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand'
        }
      }
    };
  },
  computed: {
		...mapState({
      stateData: state => state.contractCostAdd.stateData,
    }),
		categoryContractCostOptions() {
      return CATEGORY_CONTRACT_COST;
    },
		shipmentDetailInfos	() {
			return this.$store.state.shipmentList.stateData.shipmentDetailInfos;
		}
	},
	watch: {
		"cost_detail" (newVal) {
			this.invoiceCostDetail = newVal;
			this.categoryContractCostOptions.forEach(item => {					
				this.resetContractFee(item.contract_fee_key);
			});
			if (newVal.contract_costs.length !== 0) {
				newVal.contract_costs.forEach(item => {
					let cate_contrcost = this.categoryContractCostOptions.find(option => option.id == item.category_cost);
					this[cate_contrcost.contract_fee_key].value = item.cost_amount;
				});
			}
		},
    "fee_customs_duty.value" (newVal) {
			this.setStatusIcon("fee_customs_duty", newVal);
    },
		"fee_freight.value" (newVal) {
      this.setStatusIcon("fee_freight", newVal);
      // this.totalCost = this.calculateTotalCost();
      // this.$emit('changeTotalCostShipment', newVal);
    },
    "fee_customs_lcc.value" (newVal) {
      this.setStatusIcon("fee_customs_lcc", newVal);
    },
    "fee_trucking.value" (newVal) {
      this.setStatusIcon("fee_trucking", newVal);
    },
    "fee_storage.value" (newVal) {
      this.setStatusIcon("fee_storage", newVal);
    },
    "fee_labeling.value" (newVal) {
      this.setStatusIcon("fee_labeling", newVal);
    },
		"fee_inspection.value" (newVal) {
      this.setStatusIcon("fee_inspection", newVal);
    },
		"fee_testing.value" (newVal) {
      this.setStatusIcon("fee_testing", newVal);
    },
    "fee_sample.value" (newVal) {
      this.setStatusIcon("fee_sample", newVal);
    },
    "fee_ocean_marine.value" (newVal) {
      this.setStatusIcon("fee_ocean_marine", newVal);
    },
    "fee_inland_logistic.value" (newVal) {
      this.setStatusIcon("fee_inland_logistic", newVal);
    },
    "fee_design.value" (newVal) {
      this.setStatusIcon("fee_design", newVal);
    },
    "fee_accesories.value" (newVal) {
      this.setStatusIcon("fee_accesories", newVal);
    },
    "fee_develop.value" (newVal) {

      this.setStatusIcon("fee_develop", newVal);
    },
    "fee_commission.value" (newVal) {
      this.setStatusIcon("fee_commission", newVal);
    },
    "fee_royalty.value" (newVal) {
      this.setStatusIcon("fee_royalty", newVal);
    }
	},
  beforeCreate() {
		// this.invoiceCostDetail = {};
  },
  created() {
		if (this.invoiceCostDetail != undefined) {
			this.invoiceCostDetail.contract_costs.forEach(item => {
				let cate_contrcost = this.categoryContractCostOptions.find(option => option.id == item.category_cost);
				this[cate_contrcost.contract_fee_key].value = item.cost_amount;
			});
		}
  },
	mounted() {
	},
  methods: {
		formatCurrency(value) {
      return utilities.getFormatCurrency(value);
    },
		getImportExportFee(invoiceObject) {
      if (_.isEmpty(invoiceObject) || _.isNil(invoiceObject)) return 0;
			let total = 0;
			invoiceObject.contract_costs.forEach(item => {
				if (item.category_cost == 1 || item.category_cost == 2 || item.category_cost == 3) {
					total += item.cost_amount
				}
			})
			return total;
    },
    getTransportWarehouseFee(invoiceObject) {
      if (_.isEmpty(invoiceObject) || _.isNil(invoiceObject)) return 0;

      let total = 0;
			invoiceObject.contract_costs.forEach(item => {
				if (item.category_cost == 4 || item.category_cost == 5 || item.category_cost == 6) {
					total += item.cost_amount
				}
			})
			return total;
    },
    getTestingInsuranceFee(invoiceObject) {
      if (_.isEmpty(invoiceObject) || _.isNil(invoiceObject)) return 0;

      let total = 0;
			invoiceObject.contract_costs.forEach(item => {
				if (item.category_cost == 7 || item.category_cost == 8 || item.category_cost == 9 || 
						item.category_cost == 10 || item.category_cost == 11) {
					total += item.cost_amount
				}
			})
			return total;
    },
    getOtherFee(invoiceObject) {
      if (_.isEmpty(invoiceObject) || _.isNil(invoiceObject)) return 0;

      let total = 0;
			invoiceObject.contract_costs.forEach(item => {
				if (item.category_cost == 12 || item.category_cost == 13 || item.category_cost == 14 || 
						item.category_cost == 15 || item.category_cost == 16) {
					total += item.cost_amount
				}
			})
			return total;
    },
		calculateTotalCost() {
      if (_.isEmpty(this.invoiceCostDetail) || _.isNil(this.invoiceCostDetail)) return 0;
			let total = 0;
			this.invoiceCostDetail.contract_costs.forEach(item => {
				total += item.cost_amount
			})
			return total;
    },
		addContractCost(cate_cost_id) {
			let contract_cost_obj = this.invoiceCostDetail.contract_costs.find(
				item => item.category_cost == cate_cost_id
			);
			this.$emit('addContractCost', contract_cost_obj.cost_id);
    },
		viewContractCost(cate_cost_id) {
			let contract_cost_obj = this.invoiceCostDetail.contract_costs.find(
				item => item.category_cost == cate_cost_id
			);
			this.$emit('viewContractCost', contract_cost_obj.cost_id);
		},
		deleteContractCost(cate_cost_id) {
			let contract_cost_obj = this.invoiceCostDetail.contract_costs.find(
				item => item.category_cost == cate_cost_id
			);
			showConfirmDeleteAlert()
      .then(
        (result) => {
          if(!result) return;

          this.$store.dispatch('contractCostAdd/deleteContractCost', contract_cost_obj.cost_id).then(
            () => { 
              deletedAlert();
							let cate_contrcost = this.categoryContractCostOptions.find(option => option.id == contract_cost_obj.category_cost);
							this[cate_contrcost.contract_fee_key].value = null;
							this.invoiceCostDetail.contract_costs = this.invoiceCostDetail.contract_costs.filter(item => item.cost_id != contract_cost_obj.cost_id);
							this.$emit(
								'deleteContractCostProp', 
								contract_cost_obj.cost_id, 
								contract_cost_obj.invoice_id
							);
						},
            (error) => {
              console.log(error);
							let  err_message= "Failed to deleted contract cost information!";
							if (error.response.data) {
								err_message = error.response.data.detail;
							}
              errorAlert("error", "Delete Error", err_message);
            }
          );
        }
      )
		},
		saveContractCost(cate_cost_id) {
			let cate_contractcost = this.categoryContractCostOptions.find(option => option.id == cate_cost_id);
			let contract_cost_obj = this.invoiceCostDetail.contract_costs.find(
				item => item.category_cost == cate_cost_id
			);
			
			if (contract_cost_obj) {
				this.stateData["id"] = contract_cost_obj.cost_id;
				this.stateData.contract_id = contract_cost_obj.contract_id;
				this.stateData.invoice_id = contract_cost_obj.invoice_id;
				this.stateData.category_cost = contract_cost_obj.category_cost;
			}
			else {
				this.stateData["id"] = null;
				this.stateData.contract_id = this.shipmentDetailInfos.contract_id;
				this.stateData.invoice_id = this.invoiceCostDetail.invoice_id;
				this.stateData.category_cost = cate_cost_id;
			}
			this.stateData.cost_amount = this[cate_contractcost.contract_fee_key].value == "" ? null : this[cate_contractcost.contract_fee_key].value;
			Object.keys(this.stateData).forEach(key => {
				if (this.stateData[key] == "") {
					this.stateData[key] = null;
				}
			});

			let request = {
				...this.stateData
			}
			this.$store.dispatch('contractCostAdd/saveContractCostOfInvoice', request).then(
				(response) => {
					let self = this;
					
					if (!this.stateData["id"]) {
						this.invoiceCostDetail.contract_costs.push(response.result);
					}
					else {
						self.invoiceCostDetail.contract_costs.forEach(item => {
							if (item.category_cost == cate_cost_id) {
								item.cost_amount = response.result.cost_amount;
							}
						});
					}
					
					this.$swal.fire(
						'Saved',
						'Contract Cost information is saved sucessfully !',
						'success'
					).then(function() {
						self.setStatusIcon(cate_contractcost.contract_fee_key, response.result.cost_amount);
						self.$store.dispatch('contractCostAdd/resetState');
						self.$emit(
							'saveContractCostProp', 
							response.result.cost_amount, 
							response.result.invoice_id, 
							response.result.category_cost, 
							response.result.partner_id
						);
					});
				},
				error => {
					console.log(error);
					this.$swal.fire({
						icon: 'error',
						title: 'Error',
						text: 'Failed to save Contract Cost information !'
					});
				}
			);
		},
		setStatusIcon(fee_name, new_value) {
			let cate_contractcost = this.categoryContractCostOptions.find(option => option.contract_fee_key == fee_name);
			
			let initialValue = null;
			let initialAmount = null;
			if (this.invoiceCostDetail != undefined && this.invoiceCostDetail.contract_costs != undefined && this.invoiceCostDetail.contract_costs.length > 0) {
				initialValue = this.invoiceCostDetail.contract_costs.find(item => item.category_cost == cate_contractcost.id);
			}
			
			if (initialValue == undefined || initialValue.cost_amount == null) {
				this[fee_name].isMissingInfoIcon = false;
				this[fee_name].isAddedInfoIcon = false;
				initialAmount = null;
			}
			else {
				if (initialValue.partner_id) {
					this[fee_name].isMissingInfoIcon = false;
					this[fee_name].isAddedInfoIcon = true;
				}
				else {
					this[fee_name].isMissingInfoIcon = true;
					this[fee_name].isAddedInfoIcon = false;
				}
				initialAmount = initialValue.cost_amount;
			}
			
			if(new_value && new_value == initialAmount && initialAmount != 0) {
				this.iconExistedData(fee_name);
			}
			else if (!new_value && !initialAmount) {
				this.iconInitial(fee_name);
			}
			else if (initialAmount && new_value != initialAmount) {
				this.iconInputData(fee_name);
			}
			else if (!initialAmount && new_value != initialAmount) {
				this.iconAddNewData(fee_name);
			}
		},
		iconInitial(fee_name) {
			if (!fee_name) return;
			this[fee_name].isViewIcon = false;
			this[fee_name].isEditIcon = false;
			this[fee_name].isDeleteIcon = false;
			this[fee_name].isSaveIcon = false;
		},
		iconExistedData(fee_name) {
			if (!fee_name) return;
			this[fee_name].isViewIcon = true;
			this[fee_name].isEditIcon = true;
			this[fee_name].isDeleteIcon = true;
			this[fee_name].isSaveIcon = false;
		},
		iconExistedDataIncompleted(fee_name) {
			if (!fee_name) return;
			this[fee_name].isViewIcon = false;
			this[fee_name].isEditIcon = true;
			this[fee_name].isDeleteIcon = true;
			this[fee_name].isSaveIcon = false;
		},
		iconInputData(fee_name) {
			if (!fee_name) return;
			this[fee_name].isViewIcon = true;
			this[fee_name].isEditIcon = true;
			this[fee_name].isDeleteIcon = true;
			this[fee_name].isSaveIcon = true;
		},
		iconAddNewData(fee_name) {
			if (!fee_name) return;
			this[fee_name].isViewIcon = false;
			this[fee_name].isEditIcon = false;
			this[fee_name].isDeleteIcon = false;
			this[fee_name].isSaveIcon = true;
		},
		resetContractFee(fee_name) {
			this[fee_name].value = null;
			this[fee_name].isViewIcon = false;
			this[fee_name].isEditIcon = false;
			this[fee_name].isSaveIcon = false;
			this[fee_name].isDeleteIcon = false;
			this[fee_name].isMissingInfoIcon = false;
			this[fee_name].isAddedInfoIcon = false;
		}
  }
}
</script>

<style scoped>
	.cost-input {
    border-radius: 0px;
    border-left: solid 1px lightgrey;
    border-bottom: 0px;
    border-right: solid 1px lightgrey;
    border-top: 0px;
    background-color: lightyellow;
    font-size: 0.85rem;
  }
  .cost-input-last {
    border-radius: 0px;
    border-left: solid 1px lightgrey;
    border-bottom: 0px;
    border-right: solid 1px lightgrey;
    border-top: 0px;
    background-color: lightyellow;
    /* border-bottom-right-radius: 10px; */
    font-size: 0.85rem;
  }
  .list-group-item.total-cost {
    border-color: #ffab00;
    background-color: #ffab00;
    color: white;
  }
	.cost-icon, .cost-icon-delete {
		border: none;
	}
	.cost-input .bxs-show:hover,
	.cost-input .bxs-edit:hover{
    color: #696cff !important;
  }
	.cost-icon-delete:hover {
    color: red !important;
  }
</style>