import ShipmentService from '../services/shipment.service';


const getDefaultState = () => {
  return {
    currentPage: 1,
    totalItems: 0,
    totalPages: 0,
    listItems: [],
    shipmentDetailInfos: {}
  }
}

export const shipmentList = {
  namespaced: true,
  state: {
    stateData: getDefaultState()
  },
  actions: {
    getListData({commit}, page=1) {
      return ShipmentService.getListData(page).then(
        (response) => {
          commit('getListDataSuccess', response);
          return Promise.resolve(response);
        },
        (error) => {
          commit('getListDataFailure');
          return Promise.reject(error);
        }
      );
    },
    searchShipment({commit}, {"page": page, "searchKey": searchKey}) {
      return ShipmentService.searchShipment({"page": page, "searchKey": searchKey}).then(
        (response) => {
          commit('getSearchShipmentSuccess', response);
          return Promise.resolve(response);
        },
        (error) => {
          commit('getSearchShipmentFailure');
          return Promise.reject(error);
        }
      );
    },
    deleteShipment ({commit}, {"receiving_id": receivingVoucherId, "delivery_id": deliveryVoucherId, "invoice_id": invoiceId}) {
      return ShipmentService.deleteShipment(
        {"receiving_id": receivingVoucherId, "delivery_id": deliveryVoucherId, "invoice_id": invoiceId}
      ).then(
        (response) => {
          commit('deleteSuccess', response);
          return Promise.resolve(response);
        },
        (error) => {
          commit('deleteFailure');
          return Promise.reject(error);
        }
      );
    },
    getShipmentInfo ({commit}, id="") {
      return ShipmentService.getShipmentInfo(id).then(
        (response) => {
          commit('getShipmentInfoSuccess', response);
          return Promise.resolve(response);
        },
        (error) => {
          commit('getShipmentInfoFailure');
          return Promise.reject(error);
        }
      );
    },
    uploadShipmentFileAfter({commit},formdata) {
			return ShipmentService.uploadShipmentFileAfter(formdata).then(
				(response) => {
					// commit('uploadSuccess', response);
					return Promise.resolve(response);
				},
				(error) => {
					commit("uploadFailure");
					return Promise.reject(error);
				}
			);
		},
    deleteUploadedFile ({commit}, {"invoice_code_id": invoiceCodeId, "el_name": elName}) {
      return ShipmentService.deleteUploadedFile(
        {"invoice_code_id": invoiceCodeId, "el_name": elName}
      ).then(
        (response) => {
          // commit('deleteUploadFileSuccess', response);
          return Promise.resolve(response);
        },
        (error) => {
          commit('deleteUploadFileFailure');
          return Promise.reject(error);
        }
      );
    },
    exportDeliveryVoucherFile ({commit}, id="") {
      return ShipmentService.exportDeliveryVoucherFile(id).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          commit('exportDeliveryVoucherFailure');
          return Promise.reject(error);
        }
      );
    },
    exportInvoiceFile ({commit}, id="") {
      return ShipmentService.exportInvoiceFile(id).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          commit('exportInvoiceFailure');
          return Promise.reject(error);
        }
      );
    },
    exportReceivingVoucherFile ({commit}, id="") {
      return ShipmentService.exportReceivingVoucherFile(id).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          commit('exportReceivingVoucherFailure');
          return Promise.reject(error);
        }
      );
    },
    modifyCostInvoices({commit}, data) {
      return ShipmentService.modifyCostInvoices(data).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          commit("modifyCostInvoicesFailure");
          return Promise.reject(error);
        }
      );
    }
  },
  mutations: {
    getListDataSuccess(state, response) {
      state.stateData.currentPage = response.currentPage;
      state.stateData.totalItems = response.totalItems;
      state.stateData.totalPages = response.totalPages;
      state.stateData.listItems = response.listItems;
    },
    getListDataFailure(state) {
      console.log("run getFailure");
      Object.assign(state.stateData, getDefaultState())
    },
    getSearchShipmentSuccess(state, response) {
      state.stateData.currentPage = response.currentPage;
      state.stateData.totalItems = response.totalItems;
      state.stateData.totalPages = response.totalPages;
      state.stateData.listItems = response.listItems;
    },
    getSearchShipmentFailure() {
      console.log("run getSearchShipmentFailure");
    },
    deleteSuccess () {
      console.log("run deleteSuccess");
    },
    deleteFailure() {
      console.log("run deleteFailure");
    },
    getShipmentInfoSuccess(state, response) {
      state.stateData.shipmentDetailInfos = response;
    },
    getContractFailure(state) {
      state.stateData.shipmentDetailInfos = {};
      console.log("run getShipmentInfoFailure");
    },
    exportDeliveryVoucherFailure() {
      console.log("run exportDeliveryVoucherFailure");
    },
    exportInvoiceFailure() {
      console.log("run exportInvoiceFailure");
    },
    exportReceivingVoucherFailure() {
      console.log("run exportReceivingVoucherFailure");
    },
    modifyCostInvoicesFailure() {
      console.log("run modifyCostInvoicesFailure");
    }
  },
  getters: {

  }
}