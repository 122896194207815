<template>
  <div class="modal fade" id="addContractCost" data-bs-backdrop="static" role="dialog" style="display: none;">
		<div class="modal-dialog modal-lg">
			<form class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title" id="backDropModalTitle">Add Contract Cost Information</h5>
					<button type="button" 
						class="btn-close" 
						data-bs-dismiss="modal" 
						aria-label="Close"
						@click="closeModal"
					></button>
				</div>
				<div class="modal-body">
					<!-- General Information -->
					<div class="card mb-4">
						<h5 class="card-header">General Information</h5>
						<hr class="my-0">
						<div class="card-body">
							<div class="row">
								<div class="mb-3 col-md-4 fv-plugins-icon-container">
									<label for="firstName" class="form-label">Issue Date</label>
									<span class="m-1">(*)</span>
									<flat-pickr 
										v-model="stateData.issue_date"
										:config="calendarConfig" 
										placeholder="Select a date" 
										name="created-date" 
										class="form-control">
									</flat-pickr>
									<div class="error-msg" v-if="v$.stateData.issue_date.$error"> 
										{{ v$.stateData.issue_date.$errors[0].$message }}
									</div>
								</div>
							</div>
							<div class="row">
								<div class="mb-8 col-md-8">
									<label for="" class="form-label">Partner Name</label>
									<span class="m-1">(*)</span>
									<VueMultiselect 
										v-model="supplierSelected" 
										:options="list_suppliers" 
										placeholder="Please choose supplier" 
										label="partner_name" 
										:close-on-select="true"
										:clear-on-select="false"
									/>
									<div class="error-msg" v-if="v$.stateData.partner_id.$error"> 
										{{ v$.stateData.partner_id.$errors[0].$message }}
									</div>
								</div>
								<div class="mb-4 col-md-4">
									<label class="form-label">Supplier Code</label>
									<span class="m-1">(*)</span>
									<input class="form-control disabled" 
										type="text" 
										v-model="stateData.supplier_code"	
										disabled
									>
								</div>
								<div class="col-md-12" v-if="stateData.partner_id && supplierSelected">
									<div class="alert alert-primary mb-1" role="alert">
										<span class="alert-heading fw-bold">PAYMENT INFORMATION</span>
										<div class="row mt-3">
											<div class="col-md-3">
												<span v-if="supplierSelected.has_deposit == 1">HAS DEPOSIT</span>
												<span v-else>NO DEPOSIT</span>
											</div>
											<div class="col-md-3" v-if="supplierSelected.has_deposit == 1">
												<span class="me-1">Deposit: {{ supplierSelected.percent_deposit }}</span>
												<span>%</span>
											</div>
											<div class="col-md-3" v-if="supplierSelected.has_deposit == 1">
												<span class="me-1">Balance: {{ supplierSelected.percent_balance }}</span>
												<span>%</span>
											</div>
										</div>
										<div class="row mt-2">
											<div class="col-md-3">
												<span>Payment Method: </span>
												<span>{{ getPaymentName(supplierSelected.payment_method) }}</span>
											</div>
											<div class="col-md-3">
												<span>Term: </span>
												<span class="me-1">{{ supplierSelected.term }}</span>
												<span>days</span>
											</div>
											<div class="col-md-3">
												<span>Closing Time: </span>
												<span>{{ getClosingTime(supplierSelected.closing_time) }}</span>
											</div>
											<div class="col-md-3" v-if="supplierSelected.closing_time == 7">
												<span>On Exact Date: </span>
												<span>{{ supplierSelected.exact_date }}</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- / General Information -->

					<!-- Cost Information -->
					<div class="card mb-4">
						<h5 class="card-header">Main Information</h5>
						<hr class="my-0">
						<div class="card-body">
							<div class="row">
								<div class="col-md-5 mb-4">
									<label for="" class="form-label">Category Contract Cost</label>
									<span class="m-1">(*)</span>
									<select class="form-select" 
										v-model="stateData.category_cost"
									>

										<template v-for="(item, index) in categoryContractCostOptions" :key="index">
											<option :value="item.invoice_cost_value" v-if="item.id == 1 || item.id == 7">
												{{ item.invoice_cost_name }}
											</option>
										</template>
									</select>
									<div class="error-msg" v-if="v$.stateData.category_cost.$error"> 
										{{ v$.stateData.category_cost.$errors[0].$message }}
									</div>
								</div>
								<div class="col-md-4 mb-4">
									<label for="" class="form-label">Supplier Invoice No</label>
									<span class="m-1">(*)</span>
									<input type="text" 
										class="form-control" 
										placeholder="INV_001, INV_002,..."
										v-model="stateData.supplier_inv_no"
									>
									<div class="error-msg" v-if="v$.stateData.supplier_inv_no.$error"> 
										{{ v$.stateData.supplier_inv_no.$errors[0].$message }}
									</div>
								</div>
								<div class="col-md-3 mb-4">
									<label for="" class="form-label">Supplier Invoice Date</label>
									<flat-pickr 
										v-model="stateData.supplier_inv_date" 
										:config="calendarConfig" 
										placeholder="Select a date" 
										name="payment-due-date" 
										class="form-control">
									</flat-pickr>
									<div class="error-msg" v-if="v$.stateData.supplier_inv_date.$error"> 
										{{ v$.stateData.supplier_inv_date.$errors[0].$message }}
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-5 mb-4">
									<label for="" class="form-label">Cost Name</label>
									<span class="m-1">(*)</span>
									<input class="form-control" 
										type="text" 
										placeholder="Office Rental Cost 2024, Dec"
										v-model="stateData.cost_name"
									>
									<div class="error-msg" v-if="v$.stateData.cost_name.$error"> 
										{{ v$.stateData.cost_name.$errors[0].$message }}
									</div>
								</div>
								<div class="mb-3 col-md-4 mb-4">
									<label for="" class="form-label">Cost Amount</label>
									<span class="m-1">(*)</span>
									<div class="input-group">
										<span class="input-group-text px-2">VND</span>
										<cleave class="form-control px-1" 
											placeholder="999,999,000" 
											style="text-align: right;"
											v-model="stateData.cost_amount" 
											:options="cleaveOptions.cost_amount"
										/>
										<div class="error-msg" v-if="v$.stateData.cost_amount.$error"> 
											{{ v$.stateData.cost_amount.$errors[0].$message }}
										</div>
									</div>
								</div>
								<div class="mb-3 col-md-3 mb-4">
									<label for="" class="form-label">Tax</label>
									<span class="m-1">(*)</span>
									<div class="input-group">
										<cleave class="form-control px-2" 
											placeholder="10" 
											style="text-align: right;"
											v-model="stateData.cost_tax" 
											:options="cleaveOptions.cost_tax"
										/>
										<span class="input-group-text px-3">%</span>
										<div class="error-msg" v-if="v$.stateData.cost_tax.$error"> 
											{{ v$.stateData.cost_tax.$errors[0].$message }}
										</div>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="mb-3 col-md-3">
									<label class="form-label">Payment Due Date</label>
									<span class="m-1">(*)</span>
									<flat-pickr 
										v-model="stateData.payment_due_date" 
										:config="calendarConfig" 
										placeholder="Select a date" 
										name="payment-due-date" 
										class="form-control">
									</flat-pickr>
									<div class="error-msg" v-if="v$.stateData.payment_due_date.$error"> 
										{{ v$.stateData.payment_due_date.$errors[0].$message }}
									</div>
								</div>
								<div class="mb-3 col-md-3">
									<label class="form-label">Actual Payment Date</label>
									<flat-pickr 
										v-model="stateData.actual_payment_date" 
										:config="calendarConfig" 
										placeholder="Select a date" 
										name="actual-payment-date" 
										class="form-control">
									</flat-pickr>
									<div class="error-msg" v-if="v$.stateData.actual_payment_date.$error"> 
										{{ v$.stateData.actual_payment_date.$errors[0].$message }}
									</div>
								</div>
								<div class="mb-3 col-md-3">
									<label for="" class="form-label">Actual Amount</label>
									<div class="input-group">
										<span class="input-group-text px-2">VND</span>
										<cleave class="form-control px-1" 
											placeholder="999,999,999,000" 
											style="text-align: right;"
											v-model="stateData.cost_actual_amount"
											:options="cleaveOptions.cost_actual_amount"
										/>
									</div>
									<div class="error-msg" v-if="v$.stateData.cost_actual_amount.$error"> 
										{{ v$.stateData.cost_actual_amount.$errors[0].$message }}
									</div>
									<div class="error-msg" v-if="isError"> 
										The ACTUAL AMOUNT is not matched to COST AMOUNT
									</div>
								</div>
								<div class="col-md-3 mb-4">
									<label class="form-label">Status</label>
									<span class="m-1">(*)</span>
									<select 
										class="form-select text-capitalize" 
										v-model="stateData.status"
									>
										<template v-for="(item, index) in costStatusOptions" :key="index">
											<option :value="item.id">{{ item.name }}</option>
										</template>
									</select>
									<div class="error-msg" v-if="v$.stateData.status.$error"> 
										{{ v$.stateData.status.$errors[0].$message }}
									</div>
								</div>
							</div>
							<div class="row">
								<label for="apiAccess" class="form-label">Remark</label>
								<div class="col-md-12">
									<textarea 
										class="form-control" rows="3"
										v-model="stateData.remark"
									></textarea>
								</div>
							</div>
							<div class="row mt-5">
								<form id="" @submit.prevent="handleSave">
									<div class="col-sm-6">
										<button type="submit" class="btn btn-primary me-3" v-bind="{disabled: isDisabled}">Save Contract Cost</button>
										<button type="button" ref="closeModalBtn"
											class="btn btn-secondary" 
											data-bs-dismiss="modal" 
											aria-label="Close"
											@click="closeModal"
										>
											Close Modal
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
					<!-- / Cost Information -->

					<!-- Modal -->
					<div class="modal modal-transparent show fade" v-if="isLoading" id="basicModal" style="display: block;">
						<div class="modal-dialog modal-dialog-centered">
							<div class="modal-content">
								<div class="modal-body">
									<rise-loader :color="color" :size="size" style="text-align:center;"></rise-loader>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";
import RiseLoader from 'vue-spinner/src/RiseLoader.vue'
import flatPickr from 'vue-flatpickr-component';
import { configs } from '@/config/calendar.js';
import useValidate from '@vuelidate/core';
import { required, helpers, requiredIf } from '@vuelidate/validators'
import VueMultiselect from 'vue-multiselect';
import { PAYMENT_METHOD, CLOSING_TIME, COST_STATUS, CATEGORY_CONTRACT_COST } from "../../config/common.js"
import _ from 'lodash';
import moment from 'moment';
import { utilities } from "../../services/utilities.js";


export default {
	name: 'ContractCostAddModal',
  components: {
		RiseLoader,
		flatPickr,
		VueMultiselect
  },
  props: {
		"invoice_id": {
			type: Number,
			required: false
		},
		"category_cost": {
			type: String,
			required: false
		},
		"cost_id": {
			type: Number,
			required: false
		},
  },
  emits :{
		newContractCost: null,
		addContractCost: null
  },
	computed: {
    ...mapState({
      stateData: state => state.contractCostAdd.stateData,
    }),
		// categoryOptions() {
    //   return CATEGORY_COST;
    // },
		closingTimeOptions() {
			return CLOSING_TIME;
		},
		paymentMethodOptions() {
			return PAYMENT_METHOD;
		},
		costStatusOptions() {
			return COST_STATUS;
		},
		categoryContractCostOptions() {
			return CATEGORY_CONTRACT_COST;
		},
		shipmentDetailInfos	() {
			return this.$store.state.shipmentList.stateData.shipmentDetailInfos;
		}
  },
	watch: {
    "supplierSelected" (newVal) {
      if (newVal == null) {
        this.stateData.partner_id = "";
        return;
      }
      this.stateData.partner_id = newVal.id;
			this.stateData.supplier_code = newVal.partner_code;

			// Reset Payment Due Date
			// this.stateData.payment_due_date = null;
    },
		"stateData.supplier_inv_date" (newVal) {
			if(!newVal) return;
			if (!this.supplierSelected) return;

			// Estimate the value of Payment Due Date
			let supplier_inv_date = new Date(newVal);
			
			let estimated_date = this.getEstimatedPaymentDueDate(
				supplier_inv_date, 
				this.supplierSelected.term, 
				this.supplierSelected.closing_time,
				this.supplierSelected.exact_date
			);
			
			if (!estimated_date) return;
			this.stateData.payment_due_date = estimated_date.format("YYYY-MM-DD");			
		},
		"stateData.cost_actual_amount" (newVal) {
			if (!newVal) {
				this.stateData.cost_actual_amount = null;
				this.isError = (!this.stateData.actual_payment_date) ? false : true;
			}
			else {
				this.isError = (newVal == this.stateData.cost_amount) ? false : true;
			}
		},
		"stateData.actual_payment_date" (newVal) {
			if (!newVal) {
				if (!this.stateData.payment_due_date) {
					this.stateData.status = "";
					return;
				}
				else {
					let payment_date = this.$moment(this.stateData.payment_due_date.toString());
					this.stateData.status = utilities.getCostStatusId(payment_date, "");
				}
			}
			if(newVal && this.stateData.payment_due_date) {
				let payment_date = this.$moment(this.stateData.payment_due_date.toString());
				let actual_date = this.$moment(newVal.toString());
				this.stateData.status = utilities.getCostStatusId(payment_date, actual_date);
			}
		},
		"stateData.payment_due_date" (newVal) {
			if (!newVal) {
				this.stateData.status = "";
				return;
			}
			
			let payment_date = this.$moment(newVal.toString());
			if (newVal && this.stateData.actual_payment_date) {
				let actual_date = this.$moment(this.stateData.actual_payment_date.toString());
				this.stateData.status = utilities.getCostStatusId(payment_date, actual_date);
			}
			if (newVal && !this.stateData.actual_payment_date){
				this.stateData.status = utilities.getCostStatusId(payment_date, "");
			}
		},
		"cost_id" (newVal) {
			if (!newVal) return;
			this.$store.dispatch('contractCostAdd/getContractCostDetailAddModalByID', newVal).then(
				() => { 
					// deep copy category_cost
					this.categoryCodeId = _.cloneDeep(this.stateData.category_cost);

					let cate_contractcost = this.categoryContractCostOptions.find(
						option => option.id == this.stateData.category_cost
					);
					this.stateData.category_cost = cate_contractcost.invoice_cost_value;
					this.$store.dispatch('contractCostAdd/getListSupplier').then(
						(response) => { 
							this.isLoading = false;
							this.list_suppliers = response.listItems;
							if (this.stateData.partner_id) {
								this.supplierSelected = this.list_suppliers.filter(item => {
									return item.id == this.stateData.partner_id
								})[0];
							}
							else {
								this.supplierSelected = null;
							}
						},
						(error) => {
							this.isLoading = false;
							this.list_suppliers	= [];
							console.log(error);
							this.$swal.fire({
								icon: 'error',
								title: 'Error',
								text: 'Can not get the supplier value !'
							});
						}
					);
				},
				(error) => {
					console.log(error);
					this.stateData = {};
					this.$swal.fire({
						icon: 'error',
						title: 'Error',
						text: 'Sorry, can not view Contract Cost information !'
					});
				}
			);
		}
	},
	data() {
		return {
			calendarConfig: configs,
			today: new Date(),
			v$: useValidate(),
			costID: this.cost_id,
			categoryCodeId: null,
			color: '#696cff',
      size: '20px',
      isLoading: false,
			supplierSelected: null,
			isError: false,
			isDisabled: false,
			list_suppliers: [],
			cleaveOptions: {
        cost_amount: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
					numeralDecimalScale: 2
        },
				cost_tax: {
					numeral: true,
					numeralThousandsGroupStyle: 'thousand',
					numeralDecimalScale: 1
				},
				cost_actual_amount: {
					numeral: true,
          numeralThousandsGroupStyle: 'thousand',
					numeralDecimalScale: 2
				}
			}
		}	
	},
	created() {
  },
	mounted() {
	},
	methods: {
		handleSave() {
			this.isError = false;
			this.isDisabled = true;
			this.v$.$validate();
			if (!this.v$.$error) {
				if (this.stateData.cost_actual_amount  && 
						this.stateData.cost_actual_amount != this.stateData.cost_amount) {
					this.isError = true;
					this.isDisabled = false;
					return;
				}

				this.stateData.contract_id = this.shipmentDetailInfos.contract_id;
				// this.stateData.invoice_id = this.invoice_id;
        let request = {
          ...this.stateData
        }

				request.category_cost = this.categoryCodeId;
				request.invoice_id = this.stateData.invoice_id;
				request = _.omit(request, ["invoice_category_cost"]);
								
        this.$store.dispatch('contractCostAdd/saveContractCostModal', request).then(
          () => {
            let self = this;
						this.isDisabled = false;
            this.$swal.fire(
              'Saved',
              'Contract Cost information is saved sucessfully !',
              'success'
            ).then(function() {
							self.$store.dispatch('contractCostAdd/resetState');
							self.$emit('newContractCost', request.cost_amount, request.invoice_id, request.category_cost, request.partner_id);
							self.$refs.closeModalBtn.click();
            });
          },
          error => {
            console.log(error);
						this.isDisabled = false;
            this.$swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Failed to save Contract Cost information !'
            });
          }
        );
			}
			else {
				this.isDisabled = false;
        window.scrollTo(0,0);
      }
		},
		getClosingTime(close_time_id) {
      let query = this.closingTimeOptions.filter(item => item.id == close_time_id);
      return query[0].name
    },
    getPaymentName(val) {
      let query = this.paymentMethodOptions.filter((item) => {
        return item.value == val;
      });
      return query.length > 0 ? query[0].name_en : ""
    },
		getEstimatedPaymentDueDate (invoice_date, term, closing_time, exact_date=null) {
			let inv_date = moment(invoice_date);
			if (!this.isValidDate(inv_date)) return false;
			if (!term) return false;
			if (!closing_time) return false;

			switch (closing_time) {
				case 1:
				case 2:
				case 3:
				case 4:
				case 8:
				{	
					return inv_date.add(term, 'days');	
				}
				case 5:
				{
					return inv_date.subtract(3, 'days').add(term, 'days');
				}
				case 6:
				{
					let endOfMonth = inv_date.endOf('month');
					return endOfMonth.add(term, 'days');
				}
				case 7:
				{
					if (closing_time==7 && !exact_date) return false;
					let supplier_inv_date = moment(inv_date).format("DD");
					let exact_date_value = parseInt(exact_date);
					if (parseInt(supplier_inv_date) <= exact_date_value){
						return inv_date.add(exact_date_value + term, 'days');
					}
					else {
						return inv_date.add(exact_date_value + 30 + term, 'days');
					}
				}
			}
		},
		isValidDate(date) {
			return !isNaN(new Date(date).getTime());
		},
		closeModal() {
			this.v$.$reset();
			this.isDisabled = false;
			this.$emit('addContractCost', null);
		}
	},
	validations() {
    return {
			stateData: {
				issue_date: {
					required: helpers.withMessage('ISSUE DATE can not be empty', required)
				},
				partner_id: {
          required: helpers.withMessage('SUPPLIER NAME can not be empty', required)
        },
				category_cost: {
					required: helpers.withMessage('CATEGORY COST can not be empty', required)
				},
				cost_name: {
					required: helpers.withMessage('COST NAME can not be empty', required)
				},
				cost_amount:{ 
					required: helpers.withMessage('COST AMOUNT can not be empty', required)
				},
				cost_tax: {
					required: helpers.withMessage('COST TAX can not be empty', required)
				},
				payment_due_date: {
					required: helpers.withMessage('PAYMENT DUE DATE can not be empty', required)
				},
				supplier_inv_no: {
					required: helpers.withMessage(
						'INVOICE NO is required', 
						requiredIf (() => { return this.stateData.supplier_inv_date; })
					)
				},
				supplier_inv_date: {
					required: helpers.withMessage(
						'INVOICE DATE is required', 
						requiredIf (() => { return this.stateData.supplier_inv_no; })
					)
				},
				actual_payment_date: {
					required: helpers.withMessage(
						'ACTUAL PAYMENT DATE is required if ACTUAL AMOUNT is inputed', 
						requiredIf (() => { return this.stateData.cost_actual_amount; })
					)
				},
				cost_actual_amount: {
					required: helpers.withMessage(
						'ACTUAL AMOUNT is required if ACTUAL PAYMENT DATE is inputed', 
						requiredIf (() => { return this.stateData.actual_payment_date; })
					)
				},
				status: {
					required: helpers.withMessage('STATUS can not be empty', required)
				}
			}
		}
	}
}
</script>

<style>
	.modal-backdrop {
		/* display: none; */
		/* z-index: -1; */
		z-index: 1050 !important;
	}
	.swal2-container {
		z-index: 2000 !important;
	}
	/* #addContractCost .modal-dialog{
		float: right;
		margin-right: 3rem;
	} */
	@media (min-width: 992px) {
		#addContractCost .modal-lg {
			--bs-modal-width: 56rem;
		}
	}
</style>