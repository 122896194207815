<template>
  <!-- Content -->
  <div class="container-xxl flex-grow-1 container-p-y">
    <h4 class="fw-bold py-3 mb-4">
        <span class="text-muted fw-light">Product /</span> Add New
    </h4>
    <div class="card mb-4">
      <h5 class="card-header">General Information</h5>
      <hr class="my-0">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <div class="mb-3 col-sm-12">
              <label class="form-label">Product Name</label>
              <input 
                class="form-control" 
                type="text" 
                name="product-name"
                v-model="stateData.product_name"  
              >
              <div class="error-msg" v-if="v$.stateData.product_name.$error"> 
                {{ v$.stateData.product_name.$errors[0].$message }}
              </div>
            </div>
            <div class="col-sm-12 mb-3">
              <label class="form-label">Product Name in English</label>
              <input 
                class="form-control" 
                type="text" 
                name="product-name"
                v-model="stateData.product_name_en"  
              >
            </div>
            <div class="row">
              <div class="mb-3 col-sm-6">
                <label class="form-label">Product Code</label>
                <div class="input-group input-group-merge">
                  <span class="input-group-text"><i class="bx bx-search"></i></span>
                  <input 
                    class="form-control px-2" 
                    type="text" 
                    name="product-code"
                    v-model="stateData.product_code"
                    v-bind:disabled="isDisabled"
                  >
                  <div class="error-msg" v-if="v$.stateData.product_code.$error"> 
                    {{ v$.stateData.product_code.$errors[0].$message }}
                  </div>
                </div>
              </div>
              <div class="mb-3 col-sm-6">
                <label class="form-label">Product Unit</label>
                <VueMultiselect 
                    v-model="unitSelected" 
                    :options="productUnitOptions" 
                    placeholder="Please choose unit" 
                    label="name" 
                    :close-on-select="true"
                    :clear-on-select="false"
                  />
                  <div class="error-msg" v-if="v$.stateData.product_unit.$error"> 
                    {{ v$.stateData.product_unit.$errors[0].$message }}
                  </div>
              </div>
              <div class="mb-3 col-sm-12">
                <label class="form-label">Supplier Name</label>
                <div class="col-md-12 position-relative">
                  <VueMultiselect 
                    v-model="supplierSelected" 
                    :options="stateData.list_suppliers" 
                    placeholder="Please choose supplier" 
                    label="partner_name" 
                    :close-on-select="true"
                    :clear-on-select="false"
                  />
                  <div class="error-msg" v-if="v$.stateData.supplier_id.$error"> 
                    {{ v$.stateData.supplier_id.$errors[0].$message }}
                  </div>
                </div>
              </div>
              <div class="mb-3 col-sm-12" v-if="stateData.supplier_name">
                <label class="form-label">Supplier Code</label>
                <span class="form-control text-disabled">{{ stateData.supplier_name }}</span>
              </div>
              <div class="mb-3 col-sm-12" v-else>
              </div>
            </div>
            <div class="row">
              <div class="mb-3 col-sm-6">
                <label class="form-label">Category</label>
                <VueMultiselect 
                  v-model="categorySelected" 
                  :options="stateData.list_categories" 
                  placeholder="Please choose category" 
                  label="category_name" 
                  :close-on-select="true"
                  :clear-on-select="false"
                  v-bind:disabled="isDisabled"
                />
                <div class="error-msg" v-if="v$.stateData.product_category_id.$error"> 
                  {{ v$.stateData.product_category_id.$errors[0].$message }}
                </div>
              </div>
              <div class="mb-3 col-sm-6">
                <label class="form-label">SubCategory</label>
                <VueMultiselect 
                  v-model="subCategorySelected" 
                  :options="stateData.list_sub_categories" 
                  placeholder="Please choose category" 
                  label="sub_category_name" 
                  :close-on-select="true"
                  :clear-on-select="false"
                  v-bind:disabled="isDisabled"
                />
                <div class="error-msg" v-if="v$.stateData.product_sub_category_id.$error"> 
                  {{ v$.stateData.product_sub_category_id.$errors[0].$message }}
                </div>
              </div>
              <div class="mb-3 col-sm-12 mt-2">
                <label for="apiAccess" class="form-label">Description</label>
                <div class="position-relative">
                  <textarea class="form-control" 
                    rows="3"
                    v-model="stateData.description"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="row col-md-6">
            <div class="mb-3 col-md-10">
              <label class="form-label">Product Image </label> (Maximum 3 files)
              <input 
                class="form-control" 
                type="file" 
                id="" 
                accept="image/jpeg" 
                multiple=""
                @change="onFileChange"
              >
              <div class="mt-2" v-if="isUploadSuccess">
                <span class="fw-bold text-success">The images are uploaded to server successfully.</span>
              </div>
              <div class="mt-2" v-if="isError">
                <span class="fw-bold text-danger">The image isn't still uploaded to server.</span>
              </div>
              <div id="carouselExample" class="carousel slide w-75 mt-5" data-bs-ride="carousel" style="margin-left:6rem;">
                <ol class="carousel-indicators">
                  <li data-bs-target="#carouselExample" 
                    :data-bs-slide-to="key1" 
                    v-for="(image1, key1) in images" :key="key1"
                    :class="{active: getClassActive(key1)}"
                    class="carousel-image"
                  >
                  </li>
                </ol>
                <div class="carousel-inner">
                  <div class="carousel-item" v-for="(image, key) in images" :key="key" :class="{active: getClassActive(key)}">
                    <img class="d-block w-100" :ref="'image'" alt="First slide">
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-3 col-md-2 text-end" style="margin-top:1.85rem;">
              <button @click="clearImageFile" class="btn btn-primary">Clear</button>
            </div>
          </div>
        </div>
      </div>
      <!-- /Account -->
    </div>
    <div class="card mb-4">
      <h5 class="card-header">Detail Information</h5> 
      <hr class="my-0">
      <div class="card-body">
        <!-- Add Variation -->
        <div v-for="(variation, key) in stateData.list_variations" :key="key">
          <div class="row">
            <div class="mb-3 col-lg-6 col-xl-3 col-12 mb-0">
              <label class="form-label" for="form-repeater-3-5">VARIATION LABEL</label>
              <input type="text" class="form-control" v-model="variation.variation_label">
            </div>
            <div class="mb-3 col-lg-6 col-xl-2 col-12 mb-0">
              <label class="form-label" for="form-repeater-3-5">VARIATION SYMBOL</label>
              <input type="text" class="form-control" v-model="variation.variation_symbol">
            </div>
            <div class="mb-3 col-lg-6 col-xl-6 col-12 mb-0">
              <label class="form-label" for="form-repeater-3-5">ATTRIBUTE (COMMA SEPARATED)</label>
              <input type="text" class="form-control" v-model="variation.attributes">
            </div>
            <div class="mb-3 col-lg-12 col-xl-1 col-12 mt-2" v-if="key > 0">
              <button type="button" 
                class="btn btn-sm rounded-pill btn-icon btn-outline-danger mt-4" 
                @click="removeLastVariation"
              >
                <span class="tf-icons bx bx-minus"></span>
              </button>
            </div>
            <div class="error-msg" v-if="isErrorVariation & checkEmptyVar(variation)"> 
              The 3 fields VARIATION LABEL, SYMBOL, ATTRIBUTE can not be empty.
            </div>
          </div>
        </div>
        <div class="row">
          <div class="mb-3 mt-3 col-sm-4">
            <button class="btn btn-success me-5" @click="addMoreVariation" v-if="stateData.list_variations.length <= 3">
              <i class="bx bx-plus"></i>
              <span class="align-middle">Add More Variation</span>
            </button>
          </div>
          <div class="mb-3 mt-3 col-sm-3"></div>
          <div class="mb-3 mt-3 col-sm-5" style="text-align:right;">
            <button class="btn btn-success me-3" @click="handleClearSKURows">Clear SKU Rows</button>
            <button class="btn btn-success me-4"  @click="handleGenerateSKU">Generate SKU</button>
          </div>
          <div class="error-msg" v-if="isErrorGeneration"> 
            Please generate Product SKU for these variations above.
          </div>
        </div>
        <!-- / Add Variation -->
        <!-- Generate SKU -->
        <div class="mt-3">
          <div class="text-nowrap">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th v-for="(header, key) in list_header_variation" :key="key">
                    {{ list_header_variation[key] }}
                  </th>
                </tr>
              </thead>
              <tbody class="table-border-bottom-0">
                <tr v-for="(item, key) in stateData.list_generated_sku" :key="key">
                  <td><strong>{{ item.product_item }}</strong></td>    
                  <td v-if="item.variation_1">{{ item.variation_1 }}</td>
                  <td v-if="item.variation_2">{{ item.variation_2 }}</td>
                  <td v-if="item.variation_3">{{ item.variation_3 }}</td>
                  <td v-if="item.variation_4">{{ item.variation_4 }}</td>
                  <td><strong>{{item.sku}}</strong></td>
                  <td>
                    <cleave
                      v-model="item.quantity"
                      :options="cleaveOptions.quantity"
                      class="form-control"
                      style="text-align:right;max-width: 120px;" 
                    />
                    <!-- <div class="error-msg" v-if="isErrorQuantity & checkValidateQuantity(item.quantity)"> 
                      The quantity value is required !
                    </div> -->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- / Generate SKU -->
        <div class="row mt-5">
          <form id="" @submit.prevent="handleSave">
            <div class="mb-3 col-sm-6">
              <button type="submit" class="btn btn-primary me-3">Save Product</button>
              <router-link to="/product" data-i18n="Add Product" class="btn btn-outline-secondary w-45">
                <i class='bx bx-arrow-back' ></i>
                Back To List
              </router-link>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- / Content -->
</template>

<script>
import { utilities } from '@/services/utilities.js';
import useValidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
// import { requiredIf, numeric, maxLength} from '@vuelidate/validators';
import { mapState } from "vuex";
import VueMultiselect from 'vue-multiselect';
import _ from 'lodash';
import showConfirmDeleteAlert from "../../helper/error.js"
import {errorAlert} from "../../helper/error.js"
import { PRODUCT_UNIT } from "../../config/common.js"


export default {
  name: 'ProductAdd',
  components: {
    VueMultiselect
  },
  data() {
    return {
      v$: useValidate(),
      generation: "",
      images: [],
      supplierSelected: null,
      categorySelected: null,
      subCategorySelected: null,
      unitSelected: null,
      list_header_variation: [],
      isErrorVariation: false,
      // isErrorQuantity: false,
      list_attributes: [],
      isError: false,
      isUploadSuccess: false,
      isGenerated: false,
      isErrorGeneration: false,
      isDisabled: true,
      cleaveOptions: {
        quantity: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand'
        }
      }
    }
  },
  computed: {
    ...mapState({
      stateData: state => state.productAdd.stateData
    }),
    productUnitOptions() {
      return PRODUCT_UNIT;
    }
  },
  watch: {
    "supplierSelected" (newVal) {
      if (newVal == null) {
        this.stateData.supplier_name = "";
        this.stateData.supplier_id = "";
        return;
      }
      this.stateData.supplier_name =  newVal ? newVal.partner_code : "";
      this.stateData.supplier_id = newVal.id;
    },
    "categorySelected" (newVal) {
      this.subCategorySelected = null;
      this.stateData.product_category_id = newVal.id;
      this.$store.dispatch('productAdd/getListSubCategory', newVal.id).then(
        () => { 
          this.subCategorySelected = this.stateData.list_sub_categories.filter(item => {
            return item.id == this.stateData.product_sub_category_id
          })[0];
        },
        (error) => {
          console.log(error);
          this.$swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Can not get the supplier value !'
          });
        }
      );
    },
    "subCategorySelected" (newVal) {
      this.stateData.product_sub_category_id = newVal.id;
    },
    "unitSelected" (newVal) {
      this.stateData.product_unit = newVal.value;
    }
  },
  beforeCreate() {
    this.$store.dispatch('productAdd/resetState');
  },
  created() {
    let query_param = this.$router.currentRoute._value.query;
    if (!_.isEmpty(query_param)){
      if (_.has(query_param, "product_code")){
        this.$store.dispatch('productAdd/searchProductRegByCodeName', {"page": 1, "searchKey": query_param.product_code})
        .then(
          () => { 
            if (!this.stateData.product_code_id) {
              return;
            }
            else {
              this.stateData.product_code = query_param.product_code;
              this.unitSelected = this.productUnitOptions[0];

              // Get list supplier
              this.$store.dispatch('productAdd/getListSupplier').then(
                () => { 
                  console.log("Get supplier data successfully !!!");
                },
                (error) => {
                  console.log(error);
                  this.$swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Can not get the supplier value !'
                  });
                }
              );

              // Get Category data
              this.$store.dispatch('productAdd/getListCategory').then(
                () => { 
                  this.categorySelected = this.stateData.list_categories.filter(item => {
                    return item.id == this.stateData.product_category_id
                  })[0];
                },
                (error) => {
                  console.log(error);
                  this.$swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Can not get the supplier value !'
                  });
                }
              );
            }
          },
          (error) => {
            console.log(error);
            errorAlert("error", "Network Error",'Can not get Product Code data!');
          }
        );
      }
      else {
        return;
      }
    }
    else {
      return;
    }
  },
  mounted() {
    
  },
  methods: {
    onFileChange(e) {
      let selectedFiles = e.target.files;
      if (selectedFiles.length > 0 && selectedFiles.length <= 3) {
        this.isError = false;
        this.images = [];
        for (let i = 0; i < selectedFiles.length; i++) {
          this.images.push(selectedFiles[i]);
        }
        
        for (let i = 0; i < this.images.length; i++) {
          let reader = new FileReader();
          reader.onload = () => {
              this.$refs.image[i].src = reader.result;
            };
            reader.readAsDataURL(this.images[i]);
        }

        // Handling upload file onto server
        this.submitImageFile();
      }
    },
    submitImageFile() {
      let formData = new FormData();

      for( let i = 0; i < this.images.length; i++ ){
        let file = this.images[i];
        formData.append("files", file);
      }
      formData.append("service_name", "product");
      this.$store.dispatch('productAdd/uploadImage', formData).then(
        () => {
          this.isUploadSuccess = true;
          this.isError = false;
        },
        error => {
          console.log(error);
          this.isUploadSuccess = false;
          this.isError = true;

          this.$swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to upload image !'
          });
        }
      );
    },
    clearImageFile() {
      this.images = [];
      let items =  this.$refs["image"];
      if (items != undefined){
        items.splice(0, items.length);
      }
      this.stateData.image_link = "";
      this.isUploadSuccess = false;
      this.isError = false;
    },
    getClassActive (key) {
      return key == 0 ? "active" : "";
    },
    handleSave() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.stateData.list_variations.forEach((value) => {
          if(value.variation_label == "" || value.variation_symbol == "" || value.attributes == "") {
            this.isErrorVariation = true;
            return;
          }
          else{
            this.isErrorVariation = false;
          }
        });
        if(this.isErrorVariation) {
          window.scrollTo(0,0);
          return;
        }

        if(this.isGenerated == false) {
          this.isErrorGeneration = true;
          return;
        }
        else {
          this.isErrorGeneration = false;
        }

        this.$store.dispatch('productAdd/saveProduct', this.stateData).then(
          () => {
            let self = this;
            this.$swal.fire(
              'Saved',
              'Product is saved sucessfully !',
              'success'
            ).then(function() {
              self.$router.push('/product');
            });
          },
          error => {
            console.log(error);
            errorAlert("error", "Network Error",'Can not save Product data!');
          }
        );
      }
    },
    addMoreVariation() {
      let obj = {
        variation_label: "",
				variation_symbol: "",
				attributes: ""
      }
      if (this.stateData.list_variations.length === 0){
        this.stateData.list_variations.push({
          variation_label: "SIZE",
          variation_symbol: "SIZ",
          attributes: "S,M,L,XL,XXL"
        });
      }
      else if (this.stateData.list_variations.length === 1){
        this.stateData.list_variations.push({
          variation_label: "COLOR",
          variation_symbol: "COL",
          attributes: "RED, BLUE, GREEN"
        });
      }
      else {
        this.stateData.list_variations.push(obj);
      }
      this.isGenerated = false;
    },
    removeLastVariation() {
      showConfirmDeleteAlert()
      .then(
        (result) => {
          if(!result) return;
          this.stateData.list_variations.pop();
          this.isGenerated = false;
        }
      )
      
    },
    handleClearSKURows() {
      this.list_header_variation = {};
      this.stateData.list_generated_sku = [];
      this.isGenerated = false;
    },
    handleGenerateSKU() {
      let obj = {
        item_name: "Product Item"
      };
      this.list_attributes = [];
      this.stateData.list_generated_sku = []
      this.stateData.list_variations.forEach((value, index) => {
        // Handle header of table list
        if(value.variation_label == "" || value.variation_symbol == "" || value.attributes == "") {
          this.isErrorVariation = true;
          this.list_header_variation = {};
          return;
        }
        else {
          this.isErrorVariation = false;
          this.list_header_variation = obj;
          this.list_header_variation["variation_" + (index+1)] = value.variation_label;
        }
        let arr_attribute = value.attributes.split(",");
        arr_attribute = arr_attribute.map(item => {
          return item.trim()
        });
        this.list_attributes.push(arr_attribute)
      });
      
      if (this.isErrorVariation) return;
      this.list_header_variation["sku"] = "GENERATED SKU";
      this.list_header_variation["quantity"] = "QUANTITY";
      // Handle SKU Rows in table list
      let cartesian = this.CartesianProductSKU(this.list_attributes);
      
      let list_variation_len = this.stateData.list_variations.length;
      cartesian.forEach((item) => {
        for (let i = 0; i < list_variation_len; i++) {
          // item.insert(i*2, this.stateData.list_variations[i].variation_label)
          item.splice(i*2, 0, this.stateData.list_variations[i].variation_symbol);
        }
        item.splice(0, 0, this.stateData.product_code);
        let obj = {
          product_item: this.stateData.product_code,
          variation_1: "",
          variation_2: "",
          variation_3: "",
          variation_4: "",
          sku: "",
          quantity: null
        }
        
        for(let i=0; i < 4; i++) {
          if (item[2*(i+1)] !== undefined){
            obj["variation_"+(i+1)] = item[2*(i+1)];
          }
        }

        let hash = utilities.getHash(item.join("_"));
        obj["sku"] = utilities.generateRandomLetter(hash, 13);
        this.stateData.list_generated_sku.push(obj);
      })
      this.isGenerated = true;
    },
    CartesianProductSKU(list_array) {
      if (!Array.isArray(list_array) || list_array.length == 0) return;
      return list_array.reduce((a, b) => a.flatMap(x => b.map(y => [...x, y])), [[]]);
    },
    checkEmptyVar(variation) {
      return variation.variation_label == "" || variation.variation_symbol == "" || variation.attributes == "";
    },
    // checkValidateQuantity(quantity) {
    //   if (Number.isInteger(parseInt(quantity)) == false) return true;
    //   return !quantity || quantity <= 0;
    // }
  },
  validations() {
    return {
      stateData: {
        product_code: {
          required: helpers.withMessage('PRODUCT CODE can not be empty', required)
        },
        product_name: {
          required: helpers.withMessage('PRODUCT NAME can not be empty', required)
        },
        product_unit: {
          required: helpers.withMessage('PRODUCT UNIT can not be empty', required)
        },
        supplier_id: {
          required: helpers.withMessage('SUPPLIER NAME can not be empty', required)
        },
        product_category_id: {
          required: helpers.withMessage('CATEGORY can not be empty', required)
        },
        product_sub_category_id: {
          required: helpers.withMessage('SUBCATEGORY can not be empty', required)
        },
      }
    }
  }
}
</script>

<style>
  .text-disabled {
    background-color: #eceef1 !important;
  }
  .carousel-image {
    background-color: gray !important;
  }
</style>