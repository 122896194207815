<template>
  <!-- Content -->
  <div class="container-xxl flex-grow-1 container-p-y">
    <h4 class="fw-bold py-3 mb-4">
        <span class="text-muted fw-light">Product Code Registration /</span> Edit
    </h4>
    <div class="row">
      <div class="col-md-12">
        <div class="card mb-4">
          <h5 class="card-header">Edit Product Code</h5>
          <div class="row">
            <div class="col-md-6">
              <div class="card-body">
                <form id="formAccountSettingsApiKey" method="POST" onsubmit="return false" class="fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate">
                  <div class="row fv-plugins-icon-container">
                    <div class="mb-3 col-12">
                      <label for="apiAccess" class="form-label">Quality Control Status</label>
                      <div class="position-relative">
                        <VueMultiselect 
                          v-model="qualitySelected" 
                          :options="getQualityControlList" 
                          placeholder="Please choose category" 
                          label="name" 
                          :close-on-select="true"
                          :clear-on-select="false"
                        />
                        <div class="error-msg" v-if="v$.stateData.quality_control_status.$error"> 
                          {{ v$.stateData.quality_control_status.$errors[0].$message }}
                        </div>
                      </div>
                    </div>
                    <div class="mb-3 col-12">
                      <label class="form-label">Category</label>
                      <div class="position-relative">
                        <VueMultiselect 
                          v-model="categorySelected" 
                          :options="stateData.list_categories" 
                          placeholder="Please choose category" 
                          label="category_name" 
                          :close-on-select="true"
                          :clear-on-select="false"
                        />
                        <div class="error-msg" v-if="v$.stateData.category_id.$error"> 
                          {{ v$.stateData.category_id.$errors[0].$message }}
                        </div>
                      </div>
                    </div>
                    <div class="mb-3 col-12">
                      <label class="form-label">Sub Category</label>
                      <div class="position-relative">
                        <VueMultiselect 
                          v-model="subCategorySelected" 
                          :options="stateData.list_sub_categories" 
                          placeholder="Please choose subcategory" 
                          label="sub_category_name" 
                          :close-on-select="true"
                          :clear-on-select="false"
                        />
                        <div class="error-msg" v-if="v$.stateData.sub_category_id.$error"> 
                          {{ v$.stateData.sub_category_id.$errors[0].$message }}
                        </div>
                      </div>
                    </div>
                    <div class="mb-3 col-6">
                      <label class="form-label">Register Date</label>
                      <div class="position-relative">
                        <flat-pickr 
                          v-model="stateData.date" 
                          :config="calendarConfig" 
                          placeholder="Select a date" 
                          id="register_date"
                          name="register-date" 
                          class="form-control"
                          @change="onchange()"
                        >
                        </flat-pickr>
                        <div class="error-msg" v-if="v$.stateData.date.$error"> 
                          {{ v$.stateData.date.$errors[0].$message }}
                        </div>
                      </div>
                    </div>
                    <div class="mb-3 col-6">
                      <label class="form-label">Consective No</label>
                      <div class="position-relative">
                        <input class="form-control text-end" 
                          type="text" 
                          name="consective-no" 
                          id="consective_no" 
                          v-model="stateData.consective_no"
                          v-on:keyup=onchange()
                        >
                        <div class="error-msg" v-if="v$.stateData.consective_no.$error"> 
                          {{ v$.stateData.consective_no.$errors[0].$message }}
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mt-4">
                      <button type="submit" @click="generateCode" class="btn btn-outline-success me-2 d-grid w-100">
                        Generate Product Code
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-md-6 pe-5 d-none1">
              <div class="bg-lighter rounded p-4 mb-3 position-relative">
                <div class="d-flex align-items-center flex-wrap mt-4">
                  <h5 class="mb-0 me-3">Generated Product Code</h5> 
                </div>
                <div class="alert alert-success d-flex mt-4" role="alert">
                  <div class="d-flex flex-column ps-1">
                    <h6 class="alert-heading d-flex align-items-center fw-bold mb-3 mt-3">
                      {{stateData.product_code}}
                    </h6>
                  </div>
                </div>
                <div class="mt-3 p-1">
                  <div class="error-msg" v-if="v$.stateData.product_code.$error"> 
                    {{ v$.stateData.product_code.$errors[0].$message }}
                  </div>
                </div>
                <div class="row" style="margin-top:4rem">
                  <form id="" @submit.prevent="handleUpdate">
                    <div class="col col-sm-12 text-center">
                      <button type="submit" class="btn btn-primary me-2">Update Code</button>
                      <router-link to="/product-registration" data-i18n="Product Registration" class="btn btn-outline-secondary w-45">
                        <i class='bx bx-arrow-back' ></i>
                        Back To Definition
                      </router-link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- / Content -->
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import { configs } from '@/config/calendar.js';
import useValidate from '@vuelidate/core'
import { required, numeric, maxLength, helpers } from '@vuelidate/validators'
import { mapState } from "vuex";
import VueMultiselect from 'vue-multiselect'
import moment from 'moment'
import { QUALITY_CONTROL_STATUS } from "../../config/common.js"


export default {
  name: 'ProductRegistrationEdit',
  components: {
    flatPickr,
    VueMultiselect
  },
  data() {
    return {
      v$: useValidate(),
      calendarConfig: configs,
      categorySelected: null,
      subCategorySelected: null,
      qualitySelected: null
    }
  },
  computed: {
    ...mapState({
      stateData: state => state.productRegistrationAdd.stateData
    }),
    getSelectedCategoryName () {
      return this.categorySelected ? this.categorySelected.symbol: null;
    },
    getSelectedSubCategoryName () {
      return this.subCategorySelected ? this.subCategorySelected.symbol: null;
    },
    getQualityControlList () {
      return QUALITY_CONTROL_STATUS;
    }
  },
  watch: {
    "categorySelected" (newVal) {
      if (!newVal) return true;
      
      this.stateData.category_id = newVal.id;
      this.$store.dispatch('productRegistrationAdd/getSubCategoryList', newVal.id).then(
        () => { 
          let query = this.stateData.list_sub_categories.filter(item => {
            return item.id === this.stateData.sub_category_id
          });
          this.subCategorySelected = query[0];
        },
        (error) => {
          console.log(error);
          this.$swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Can not get the SubCategory code value !'
          });
        }
      );
    },
    "subCategorySelected" (newVal) {
      if (!newVal) return;
      this.stateData.sub_category_id = newVal.id;
    },
    "qualitySelected" (newVal) {
      if (!newVal) return;
      this.stateData.quality_control_status = newVal.value;
    }
  },
  beforeCreate() {
    this.$store.dispatch('productRegistrationAdd/resetState');
  },
  created() {
    this.$store.dispatch('productRegistrationAdd/getCategoryList').then(
      () => { 
        let paramID = this.$router.currentRoute._value.params.id;
        this.$store.dispatch('productRegistrationAdd/getProductRegCode', paramID).then(
          () => { 
            let status = this.getQualityControlList.filter(item => {
              return item.value == this.stateData.quality_control_status;
            });
            this.qualitySelected = status[0];
            
            let query = this.stateData.list_categories.filter(item => {
              return item.id === this.stateData.category_id;
            });
            this.categorySelected = query[0];
          },
          (error) => {
            console.log(error);
            this.$swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Can not get the product code value !'
            });
          }
        );
      },
      (error) => {
        console.log(error);
        this.$swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Can not get list Category data !'
        });
      }
    );
  },
  mounted () {
    
  },
  methods: {
    handleUpdate() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.$store.dispatch('productRegistrationAdd/updateCode', this.stateData).then(
          () => {
            let self = this;
            this.$swal.fire(
              'Updated',
              'Product Code is updated sucessfully !',
              'success'
            ).then(function() {
              self.$router.push('/product-registration');
            });
          },
          error => {
            console.log(error);
            this.$swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Failed to update Product Code !'
            });
          }
        );
      }
    },
    generateCode() {
      // this.$store.commit('productRegistrationAdd/generateCode');
      let selected_date = moment(this.stateData.date).format("YYMMDD");
      this.stateData.product_code = this.stateData.quality_control_status + "-" + this.getSelectedCategoryName + 
        this.getSelectedSubCategoryName + selected_date +"-" + this.stateData.consective_no;
    },
    onchange() {
      // this.stateData.product_code = "";
    }
  },
  validations() {
    return {
      stateData: {
        category_id: {
          required: helpers.withMessage('CATEGORY cannot be empty', required)
        },
        sub_category_id: {
          required: helpers.withMessage('SUB CATEGORY cannot be empty', required)
        },
        date: {
          required: helpers.withMessage('REGISTER DATE cannot be empty', required)
        },
        consective_no: { 
          required: helpers.withMessage('Consective No cannot be empty', required),
          numeric: helpers.withMessage('Please input number only', numeric),
          maxLength: helpers.withMessage('Please input maximum 3 letters', maxLength(3)),
        },
        product_code: {
          required: helpers.withMessage('Please generate new product code', required)
        },
        quality_control_status: {
          required: helpers.withMessage('QUALITY CONTROL STATUS cannot be empty', required)
        }
      }
    }
  }
}
</script>