import contractCostService from '../services/contractcost.service';
import moment from 'moment'


let today = new Date();
const getDefaultState = () => {
  return {
    contract_id: "",
    invoice_id: "",
    partner_id: null,
    supplier_code: "",
    cost_type: 2,
    issue_date: moment(today).format("YYYY-MM-DD"),
    category_cost: null,
    supplier_inv_no: null,
    supplier_inv_date: null,
    cost_name: "",
    cost_amount: null,
    cost_tax: null,
    payment_due_date: null,
    actual_payment_date: null,
    cost_actual_amount: null,
    remark: "",
    status: 1
  }
}

export const contractCostAdd = {
    namespaced: true,
    state: {
      stateData: getDefaultState()
    },
    actions: {
      resetState({commit}) {
        commit("resetState");
      },
      saveContractCost({commit}, data) {
        return contractCostService.saveContractCost(data).then(
          (response) => {
            return Promise.resolve(response);
          },
          (error) => {
            commit("saveContractCostFailure");
            return Promise.reject(error);
          }
        );
      },
      getListSupplier({commit}, page=1, size=-1, type=1) {
        return contractCostService.getListSupplier(page, size, type).then(
          (response) => {
            // commit('getListSupplierSuccess', response);
            return Promise.resolve(response);
          },
          (error) => {
            commit('getListSupplierFailure');
            return Promise.reject(error);
          }
        );
      },
      updateContractCost({commit}, data) {
        return contractCostService.updateContractCost(data).then(
          (response) => {
            return Promise.resolve(response);
          },
          (error) => {
            commit("updateContractCostFailure");
            return Promise.reject(error);
          }
        );
      },
      saveContractCostOfInvoice({commit}, data) {
        return contractCostService.saveContractCostOfInvoice(data).then(
          (response) => {
            return Promise.resolve(response);
          },
          (error) => {
            commit("saveContractCostOfInvoiceFailure");
            return Promise.reject(error);
          }
        );
      },
      saveContractCostModal({commit}, data) {
        return contractCostService.saveContractCostModal(data).then(
          (response) => {
            return Promise.resolve(response);
          },
          (error) => {
            commit("saveContractCostModalFailure");
            return Promise.reject(error);
          }
        );
      },
      getContractCostDetail({commit}, {"contract_id": contract_id, "invoice_id": invoice_id, "category_cost": category_cost}) {
        return contractCostService.getContractCostDetail({"contract_id": contract_id, "invoice_id": invoice_id, "category_cost": category_cost})
        .then(
          (response) => {
            commit('getContractCostDetailSuccess', response);
          },
          (error) => {
            // commit('getContractCostDetailFailure');
            return Promise.reject(error);
          }
        );
      },
      getContractCostDetailByID({commit}, id) {
        return contractCostService.getContractCostDetailByID(id).then(
          (response) => {
            return Promise.resolve(response);
          },
          (error) => {
            commit('getContractCostDetailByIDFailure');
            return Promise.reject(error);
          }
        );
      },
      getContractCostDetailAddModalByID({commit}, id) {
        return contractCostService.getContractCostDetailAddModalByID(id).then(
          (response) => {
            commit('getContractCostDetailAddModalByIDSuccess', response);
            // return Promise.resolve(response);
          },
          (error) => {
            commit('getContractCostDetailByIDFailure');
            return Promise.reject(error);
          }
        );
      },
      deleteContractCost({commit}, id) {
        return contractCostService.deleteContractCost(id).then(
          (response) => {
            return Promise.resolve(response);
          },
          (error) => {
            commit('deleteContractCostFailure');
            return Promise.reject(error);
          }
        );
      }
    },
    mutations: {
      resetState (state) {
        console.log("run resetState");
        Object.assign(state.stateData, getDefaultState());
      },
      // getListSupplierSuccess(state, response) {
      //   state.stateData.list_suppliers = response.listItems
      // },
      // getListSupplierFailure(state) {
      //   state.stateData.list_suppliers = [];
      // },
      getContractCostDetailSuccess(state, response) {
        state.stateData = {...state.stateData, ...response};
        state.stateData.partner_id = response.partner_id;
      },
      getContractCostDetailFailure(state) {
        Object.assign(state.stateData, getDefaultState())
      },
      getContractCostDetailByIDFailure() {
        console.log("run getContractCostDetailByIDFailure");
      },
      getContractCostDetailAddModalByIDSuccess(state, response) {
        state.stateData = {...state.stateData, ...response};
        state.stateData.partner_id = response.partner_id;
      }
    }
}