<template>
	<div class="modal fade" id="viewContractCost" data-bs-backdrop="static" aria-hidden="false" role="dialog" style="display: none;">
		<div class="modal-dialog modal-lg">
			<form class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title" id="backDropModalTitle">Contract Cost Detail</h5>
					<div class="text-end" v-if="contractCostDetail.partner_id"> 
						<div v-if="contractCostDetail.status == 1">
							<span class="badge bg-label-info mb-1">
								{{ statusOptions[0].name}}
							</span>
							<br/>
							<span>{{ getNotifyMessage(1) }}</span>
						</div>
						<div v-if="contractCostDetail.status == 2">
							<span class="badge bg-label-warning mb-1">
									{{ statusOptions[1].name}}
							</span>
							<br/>
							<span>{{ getNotifyMessage(2) }}</span>
						</div>
						<div v-if="contractCostDetail.status == 3">
							<span class="badge bg-label-danger mb-1" >
								{{ statusOptions[2].name}}
							</span>
							<br/>
							<span>{{ getNotifyMessage(3) }}</span>
						</div>
						<div v-if="contractCostDetail.status == 4">
							<span class="badge bg-label-success mb-1">
								{{ statusOptions[3].name}}
							</span>
							<br/>
							<span>{{ getNotifyMessage(4) }}</span>
						</div>
						<div v-if="contractCostDetail.status == 5">
							<span class="badge bg-label-primary mb-1">
								{{ statusOptions[4].name}}
							</span>
							<br/>
							<span>{{ getNotifyMessage(5) }}</span>
						</div>
					</div>
					<!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
				</div>
				<div class="modal-body" v-if="contractCostDetail">
					<div class="d-flex my-2">
						<div class="col-sm-7" v-if="contractCostDetail.partner_id">
							<span class="fw-bold">{{ contractCostDetail.cost_name }}</span>
						</div>
						<div class="col-sm-5 text-end" v-if="contractCostDetail.partner_id">
							<div> 
								<span class="text-primary fw-bold me-2">VND</span>
								<span class="text-primary fw-bold">{{ formatCurrency(contractCostDetail.cost_amount) }}</span>
							</div>
							<div v-if="contractCostDetail.partner_id">
								<span class="fw-bold me-2">Tax({{ contractCostDetail.cost_tax }}%):</span>
								<span class="fw-bold me-2">VND</span>
								<span class="fw-bold">{{ formatCurrency(getTaxAmount()) }}</span>
							</div>
						</div>
						<div class="col-sm-12" v-else>
							<span class="text-primary fw-bold me-2">Payment Cost Amount: </span>
							<span class="text-primary fw-bold me-2">VND</span>
							<span class="text-primary fw-bold">{{ formatCurrency(contractCostDetail.cost_amount) }}</span>
						</div>
					</div>
					<h5 class="pb-2 border-bottom mb-3" v-if="contractCostDetail.partner_id"></h5>
					<div class="info-container my-2" v-if="contractCostDetail.partner_info != undefined">
						<div class="col-sm-12 my-2">
							<span class="fw-bold me-2">Issue Date: </span>
							<span>{{ formatDate(contractCostDetail.issue_date) }}</span>
						</div>
						<div class="col-sm-12 my-2" v-if="contractCostDetail.partner_id">
							<span class="fw-bold me-2">Supplier Name: </span>
							<span>
								<a class="me-1" 
									data-bs-toggle="collapse" 
									href="#collapseExample" 
									role="button" 
									aria-expanded="true" 
									aria-controls="collapseExample"
								>
								<span class="me-3">{{ contractCostDetail.partner_info.partner_name }}</span>
							</a>
							</span>
						</div>
						<div class="ol-sm-12 my-2" v-if="contractCostDetail.partner_id">
							<div class="collapse show" id="collapseExample">
								<div class="border">
									<div class="alert mb-1" role="alert">
										<span class="alert-heading fw-bold">PAYMENT INFORMATION</span>
										<div class="row mt-3">
											<div class="col-md-3">
												<span v-if="contractCostDetail.partner_info.has_deposit == 1">HAS DEPOSIT</span>
												<span v-else>NO DEPOSIT</span>
											</div>
											<div class="col-md-3" v-if="contractCostDetail.partner_info.has_deposit == 1">
												<span class="me-1">Deposit: {{ contractCostDetail.partner_info.percent_deposit }}</span>
												<span>%</span>
											</div>
											<div class="col-md-3" v-if="contractCostDetail.partner_info.has_deposit == 1">
												<span class="me-1">Balance: {{ contractCostDetail.partner_info.percent_balance }}</span>
												<span>%</span>
											</div>
										</div>
										<div class="row mt-2">
											<div class="col-md-3">
												<span>Payment Method: </span>
												<span>{{ getPaymentName(contractCostDetail.partner_info.payment_method) }}</span>
											</div>
											<div class="col-md-3">
												<span>Term: </span>
												<span class="me-1">{{ contractCostDetail.partner_info.term }}</span>
												<span>days</span>
											</div>
											<div class="col-md-3">
												<span>Closing Time: </span>
												<span>{{ getClosingTime(contractCostDetail.partner_info.closing_time) }}</span>
											</div>
											<div class="col-md-3" v-if="contractCostDetail.partner_info.closing_time == 7">
												<span>On Exact Date: </span>
												<span>{{ contractCostDetail.partner_info.exact_date }}</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-sm-12 my-2" v-if="contractCostDetail.partner_id">
							<span class="fw-bold me-2">Supplier Code: </span>
							<span>{{ contractCostDetail.partner_info.partner_code }}</span>
						</div>
						<div class="col-sm-12 my-2" v-if="contractCostDetail.partner_id">
							<span class="fw-bold me-2">Category: </span>
							<span>{{ getCategoryName(contractCostDetail.category_cost) }}</span>
						</div>
						<div class="col-sm-12 my-2" v-if="contractCostDetail.partner_id">
							<span class="fw-bold me-2">Supplier Invoice No: </span>
							<span>{{ contractCostDetail.supplier_inv_no }}</span>
						</div>
						<div class="col-sm-12 my-2" v-if="contractCostDetail.partner_id">
							<span class="fw-bold me-2">Supplier Invoice Date: </span>
							<span>{{ formatDate(contractCostDetail.supplier_inv_date) }}</span>
						</div>
						<div class="row my-2" v-if="contractCostDetail.partner_id">
							<div class="col-md-6 mt-3 g-2">
								<div class="alert alert-primary" role="alert">
									<span class="alert-heading fw-bold me-3">Estimated Cost Amount:</span>
									<span class="fw-bold me-2">VND</span>
									<span class="fw-bold">{{ formatCurrency(getEstimateCostAmount()) }}</span>
									<br />
									<span class="fw-bold me-2">Payment Due Date:</span>
									<span class="fw-bold">{{ formatDate(contractCostDetail.payment_due_date) }}</span>
								</div>
							</div>
							<div class="col-md-6 mt-3 g-2" v-if="contractCostDetail.actual_payment_date">
								<div class="alert alert-warning" role="alert">
									<span class="alert-heading fw-bold me-2">Actual Cost Amount: </span> 
									<span class="fw-bold me-2">VND</span>
									<span class="fw-bold">{{ formatCurrency(getActualCostAmount()) }}</span>
									<br />
									<span class="fw-bold me-2">Actual Payment Date:</span>
									<span class="fw-bold">{{ formatDate(contractCostDetail.actual_payment_date) }}</span>
								</div>
							</div>
						</div>
						<div class="col-sm-12" v-if="contractCostDetail.partner_id">
							<span class="fw-bold me-2">Ramark: </span>
							<span>{{ contractCostDetail.remark }}</span>
						</div>
					</div>
				</div>
				<div class="modal-footer1">
					<div class="row my-4">
						<div class="col-md-4"></div>
						<div class="col-md-4">
							<button type="button" 
								class="btn btn-outline-secondary w-100" 
								data-bs-dismiss="modal"
								@click="closeViewModal"
							>
								<i class='bx bx-arrow-back' ></i>
								Back to Detail
							</button>
						</div>
						<div class="col-md-4"></div>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import { CATEGORY_CONTRACT_COST, COST_STATUS, CLOSING_TIME, PAYMENT_METHOD } from "../../config/common.js"
import { utilities } from "../../services/utilities.js";

export default {
	name: 'ContractCostViewModal',
  components: {
  },
  props: {
		"cost_id": {
			type: Number,
			required: false
		},
  },
  emits :{
		// closeViewModal: null
		viewContractCost: null
  },
	watch: {
		"cost_id" (newVal) {
			if (!newVal) return;
			this.$store.dispatch('contractCostAdd/getContractCostDetailByID', newVal).then(
				(response) => { 
					if (response.id) {
						this.contractCostDetail = response
					}
					else {
						this.contractCostDetail = {}
					}
				},
				(error) => {
					console.log(error);
					this.contractCostDetail = {};
					this.$swal.fire({
						icon: 'error',
						title: 'Error',
						text: 'Sorry, can not view Contract Cost information !'
					});
				}
			);
		}
	},
	data() {
		return {
			today: new Date(),
			costID: this.cost_id,
			contractCostDetail: {}
		}	
	},
	computed: {
		categoryContractCostOptions() {
      return CATEGORY_CONTRACT_COST;
    },
		statusOptions() {
      return COST_STATUS;
    },
		closingTimeOptions() {
			return CLOSING_TIME;
		},
		paymentMethodOptions() {
			return PAYMENT_METHOD;
		},
	},
	created() {
  },
	methods: {
		getNotifyMessage (value){
      let remaining_days = this.countRemainingDays(this.contractCostDetail.payment_due_date);
      let str = remaining_days == 1 ? " day" : " days";
      let result = "";
      switch (value) {
        case 1:
        case 2:
          result = "Remaining Payment Time: " + remaining_days + str;
          break;
        case 3: 
          result = "Late Payment for " + Math.abs(remaining_days) + str;
          break;
        case 4: 
          result = "Payment has already paid";
          break;
        case 5:
          result = "Already paid, but late for " + Math.abs(remaining_days) + str;
          break;
        default:
          break;
      }
      return result;
    },
		countRemainingDays(payment_due_date) {
      if (!payment_due_date) return 0;
      let due_date = this.$moment(payment_due_date.toString());
      return due_date.diff(this.today, 'days');
    },
		getCategoryName(category_code_id) {
			if (!category_code_id) return;

			return this.categoryContractCostOptions.find(item => item.id == category_code_id).invoice_cost_name;
		},
		formatDate(date) {
      if (date) {
        return utilities.changeFormatDate(date);
      }
    },
    formatCurrency(amount) {
      return utilities.getFormatCurrency(amount);
    },
		getTaxAmount () {
      if (this.contractCostDetail.cost_tax && this.contractCostDetail.cost_amount) {
        return this.contractCostDetail.cost_amount * this.contractCostDetail.cost_tax / 100;
      }
    },
    getCostAmount () {
      if (this.contractCostDetail.cost_tax && this.contractCostDetail.cost_amount) {
        let tax = this.getTaxAmount();
        return this.contractCostDetail.cost_amount + tax;
      }
    },
		getEstimateCostAmount () {
			if (this.contractCostDetail.cost_tax && this.contractCostDetail.cost_amount) {
				let tax = this.getTaxAmount();
				return this.contractCostDetail.cost_amount + tax;
			}
		},
		getActualTaxAmount () {
      if (this.contractCostDetail.cost_tax && this.contractCostDetail.cost_actual_amount) {
        return this.contractCostDetail.cost_actual_amount * this.contractCostDetail.cost_tax / 100;
      }
    },
		getActualCostAmount () {
			if (this.contractCostDetail.cost_tax && this.contractCostDetail.cost_actual_amount) {
				let tax = this.getActualTaxAmount();
				return this.contractCostDetail.cost_actual_amount + tax;
			}
		},
		getClosingTime(close_time_id) {
			if (!close_time_id) return;
      let query = this.closingTimeOptions.filter(item => item.id == close_time_id);
      return query[0].name
    },
    getPaymentName(val) {
			if (!val) return;
      let query = this.paymentMethodOptions.filter((item) => {
        return item.value == val;
      });
      return query.length > 0 ? query[0].name_en : ""
    },
		closeViewModal() {
			// this.contractCostDetail = {};
			this.$emit('viewContractCost', null);
		}
	}
}
</script>

<style>

</style>