<template>
  <!-- Content -->
  <div class="card1 mb-3">
    <div class="card-body">
      <div class="row p-sm-3 p-0">
        <div class="col-md-6 mb-md-0 mb-4">
          <div class="d-flex svg-illustration mb-4 gap-2">
          </div>
          <p class="mb-1 fw-bold">LAMODA VIETNAM COMPANY LIMITED</p>
          <p class="mb-1">Ho Chi Minh City, Vietnam</p>
          <p class="mb-0">+84 (0) 394 577 366</p>
        </div>
        <div class="col-md-6">
            <dl class="row mb-2">
            <dt class="col-sm-6 mb-2 mb-sm-0 text-md-end mt-2">
              <span class="h5 text-capitalize mb-0 text-nowrap">Invoice No</span>
            </dt>
            <dd class="col-sm-6 d-flex justify-content-md-end">
              <div class="w-px-200">
                <input type="text" 
                  class="form-control" 
                  v-model="stateData.invoice_code" 
                  disabled
                >
              </div>
            </dd>
            <dt class="col-sm-6 mb-2 mb-sm-0 text-md-end mt-2">
              <span class="fw-normal">Contract No</span>
            </dt>
            <dd class="col-sm-6 d-flex justify-content-md-end">
              <div class="w-px-200">
                <input type="text" 
                  class="form-control"
                  v-model="this.stateData.contract_code"
                  disabled
                >
              </div>
            </dd>
            <dt class="col-sm-6 mb-2 mb-sm-0 text-md-end mt-2">
                <span class="fw-normal">Issued Date</span>
            </dt>
            <dd class="col-sm-6 d-flex justify-content-md-end">
              <div class="w-px-200">
                <flat-pickr 
                  v-model="stateData.invoice_date" 
                  :config="calendarConfig" 
                  placeholder="Select a date" 
                  name="contract-date" 
                  class="form-control">
                </flat-pickr>
              </div>
            </dd>
            </dl>
        </div>
      </div>
      <hr class="my-4 mx-n4">
      <div class="row p-sm-0 p-0">
        <div class="col-md-6 col-sm-5 col-12 mb-sm-0 mb-4" v-if="stateData.customerInfo.id">
          <h6 class="pb-2 fw-bold">Invoice To:</h6>
          <div class="alert alert-warning mt-1" role="alert">
            <div class="row">
              <div class="mb-2">
                <span class="fw-bold me-3">{{ stateData.customerInfo.partner_name }}</span>
                <div class="mt-2">
                  <span class="fw-bold me-3">Address:</span>
                  {{ stateData.customerInfo.address +" "+ stateData.customerInfo.ward_name +" "+ 
                    stateData.customerInfo.district_name +" "+ stateData.customerInfo.city_name +" "+ stateData.customerInfo.country}}
                  {{ stateData.customerInfo.zipcode ? ", zipcode: " + stateData.customerInfo.zipcode : "" }}
                </div>
                <div class="mt-3">
                  <span class="fw-bold me-3">Payment method:</span>
                  {{ getPaymentName(stateData.customerInfo.payment_method) +" "+ stateData.customerInfo.term +" days "+ getClosingTime(stateData.customerInfo.closing_time) }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-7">
          <h6 class="pb-2 fw-bold">Bill To:</h6>
          <div class="alert alert-warning mt-1" role="alert">
            <div class="row">
              <div class="mb-2">
                <span class="alert-heading fw-bold mt-2">BANK INFORMATION</span><br/>
                <span class="fw-bold me-1">
                  {{ showBankName(stateData.customerInfo.bank_name) }}
                  {{ " (" + stateData.customerInfo.bank_name + ")" }}
                </span><br/>
                <span class="fw-bold me-3">Account Name:</span>{{ stateData.customerInfo.bank_account_name }}<br/>
                <span class="fw-bold me-3">Account No:</span>{{ stateData.customerInfo.bank_account_number }}<br/>
                <span class="fw-bold me-3">SWIFT code:</span>{{ stateData.customerInfo.swift_code }} <br/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr class="mx-n4">
      <div class="card-body">
        <div class="d-flex border rounded position-relative mb-3" v-for="(product, key) in stateData.list_products" :key="key">
          <invoice-product-detail
            :product="product"
            :index="key"
            @changeAmountValue="changeGrandTotalAmount"
          >
          </invoice-product-detail>
        </div>
        <!-- Grand Total Amount -->
        <div class="row col-md-12 mt-5">
          <div class="col-md-5 d-flex justify-content-end" style="margin-left: 5rem">
            <div class="invoice-calculations">
              <div class="d-flex justify-content-between mb-4">
                <span class="fw-bold w-px-200"></span>
                <span class="fw-bold">SELL</span>
              </div>
              <div class="d-flex justify-content-between mb-2">
                <span class="fw-bold w-px-200">Subtotal:</span>
                <span class="fw-semibold">{{ stateData.sell_currency }}&nbsp;&nbsp;&nbsp;{{ getFormatCurrency(subTotalSellPrice) }}</span>
              </div>
              <div class="d-flex justify-content-between mb-2">
                <span class="fw-bold w-px-200">Tax:</span>
                <span class="fw-semibold">{{ stateData.sell_currency }}&nbsp;&nbsp;&nbsp;{{ getFormatCurrency(grandTotalSellTax) }}</span>
              </div>
              <hr>
              <div class="d-flex justify-content-between mb-2">
                <span class="fw-bold w-px-200">Grand Total:</span>
                <span class="fw-semibold">{{ stateData.sell_currency }}&nbsp;&nbsp;&nbsp;{{ getFormatCurrency(stateData.grand_total_sell_price) }}</span>
              </div>
              <div class="d-flex justify-content-between mb-2">
                <span class="fw-bold w-px-200">Grand Total in VND</span>
                <span class="fw-semibold">VND&nbsp;&nbsp;&nbsp;{{ getFormatCurrency(grandTotalSellPriceVND) }}</span>
              </div>
            </div>
          </div>
          <div class="col-md-12 mt-3">
            <span class="fw-bold me-2">GRAND SELL AMOUNT: </span> 
            <span class="fw-bold text-warning">{{ getFormatCurrencyText(stateData.grand_total_sell_text) }}&nbsp;{{ stateData.sell_currency }}</span>
          </div>
        </div>
        <!-- / Grand Total Amount -->
      </div>
      <hr class="my-4">
      <div class="row">
        <div class="col-md-6">
          <div class="mb-3">
            <label for="note" class="form-label fw-semibold">Date of Shipment</label>
            <flat-pickr 
              v-model="stateData.shipment_date" 
              :config="calendarConfig" 
              placeholder="Select a date" 
              name="contract-date" 
              class="form-control"
              disabled
            >
            </flat-pickr>
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3">
            <label for="note" class="form-label fw-semibold">Country of Origin</label>
            <input type="text" 
              class="form-control" 
              v-model="stateData.country_origin"
              disabled
            >
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3">
            <label for="note" class="form-label fw-semibold">Means of Transport</label>
            <input type="text" 
              class="form-control"
              v-model="stateData.mean_transport"
              disabled
            >
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3">
            <label for="note" class="form-label fw-semibold">HAWB NO</label>
            <input type="text" 
              class="form-control"
              v-model="stateData.hawb_no"
              disabled
            >
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3">
            <label for="note" class="form-label fw-semibold">Port of Loading</label>
            <input type="text" 
              class="form-control"
              v-model="stateData.port_landing"
              disabled
            >
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3">
            <label for="note" class="form-label fw-semibold">Port of Discharge</label>
            <input type="text" 
              class="form-control"
              v-model="stateData.port_discharge"
              disabled
            >
          </div>
        </div>
      </div>
      <form @submit.prevent="handleSave">
        <div class="row mt-5">
          <div class="mb-3 col-sm-3"></div>
          <div class="mb-3 col-sm-6">
            <button type="submit" class="btn btn-primary me-3">Save Invoice, Receiving, Delivery</button>
            <router-link to="/shipment" class="btn btn-outline-secondary w-45">
              <i class='bx bx-arrow-back'></i>
              Back To List
            </router-link>
          </div>
        </div>
      </form>
    </div>
    <!-- Modal -->
    <div class="modal modal-transparent show fade" v-if="isLoading" id="basicModal" style="display: block;">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <rise-loader :color="color" :size="size" style="text-align:center;"></rise-loader>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- / Content -->
</template>

<script>
import { mapState } from "vuex";
import flatPickr from 'vue-flatpickr-component';
import { configs } from '@/config/calendar.js';
import useValidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import { CLOSING_TIME, PAYMENT_METHOD } from "../../config/common.js"
import banks from "../../../public/assets/vendor/js/bank.js"
import InvoiceProductDetail from "./InvoiceProductDetail.vue";
import {Converter} from "any-number-to-words";
import RiseLoader from 'vue-spinner/src/RiseLoader.vue'


export default {
  name: 'InvoiceAdd',
  components: {
    flatPickr,
    InvoiceProductDetail,
    RiseLoader
  },
  data() {
    return {
      v$: useValidate(),
      calendarConfig: configs,
      color: '#696cff',
      size: '20px',
      isLoading: false,
      subTotalBuyPrice: null,
      subTotalSellPrice: null,
      grandTotalBuyTax: null,
      grandTotalSellTax: null,
      grandTotalSellPriceVND: null
    }
  },
  computed: {
    ...mapState({
      stateData: state => state.shipmentAdd.stateData
    }),
    bankOptions() {
      return banks;
    },
    paymentMethodOptions() {
      return PAYMENT_METHOD;
    }
  },
  watch: {
    "stateData.grand_total_sell_price"(newVal) {
      this.changeGrandTotalAmount(newVal);
    }
  },
  created() {
    this.isLoading = true;
  },
  mounted(){
    // this.changeGrandTotalAmount(this.stateData.grand_total_sell_price);
    this.isLoading = false;
  },
  methods: {
    getFormatCurrency(value) {
      return Intl.NumberFormat().format(value);
    },
    getFormatCurrencyText(value) {
      return value.toUpperCase();
    },
    getClosingTime(close_time_id) {
      let query = CLOSING_TIME.filter(item => item.id == close_time_id);
      return query[0].name
    },
    getPaymentName(val) {
      let query = this.paymentMethodOptions.filter((item) => {
        return item.value == val;
      });
      return query.length > 0 ? query[0].name_en : ""
    },
    changeGrandTotalAmount(new_value) {
      if(new_value){
        this.subTotalBuyPrice = 0;
        this.subTotalSellPrice = 0;
        this.grandTotalBuyTax = 0;
        this.grandTotalSellTax = 0;
        this.grandTotalBuyPriceVND = 0;
        this.grandTotalSellPriceVND = 0;
        this.grandTotalProfit = 0;
        this.grandTotalProfitPercent = 0;
        this.stateData.grand_total_buy_price = 0;
        this.stateData.grand_total_sell_price = 0;
        
        this.stateData.list_products.forEach(product => {

          this.subTotalBuyPrice += (parseFloat(product.buy_price)*parseFloat(product.quantity_in));
          this.subTotalSellPrice += (parseFloat(product.sell_price)*parseFloat(product.quantity_out));

          this.grandTotalBuyTax += parseFloat(product.purchase_total_amount) - (parseFloat(product.buy_price)*parseFloat(product.quantity_in))
          this.grandTotalSellTax += parseFloat(product.sell_total_amount) - (parseFloat(product.sell_price)*parseFloat(product.quantity_out))
        });
        this.stateData.grand_total_buy_price = parseFloat(this.subTotalBuyPrice) + parseFloat(this.grandTotalBuyTax);
        this.stateData.grand_total_sell_price = parseFloat(this.subTotalSellPrice) + parseFloat(this.grandTotalSellTax);
      
        this.grandTotalBuyPriceVND = this.subTotalBuyPrice * parseFloat(this.stateData.buy_exchange_rates);
        this.grandTotalSellPriceVND = this.subTotalSellPrice * parseFloat(this.stateData.sell_exchange_rates);

        if(this.grandTotalBuyPriceVND) {
          this.grandTotalProfit = (this.subTotalSellPrice * this.stateData.sell_exchange_rates) - (this.subTotalBuyPrice * this.stateData.buy_exchange_rates);
          this.grandTotalProfitPercent = this.grandTotalProfit * 100 / (this.subTotalSellPrice * this.stateData.sell_exchange_rates)
        }
        
        if(this.stateData.grand_total_buy_price){
          this.stateData.grand_total_buy_text = new Converter().toWords(this.stateData.grand_total_buy_price);
        }
        if(this.stateData.grand_total_sell_price){
          this.stateData.grand_total_sell_text = new Converter().toWords(this.stateData.grand_total_sell_price);
        }
      }
    },
    handleSave() {
      this.isLoading = true;
      this.v$.$validate();

      if (!this.v$.$error) {
        if(this.stateData.error_lists.length > 0) {
          return;
        }
        let request = {
          ...this.stateData
        }
        this.$store.dispatch('shipmentAdd/saveShipment', request).then(
          () => {
            let self = this;
            this.isLoading = false;
            this.$swal.fire(
              'Saved',
              'Shipment information is saved sucessfully !',
              'success'
            ).then(function() {
              self.$router.push('/shipment');
            });
          },
          error => {
            this.isLoading = false;
            console.log(error);
            this.$swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Failed to save Shipment information !'
            });
          }
        );

      }
      else {
        this.isLoading = false;
        window.scrollTo(0,0);
      }
    },
    showBankName(bank_code) {
      if (!bank_code) return "";
      let bank_name = this.bankOptions.filter(item => {
        return item.code == bank_code
      });
      return bank_name.length > 0 ? bank_name[0].name : "";
    }
  },
  validations() {
    return {
      stateData: {
        invoice_date: {
          required: helpers.withMessage('Issued Date can not be empty', required)
        },
        contract_id: {
          required: helpers.withMessage('Contract Code can not be empty', required)
        }
      }
    }
  }
}
</script>