<template>
  <!-- Content -->
  <!-- Detail Style 1 -->
  <div class="row col-md-12 m-0 mt-2 p-2" v-if="item_view">
    <div class="col-md-6 col-6 mb-3 px-1 pe-2">
      <p class="mb-2">Product Name</p>
      <VueMultiselect 
        v-model="productSelected"
        :options="list_search_products" 
        placeholder="Search product name, Code" 
        label="product_name" 
        @search-change="asyncFind"
        :close-on-select="true"
        :clear-on-select="false"
        :show-labels="false"
        :loading="isLoading"
        :show-no-results="false"
        :internal-search="false"
      />
    </div>
    <div class="col-md-3 col-3 mb-3">
      <p class="mb-2 repeater-title">Product Code</p>
      <input type="text" 
        class="form-control invoice-item-price" 
        v-model="productInfo.product_code"
        disabled
      >
    </div>
    <div class="col-md-3 col-3"></div>

    <div class="col-md-3 col-3 px-1">
      <p class="mb-2 repeater-title">Quantity In</p>
      <cleave class="form-control invoice-item-price mb-3" 
        placeholder="100" style="text-align:right;"
        v-model="productInfo.quantity_in"
        :options="cleaveOptions.quantity_in"
      />
    </div>
    <div class="col-md-3 col-3">
      <p class="mb-2 repeater-title">Product Unit</p>
      <span type="text" class="form-control">{{ getProductUnitName(productInfo.product_unit) }}&nbsp;</span>
    </div>
    <div class="col-md-3 col-3">
      <p class="mb-2 repeater-title">Quantity Out</p>
      <cleave class="form-control invoice-item-price mb-3" 
        placeholder="100" 
        style="text-align:right;"
        v-model="productInfo.quantity_out"
        :options="cleaveOptions.quantity_out"
      />
    </div>
    <div class="col-md-3 col-3 pe-1">
      <p class="mb-2 repeater-title">Product Unit</p>
      <span type="text" class="form-control">{{ getProductUnitName(productInfo.product_unit) }}&nbsp;</span>
    </div>
    <div class="col-md-3 col-3 px-1">
      <p class="mb-2 repeater-title">Buy Price</p>
      <div class="input-group mb-3">
        <span class="input-group-text px-2">{{ buy_unit }}</span>
        <cleave class="form-control px-1" 
          placeholder="999,999,999,000" 
          style="text-align:right;"
          v-model="productInfo.buy_price"
          :options="cleaveOptions.buy_price"
        />
      </div>
    </div>
    <div class="col-md-3 col-3">
      <p class="mb-2 repeater-title">Buy Price in VND</p>
      <div class="input-group">
        <span class="input-group-text px-2">VND</span>
        <cleave class="form-control px-1" 
          placeholder="999,999,999,000" 
          style="text-align:right;" 
          v-model="productInfo.buy_price_vnd"
          :options="cleaveOptions.buy_price"
          disabled
        />
      </div>
    </div>
    <div class="col-md-3 col-3">
      <p class="mb-2 repeater-title">Sell Price</p>
      <div class="input-group mb-3">
        <span class="input-group-text px-2">{{ sell_unit }}</span>
        <cleave class="form-control px-1" 
          placeholder="999,999,999,000" 
          style="text-align:right;"
          v-model="productInfo.sell_price"
          :options="cleaveOptions.sell_price"
        />
      </div>
    </div>
    <div class="col-md-3 col-3 pe-1">
      <p class="mb-2 repeater-title">Sell Price in VND</p>
      <div class="input-group">
        <span class="input-group-text px-2">VND</span>
        <cleave class="form-control px-1" 
          placeholder="999,999,999,000" 
          style="text-align:right;" 
          v-model="productInfo.sell_price_vnd"
          :options="cleaveOptions.sell_total_amount"
          disabled
        />
      </div>
    </div>

    <div class="row col-md-6 col-6 px-2 pe-0">
      <p class="mb-2 repeater-title">Buy TAX</p>
      <div class="col-md-4 col-4 px-1 mx-1">
        <div class="input-group mb-3">
          <select
            class="form-select px-1" 
            name="select-tax"
            v-model="productInfo.tax_buy_1.name"
          >
            <option value="">EMPTY</option>
            <option 
              v-for="(ele, index) in taxOptions" 
              :key="index" 
              :value="ele.symbol"
            >
              {{ ele.symbol + " %"}}
            </option>
          </select>
          <cleave class="form-control px-1 me-0" 
            style="max-width: 3rem; text-align: right;"
            placeholder="0.0"
            v-model="productInfo.tax_buy_1.value"
            :options="cleaveOptions.tax"
            v-bind:disabled="!productInfo.tax_buy_1.name"
          />
        </div>
      </div>
      <div class="col-md-4 col-4 px-1" style="margin-left: -0.5rem;">
        <div class="input-group mb-3">
          <select
            class="form-select px-1" 
            name="select-tax"
            v-model="productInfo.tax_buy_2.name"
          >
            <option value="">EMPTY</option>
            <option 
              v-for="(ele, index) in taxOptions" 
              :key="index" 
              :value="ele.symbol"
            >
              {{ ele.symbol + " %"}}
            </option>
          </select>
          <cleave class="form-control px-1 me-0" 
            style="max-width: 3rem; text-align: right;"
            placeholder="0.0"
            v-model="productInfo.tax_buy_2.value"
            :options="cleaveOptions.tax"
            v-bind:disabled="!productInfo.tax_buy_2.name"
          />
        </div>
      </div>
      <div class="col-md-4 col-4 px-0">
        <div class="input-group mb-3">
          <select
            class="form-select px-1" 
            name="select-tax"
            v-model="productInfo.tax_buy_3.name"
          >
            <option value="">EMPTY</option>
            <option 
              v-for="(ele, index) in taxOptions" 
              :key="index" 
              :value="ele.symbol"
            >
              {{ ele.symbol + " %"}}
            </option>
          </select>
          <cleave class="form-control px-1 me-0" 
            style="max-width: 3rem; text-align: right;"
            placeholder="0.0"
            v-model="productInfo.tax_buy_3.value"
            :options="cleaveOptions.tax"
            v-bind:disabled="!productInfo.tax_buy_3.name"
          />
        </div>
      </div>
    </div>
    <div class="row col-md-6 col-6 px-0 pe-0" style="margin-left: 1.6rem;">
      <p class="mb-2 repeater-title">Sell TAX</p>
      <div class="col-md-4 col-4 px-1" style="margin-left: 4px;">
        <div class="input-group mb-3">
          <select
            class="form-select px-1" 
            name="select-tax"
            v-model="productInfo.tax_sell_1.name"
          >
            <option value="">EMPTY</option>
            <option 
              v-for="(ele, index) in taxOptions" 
              :key="index" 
              :value="ele.symbol"
            >
              {{ ele.symbol + " %"}}
            </option>
          </select>
          <cleave class="form-control px-1 me-0" 
            style="max-width: 3rem; text-align: right;"
            placeholder="0.0"
            v-model="productInfo.tax_sell_1.value"
            :options="cleaveOptions.tax"
            v-bind:disabled="!productInfo.tax_sell_1.name"
          />
        </div>
      </div>
      <div class="col-md-4 col-4 px-1" style="margin-left: -0.3rem;">
        <div class="input-group mb-3">
          <select
            class="form-select px-1" 
            name="select-tax"
            v-model="productInfo.tax_sell_2.name"
          >
            <option value="">EMPTY</option>
            <option 
              v-for="(ele, index) in taxOptions" 
              :key="index" 
              :value="ele.symbol"
            >
              {{ ele.symbol + " %"}}
            </option>
          </select>
          <cleave class="form-control px-1 me-0" 
            style="max-width: 3rem; text-align: right;"
            placeholder="0.0"
            v-model="productInfo.tax_sell_2.value"
            :options="cleaveOptions.tax"
            v-bind:disabled="!productInfo.tax_sell_2.name"
          />
        </div>
      </div>
      <div class="col-md-4 col-4 px-0">
        <div class="input-group mb-3">
          <select
            class="form-select px-1" 
            name="select-tax"
            v-model="productInfo.tax_sell_3.name"
          >
            <option value="">EMPTY</option>
            <option 
              v-for="(ele, index) in taxOptions" 
              :key="index" 
              :value="ele.symbol"
            >
              {{ ele.symbol + " %"}}
            </option>
          </select>
          <cleave class="form-control px-1 me-0" 
            style="max-width: 3rem; text-align: right;"
            placeholder="0.0"
            v-model="productInfo.tax_sell_3.value"
            :options="cleaveOptions.tax"
            v-bind:disabled="!productInfo.tax_sell_3.name"
          />
        </div>
      </div>
    </div>
  </div>
  <!-- / Detail Style 1 -->

  <!-- Detail Style 2 -->
  <div class="row col-md-12 m-0 mt-2 p-2" v-else>
    <div class="col-md-8 col-8 mb-1 px-1 pe-2">
      <!-- <p class="mb-2">Product Name</p> -->
      <VueMultiselect 
        v-model="productSelected"
        :options="list_search_products" 
        placeholder="Search product name, Code" 
        label="product_name" 
        @search-change="asyncFind"
        :close-on-select="true"
        :clear-on-select="false"
        :show-labels="false"
        :loading="isLoading"
        :show-no-results="false"
        :internal-search="false"
      />
    </div>
    <div class="col-md-4 col-4 mb-1 px-1">
      <input type="text" 
        class="form-control invoice-item-price py-2" 
        style="margin-left: -0.5rem;"
        v-model="productInfo.product_code"
        disabled
      >
    </div>
    <div class="col-md-2 col-2 px-1">
      <cleave class="form-control invoice-item-price px-1 mb-1" 
        placeholder="Quantity-In" style="text-align:right;"
        v-model="productInfo.quantity_in"
        :options="cleaveOptions.quantity_in"
      />
    </div>
    <div class="col-md-2 col-2 px-1">
      <div class="input-group mb-1">
        <span class="input-group-text px-1">{{ buy_unit }}</span>
        <cleave class="form-control px-1" 
          placeholder="Buy Price" 
          style="text-align:right;"
          v-model="productInfo.buy_price"
          :options="cleaveOptions.buy_price"
        />
      </div>
    </div>
    <div class="col-md-2 col-2 px-1">
      <div class="input-group">
        <span class="input-group-text px-1">VND</span>
        <cleave class="form-control px-1" 
          placeholder="Buy Price (VND)" 
          style="text-align:right;" 
          v-model="productInfo.buy_price_vnd"
          :options="cleaveOptions.buy_price"
          disabled
        />
      </div>
    </div>
    <div class="row col-md-6 col-6 px-2 pe-0">
      <div class="col-md-4 col-4 px-1 mx-1">
        <div class="input-group mb-1">
          <select
            class="form-select px-1" 
            name="select-tax"
            v-model="productInfo.tax_buy_1.name"
          >
            <option value="">EMPTY</option>
            <option 
              v-for="(ele, index) in taxOptions" 
              :key="index" 
              :value="ele.symbol"
            >
              {{ ele.symbol + " %"}}
            </option>
          </select>
          <cleave class="form-control px-1 me-0" 
            style="max-width: 3rem; text-align: right;"
            placeholder="0.0"
            v-model="productInfo.tax_buy_1.value"
            :options="cleaveOptions.tax"
            v-bind:disabled="!productInfo.tax_buy_1.name"
          />
        </div>
      </div>
      <div class="col-md-4 col-4 px-1" style="margin-left: -0.5rem;">
        <div class="input-group mb-1">
          <select
            class="form-select px-1" 
            name="select-tax"
            v-model="productInfo.tax_buy_2.name"
          >
            <option value="">EMPTY</option>
            <option 
              v-for="(ele, index) in taxOptions" 
              :key="index" 
              :value="ele.symbol"
            >
              {{ ele.symbol + " %"}}
            </option>
          </select>
          <cleave class="form-control px-1 me-0" 
            style="max-width: 3rem; text-align: right;"
            placeholder="0.0"
            v-model="productInfo.tax_buy_2.value"
            :options="cleaveOptions.tax"
            v-bind:disabled="!productInfo.tax_buy_2.name"
          />
        </div>
      </div>
      <div class="col-md-4 col-4 px-0">
        <div class="input-group mb-1">
          <select
            class="form-select px-1" 
            name="select-tax"
            v-model="productInfo.tax_buy_3.name"
          >
            <option value="">EMPTY</option>
            <option 
              v-for="(ele, index) in taxOptions" 
              :key="index" 
              :value="ele.symbol"
            >
              {{ ele.symbol + " %"}}
            </option>
          </select>
          <cleave class="form-control px-1 me-0" 
            style="max-width: 3rem; text-align: right;"
            placeholder="0.0"
            v-model="productInfo.tax_buy_3.value"
            :options="cleaveOptions.tax"
            v-bind:disabled="!productInfo.tax_buy_3.name"
          />
        </div>
      </div>
    </div>
    <div class="col-md-2 col-2 px-1">
      <!-- <p class="mb-2 repeater-title">Quantity Out</p> -->
      <cleave class="form-control invoice-item-price px-1 mb-3" 
        placeholder="Quantity Out" 
        style="text-align:right;"
        v-model="productInfo.quantity_out"
        :options="cleaveOptions.quantity_out"
      />
    </div>
    <div class="col-md-2 col-2 px-1">
      <div class="input-group mb-3">
        <span class="input-group-text px-1">{{ sell_unit }}</span>
        <cleave class="form-control px-1" 
          placeholder="Sell Price" 
          style="text-align:right;"
          v-model="productInfo.sell_price"
          :options="cleaveOptions.sell_price"
        />
      </div>
    </div>
    <div class="col-md-2 col-2 px-1 me-2">
      <div class="input-group">
        <span class="input-group-text px-1">VND</span>
        <cleave class="form-control px-1" 
          placeholder="999,999,999,000" 
          style="text-align:right;" 
          v-model="productInfo.sell_price_vnd"
          :options="cleaveOptions.sell_total_amount"
          disabled
        />
      </div>
    </div>
    <div class="row col-md-6 col-6 px-1">
      <div class="col-md-4 col-4 px-1">
        <div class="input-group mb-3">
          <select
            class="form-select px-1" 
            name="select-tax"
            v-model="productInfo.tax_sell_1.name"
          >
            <option value="">EMPTY</option>
            <option 
              v-for="(ele, index) in taxOptions" 
              :key="index" 
              :value="ele.symbol"
            >
              {{ ele.symbol + " %"}}
            </option>
          </select>
          <cleave class="form-control px-1 me-0" 
            style="max-width: 3rem; text-align: right;"
            placeholder="0.0"
            v-model="productInfo.tax_sell_1.value"
            :options="cleaveOptions.tax"
            v-bind:disabled="!productInfo.tax_sell_1.name"
          />
        </div>
      </div>
      <div class="col-md-4 col-4 px-1" style="margin-left: -0.3rem;">
        <div class="input-group mb-3">
          <select
            class="form-select px-1" 
            name="select-tax"
            v-model="productInfo.tax_sell_2.name"
          >
            <option value="">EMPTY</option>
            <option 
              v-for="(ele, index) in taxOptions" 
              :key="index" 
              :value="ele.symbol"
            >
              {{ ele.symbol + " %"}}
            </option>
          </select>
          <cleave class="form-control px-1 me-0" 
            style="max-width: 3rem; text-align: right;"
            placeholder="0.0"
            v-model="productInfo.tax_sell_2.value"
            :options="cleaveOptions.tax"
            v-bind:disabled="!productInfo.tax_sell_2.name"
          />
        </div>
      </div>
      <div class="col-md-4 col-4 px-0">
        <div class="input-group mb-3">
          <select
            class="form-select px-1" 
            name="select-tax"
            v-model="productInfo.tax_sell_3.name"
          >
            <option value="">EMPTY</option>
            <option 
              v-for="(ele, index) in taxOptions" 
              :key="index" 
              :value="ele.symbol"
            >
              {{ ele.symbol + " %"}}
            </option>
          </select>
          <cleave class="form-control px-1 me-0" 
            style="max-width: 3rem; text-align: right;"
            placeholder="0.0"
            v-model="productInfo.tax_sell_3.value"
            :options="cleaveOptions.tax"
            v-bind:disabled="!productInfo.tax_sell_3.name"
          />
        </div>
      </div>
    </div>
  </div>
  <!-- / Detail Style 2 -->

  <div class="d-flex">
    <span class="badge rounded-pill bg-danger text-white badge-notifications cursor-pointer fw-bold delete-item"
      @click="removeOneProduct"
      v-if="index != 0"
    >X</span>
  </div>
  <!-- / Content -->
</template>

<script>
import { mapState } from "vuex";
import { TAX_CONFIG, PRODUCT_UNIT } from "../../config/common.js";
import VueMultiselect from 'vue-multiselect';
import {errorAlert} from "../../helper/error.js";
import { utilities } from "../../services/utilities.js";

export default {
  name: 'ContractDetailEdit',
  components: {
    VueMultiselect
  },
  props: {
    "product": {
			type: Object,
			required: true
		},
    "index": {
      type: Number,
			required: true
    },
    "buy_unit": {
      type: String,
			default: ""
    },
    "sell_unit": {
      type: String,
			default: ""
    },
    "item_view": {
      type: Boolean,
      default: true
    }
  },
  emits :{
    removeOneProduct: null,
    changeAmountValue: null
  },
  data() {
    return {
      productInfo: this.product,
      key: this.index,
      list_search_products: [],
      page: 1,
      productSelected: null,
      isLoading: false,
      cleaveOptions: {
        buy_price: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand'
        },
        sell_price: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand'
        },
        purchase_total_amount: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalScale: 2
        },
        sell_total_amount: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalScale: 2
        },
        quantity_in: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalScale: 2
        },
        quantity_out: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalScale: 2
        },
        tax: {
          numeral: true,
          numeralDecimalScale: 1
        }
      }
    }
  },
  watch: {
    "productInfo.buy_price" (newVal) {
      if (!newVal) return;
      this.productInfo.purchase_total_amount = 
        utilities.calculateTotalAmount(
          this.productInfo.quantity_in, 
          newVal, 
          this.productInfo.tax_buy_1.value,
          this.productInfo.tax_buy_2.value,
          this.productInfo.tax_buy_3.value
        );
      
      this.productInfo.buy_price_vnd = newVal * this.stateData.buy_exchange_rates;
    },
    "productInfo.quantity_in" (newVal) {
      if (!newVal) return;
      this.productInfo.purchase_total_amount = 
        utilities.calculateTotalAmount(
          newVal,
          this.productInfo.buy_price, 
          this.productInfo.tax_buy_1.value,
          this.productInfo.tax_buy_2.value,
          this.productInfo.tax_buy_3.value
        );
    },
    "productInfo.sell_price" (newVal) {
      if (!newVal) return;
      this.productInfo.sell_total_amount = 
        utilities.calculateTotalAmount(
          this.productInfo.quantity_out, 
          newVal,
          this.productInfo.tax_sell_1.value,
          this.productInfo.tax_sell_2.value,
          this.productInfo.tax_sell_3.value
        );

      this.productInfo.sell_price_vnd = newVal * this.stateData.sell_exchange_rates;
    },
    "productInfo.quantity_out" (newVal) {
      if (!newVal) return;
      this.productInfo.sell_total_amount = 
        utilities.calculateTotalAmount( 
          newVal,
          this.productInfo.sell_price,
          this.productInfo.tax_sell_1.value,
          this.productInfo.tax_sell_2.value,
          this.productInfo.tax_sell_3.value
        );
    },
    "productInfo.tax_buy_1.name" (newVal) {
      if (!newVal) {
        this.productInfo.tax_buy_1.value = null;
        this.productInfo.tax_buy_1.id = null;
        return;
      }
      let query = this.taxOptions.filter(item => item.symbol == newVal);
      // this.productInfo.tax_buy_1.value = query[0].value;
      this.productInfo.tax_buy_1.id = query[0].id;
      if (!this.productInfo.tax_buy_value_1) {
        this.productInfo.tax_buy_1.value = query[0].value;
      }
    },
    "productInfo.tax_buy_1.value" (newVal) {
      let tax_value = !newVal ? 0: newVal;
      this.productInfo.purchase_total_amount = 
        utilities.calculateTotalAmount(
          this.productInfo.quantity_in,
          this.productInfo.buy_price, 
          tax_value,
          this.productInfo.tax_buy_2.value,
          this.productInfo.tax_buy_3.value
        );
    },
    "productInfo.tax_buy_2.name" (newVal) {
      if (!newVal) {
        this.productInfo.tax_buy_2.value = null;
        this.productInfo.tax_buy_2.id = null;
        return;
      }
      let query = this.taxOptions.filter(item => item.symbol == newVal);
      // this.productInfo.tax_buy_2.value = query[0].value;
      this.productInfo.tax_buy_2.id = query[0].id;
      if (!this.productInfo.tax_buy_value_2) {
        this.productInfo.tax_buy_2.value = query[0].value;
      }
    },
    "productInfo.tax_buy_2.value" (newVal) {
      let tax_value = !newVal ? 0: newVal;
      this.productInfo.purchase_total_amount = 
        utilities.calculateTotalAmount(
          this.productInfo.quantity_in,
          this.productInfo.buy_price, 
          this.productInfo.tax_buy_1.value,
          tax_value,
          this.productInfo.tax_buy_3.value
        );
    },
    "productInfo.tax_buy_3.name" (newVal) {
      if (!newVal) {
        this.productInfo.tax_buy_3.value = null;
        this.productInfo.tax_buy_3.id = null;
        return;
      }
      let query = this.taxOptions.filter(item => item.symbol == newVal);
      // this.productInfo.tax_buy_3.value = query[0].value;
      this.productInfo.tax_buy_3.id = query[0].id;
      if (!this.productInfo.tax_buy_value_3) {
        this.productInfo.tax_buy_3.value = query[0].value;
      }
    },
    "productInfo.tax_buy_3.value" (newVal) {
      let tax_value = !newVal ? 0: newVal;
      this.productInfo.purchase_total_amount = 
        utilities.calculateTotalAmount(
          this.productInfo.quantity_in,
          this.productInfo.buy_price, 
          this.productInfo.tax_buy_1.value,
          this.productInfo.tax_buy_2.value,
          tax_value
        );
    },
    "productInfo.tax_sell_1.name" (newVal) {
      if (!newVal) {
        this.productInfo.tax_sell_1.value = null;
        this.productInfo.tax_sell_1.id = null;
        return;
      }
      let query = this.taxOptions.filter(item => item.symbol == newVal);
      // this.productInfo.tax_sell_1.value = query[0].value;
      this.productInfo.tax_sell_1.id = query[0].id;
      if (!this.productInfo.tax_sell_value_1) {
        this.productInfo.tax_sell_1.value = query[0].value;
      }
    },
    "productInfo.tax_sell_1.value" (newVal) {
      let tax_value = !newVal ? 0: newVal;
      this.productInfo.sell_total_amount = 
        utilities.calculateTotalAmount( 
          this.productInfo.quantity_out,
          this.productInfo.sell_price,
          tax_value,
          this.productInfo.tax_sell_2.value,
          this.productInfo.tax_sell_3.value
        );
    },
    "productInfo.tax_sell_2.name" (newVal) {
      if (!newVal) {
        this.productInfo.tax_sell_2.value = null;
        this.productInfo.tax_sell_2.id = null;
        return;
      }
      let query = this.taxOptions.filter(item => item.symbol == newVal);
      // this.productInfo.tax_sell_2.value = query[0].value;
      this.productInfo.tax_sell_2.id = query[0].id;
      if (!this.productInfo.tax_sell_value_2) {
        this.productInfo.tax_sell_2.value = query[0].value;
      }
    },
    "productInfo.tax_sell_2.value" (newVal) {
      let tax_value = !newVal ? 0: newVal;
      this.productInfo.sell_total_amount = 
        utilities.calculateTotalAmount( 
          this.productInfo.quantity_out,
          this.productInfo.sell_price,
          this.productInfo.tax_sell_1.value,
          tax_value,
          this.productInfo.tax_sell_3.value
        );
    },
    "productInfo.tax_sell_3.name" (newVal) {
      if (!newVal) {
        this.productInfo.tax_sell_3.value = null;
        this.productInfo.tax_sell_3.id = null;
        return;
      }
      let query = this.taxOptions.filter(item => item.symbol == newVal);
      // this.productInfo.tax_sell_3.value = query[0].value;
      this.productInfo.tax_sell_3.id = query[0].id;
      if (!this.productInfo.tax_sell_value_3) {
        this.productInfo.tax_sell_3.value = query[0].value;
      }
    },
    "productInfo.tax_sell_3.value" (newVal) {
      let tax_value = !newVal ? 0: newVal;
      this.productInfo.sell_total_amount = 
        utilities.calculateTotalAmount( 
          this.productInfo.quantity_out,
          this.productInfo.sell_price,
          this.productInfo.tax_sell_1.value,
          this.productInfo.tax_sell_2.value,
          tax_value
        );
    },
    "productInfo.purchase_total_amount" (newVal) {
      this.$emit('changeAmountValue', newVal);
    },
    "productInfo.sell_total_amount" (newVal) {
      this.$emit('changeAmountValue', newVal);
    },
    "productSelected" (newVal) {
      if (!newVal) return;

      this.productInfo.product_id = newVal.id;
      this.productInfo.product_code = newVal.product_code;
      this.productInfo.product_code_id = newVal.product_code_id;
      this.productInfo.product_unit = newVal.product_unit
    },
    "stateData.buy_exchange_rates" (newVal) {
      if (!newVal) return;
      this.productInfo.buy_price_vnd = this.productInfo.buy_price * newVal;
      this.$emit('changeAmountValue', newVal);
    },
    "stateData.sell_exchange_rates" (newVal) {
      if (!newVal) return;
      this.productInfo.sell_price_vnd = this.productInfo.sell_price * newVal;
      this.$emit('changeAmountValue', newVal);
    }
  },
  computed: {
    ...mapState({
      stateData: state => state.contractAdd.stateData
    }),
    taxOptions() {
      return TAX_CONFIG;
    },
    productUnits() {
      return PRODUCT_UNIT;
    }
  },
  created() {
    // Handling Product Name selected
    for (let i=1; i<=3; i++) {
      if(this.productInfo["tax_buy_name_"+i]) {
        this.productInfo["tax_buy_"+i] = {
          id: i,
          name: this.productInfo["tax_buy_name_"+i],
          value: this.productInfo["tax_buy_value_"+i]
        }
      }
      else {
        this.productInfo["tax_buy_"+i] = {
          id: i,
          name: "",
          value: ""
        }
      }

      if(this.productInfo["tax_sell_name_"+i]) {
        this.productInfo["tax_sell_"+i] = {
          id: i,
          name: this.productInfo["tax_sell_name_"+i],
          value: this.productInfo["tax_sell_value_"+i]
        }
      }
      else {
        this.productInfo["tax_sell_"+i] = {
          id: i,
          name: "",
          value: ""
        }
      }
    }
  }, 
  mounted() {
    this.productInfo.buy_price_vnd = this.productInfo.buy_price * this.stateData.buy_exchange_rates;
    this.productInfo.sell_price_vnd = this.productInfo.sell_price * this.stateData.sell_exchange_rates;
    this.asyncFind(this.productInfo.product_code);
    
  },
  methods: {
    asyncFind(query) {
      if (query == undefined) return;
      if (query.length < 3) return;

      this.isLoading = true
      
      // Get List products belong to input value
      this.$store.dispatch('contractAdd/searchProduct', {"page": this.page, "searchKey":  query})
      .then(
        (response) => { 
          this.isLoading = false;
          this.list_search_products = response.listItems;
          if (this.productInfo.product_code){
            this.productSelected = this.list_search_products[0];
            this.$emit('changeAmountValue', this.productSelected.id);
          }
        },
        (error) => {
          this.isLoading = false;
          console.log(error);
          errorAlert("error", "Network Error",'Can not get search data!');
        }
      );
    },
    removeOneProduct() {
      this.$emit('removeOneProduct', this.index);
    },
    getProductUnitName(unit) {
      if(unit == undefined) return "";
      let punit = this.productUnits.filter(item => {
        return item.value == unit;
      });
      if(punit.length > 0) {
        return punit[0].name;
      }
      else {
        return "";
      }
    }
  },
}
</script>

<style>
  .delete-item {
    padding: 0.2rem 0.37rem !important;
  }
</style>