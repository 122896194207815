import API from "@/config/api.js";
import PartnerService from "../services/partner.service"
import FixedCostService from "./fixedcost.service"


class ContractCostService {
  getListSupplier(page, size, type) {
    return PartnerService.getListData(page, size, type)
  }

  saveContractCost(request) {
    return FixedCostService.saveFixedCost(request)
  }

  getContractCostInfo(fixedCostID) {
		return FixedCostService.getFxiedCostInfo(fixedCostID)
	}

  updateContractCost(request) {
		return FixedCostService.updateFixedCost(request)
	}

  searchContractCost(conditions) {
    return FixedCostService.searchFixedCost(conditions)
  }

  deleteContractCost(fixedCostID) {
    return FixedCostService.deleteFixedCost(fixedCostID)
  }

  saveContractCostOfInvoice(request) {
    return API.post('cost/contract_cost_detail/add', request)
		.then(
			(response) => {
        return response.data;
			},
			(error) => {
        return Promise.reject(error);
			}
		)
  }

  
  saveContractCostModal(request) {
		return API.put(`cost/contract_cost_modal/edit/${request.id}`, request)
		.then(
			(response) => {
			return response.data;
			},
			(error) => {
			return Promise.reject(error);
			}
		)
	}

  getContractCostDetail (data) {
    return API.get(`cost/contract_cost_detail/?contract_id=${data.contract_id}&invoice_id=${data.invoice_id}&category_cost=${data.category_cost}`)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
  }

  getContractCostDetailByID (costID) {
    return API.get(`cost/contract_cost_detail/view/${costID}`)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
  }

  getContractCostDetailAddModalByID (costID) {
    return this.getContractCostDetailByID(costID);
  }
}

export default new ContractCostService();