import API from "@/config/api.js";
import {API_FORM} from "@/config/api.js";
import CategoryService from '../services/category.service.js';


class SubCategoryService {

	getListCategory(page, size) {
		return CategoryService.getListData(page, size)
	}
	
	uploadImage(formdata) {
		return API_FORM.post('common/upload_image', formdata)
		.then(
			(response) => {
				return response.data;
			},
			(error) => {
				return Promise.reject(error);
			}
		)
	}

	saveSubCategory(data) {
		return API.post('subcategory/add', {
			product_category_id: data.product_category_id,
			sub_category_name: data.sub_category_name,
			symbol: data.symbol,
			description: data.description,
			image_link: data.image_link,
		})
		.then(
			(response) => {
				return response.data;
			},
			(error) => {
				return Promise.reject(error);
			}
		)
	}

	getListSubCategory(page, size) {
    let size_text = size !== undefined ? "&size="+size : "";
    return API.get(`subcategory/?page=${page}` + size_text)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
  }

  getListSubCategoryByCategoryId(categoryId) {
    return API.get(`subcategory/category/${categoryId}`)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
  }

	searchSubCategory(data) {
    return API.get(`subcategory/search/?searchkey=${data.searchKey}&page=${data.page}`)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
  }

	deleteSubCategory(categoryId) {
    return API.delete(`subcategory/delete/${categoryId}`)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
  }

	getSubCategoryInfo(categoryId) {
		return API.get(`subcategory/${categoryId}`)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
	}

	updateSubCategory(data) {
    return API.put(`subcategory/edit/${data.id}`, data)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
  }
}

export default new SubCategoryService();