import statisticRevenueService from '../services/statistic_revenue.service';
import moment from 'moment'


const getDefaultState = () => {
  return {
    totalItems: 0,
    listItems: [],
    listMonthItems: [],
    list_suppliers: [],
    list_customers: []
  }
}

export const statisticRevenue = {
  namespaced: true,
  state: {
    stateData: getDefaultState()
  },
  actions: {
    resetState({commit}) {
      commit("resetState");
    },
    searchRevenue({commit}, conditions) {
      return statisticRevenueService.searchRevenue(conditions).then(
        (response) => {
          commit('searchRevenueSuccess', response);
          return Promise.resolve(response);
        },
        (error) => {
          commit('searchRevenueFailure');
          return Promise.reject(error);
        }
      );
    },
    exportStatisticFile ({commit}, conditions) {
      return statisticRevenueService.exportStatisticFile(conditions).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          commit('exportStatisticFileFailure');
          return Promise.reject(error);
        }
      );
    },
    getListSupplier({commit}, page=1, size=-1, type=1) {
			return statisticRevenueService.getListSupplier(page, size, type).then(
				(response) => {
					// commit('getListSupplierSuccess', response);
					return Promise.resolve(response);
				},
				(error) => {
					commit('getListSupplierFailure');
					return Promise.reject(error);
				}
			);
		},

    getListCustomer({commit}, page=1, size=-1, type=2) {
			return statisticRevenueService.getListSupplier(page, size, type).then(
				(response) => {
					// commit('getListCustomerSuccess', response);
					return Promise.resolve(response);
				},
				(error) => {
					commit('getListCustomerFailure');
					return Promise.reject(error);
				}
			);
		},
  },
  mutations: {
    resetState (state) {
      console.log("run resetState");
      Object.assign(state.stateData, getDefaultState())
    },
    searchRevenueSuccess(state, response) {
      let new_list = [];
      if (response.totalItems != 0){
        let first_month = moment(response.listItems[0].year_month).format("MM");
        let first_year = moment(response.listItems[0].year_month).format("YYYY");
        let obj = {
          "year_month": first_year.toString() +  "-" + first_month.toString(),
          "total_quantity": 0,
          "total_sales_amount": 0,
          "total_purchase_amount": 0,
          "total_profit_amount": 0,
          "total_profit_rate": 0
        };
        response.listItems.forEach((item, indx, array) => {
          let item_month = moment(item.year_month).format("MM");
          let item_year = moment(item.year_month).format("YYYY");
          let item_month_next = moment(array[indx].year_month).format("MM");
          let item_year_next = moment(array[indx].year_month).format("YYYY");
          if (indx < array.length - 1) {
            item_month_next = moment(array[indx+1].year_month).format("MM");
            item_year_next = moment(array[indx+1].year_month).format("YYYY");
          }
          
          if(parseInt(item_month) == parseInt(first_month) && parseInt(item_year) == parseInt(first_year)){
            obj.total_quantity += item.total_quantity;
            obj.total_sales_amount += item.total_sales_amount;
            obj.total_purchase_amount += item.total_purchase_amount;
            obj.total_profit_amount += item.total_profit_amount;
            obj.total_profit_rate = obj.total_profit_amount * 100 / obj.total_sales_amount;
            if ((indx == array.length - 1) || 
              (item_month_next.toString() !== item_month.toString()) || 
              (item_year_next.toString() !== item_year.toString())) {

              new_list.push(obj);
              first_month = item_month_next;
              first_year = item_year_next;
              obj = {
                "year_month": first_year.toString() +  "-" + first_month.toString(),
                "total_quantity": 0,
                "total_sales_amount": 0,
                "total_purchase_amount": 0,
                "total_profit_amount": 0,
                "total_profit_rate": 0
              };
            }
          }
        });
      }

      state.stateData.totalItems = response.totalItems;
      state.stateData.listItems = response.listItems;
      state.stateData.listMonthItems = new_list;
    },
    searchRevenueFailure() {
      console.log("run searchRevenueFailure");
    },
    exportStatisticFileFailure() {
      console.log("run exportStatisticFileFailure");
    },
		getListSupplierFailure(state) {
			state.stateData.list_suppliers = [];
		},
    getListCustomerFailure(state) {
			state.stateData.list_customers = [];
		},
  },
  getters: {

  }
}