<template>
  <!-- Content -->
  <div class="container-xxl flex-grow-1 container-p-y">
    <h4 class="fw-bold py-3 mb-4">
        <span>Shipment Revenue Statistic</span>
    </h4>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <h5 class="card-header">Search Condition</h5>
          <div class="card-body">
            <div class="row mb-5">
              <div class="col-12">
                <div class="row g-3">
                  <div class="col-12 col-sm-3 col-lg-2">
                    <label class="form-label">Start Date</label>
                    <flat-pickr 
                      :config="calendarConfig" 
                      placeholder="Select a date" 
                      id="register_date"
                      name="register-date" 
                      class="form-control"
                      v-model="conditions.start_date"
                    >
                    </flat-pickr>
                    <div class="error-msg" v-if="v$.conditions.start_date.$error"> 
                      {{ v$.conditions.start_date.$errors[0].$message }}
                    </div>
                  </div>
                  <div class="col-12 col-sm-3 col-lg-2">
                    <label class="form-label">End Date</label>
                    <flat-pickr 
                      :config="calendarConfig" 
                      placeholder="Select a date" 
                      id="register_date"
                      name="register-date" 
                      class="form-control"
                      v-model="conditions.end_date"
                    >
                    </flat-pickr>
                    <div class="error-msg" v-if="error_date"> 
                      Wrong End Date Value !
                    </div>
                  </div>
                  <div class="col-12 col-sm-3 col-lg-3">
                    <label class="form-label">Contract No</label>
                    <input type="text" 
                      class="form-control dt-input"
                      v-model="conditions.contract_no"  
                    >
                  </div>
                  <div class="col-12 col-sm-3 col-lg-3">
                    <label class="form-label">Invoice No</label>
                    <input type="text" 
                      class="form-control dt-input"
                      v-model="conditions.invoice_no"
                    >
                  </div>
                  <div class="col-12 col-sm-3 col-lg-2 text-center">
                    <button class="btn btn-outline-primary" 
                      style="margin-top: 1.9rem;"
                      @click="handleSearch"
                    >
                      Search
                    </button>
                  </div>
                  <div class="col-12 col-sm-5 col-lg-5">
                    <label class="form-label">Supplier</label>
                    <VueMultiselect 
                      v-model="supplierSelected" 
                      :options="stateData.list_suppliers" 
                      placeholder="Please choose supplier" 
                      label="partner_name" 
                      :show-labels="false"
                      :multiple="true" 
                      :close-on-select="false"
                      track-by="id"
                    />
                  </div>
                  <div class="col-12 col-sm-5 col-lg-5">
                    <label class="form-label">Customer</label>
                    <VueMultiselect 
                      v-model="customerSelected" 
                      :options="stateData.list_customers" 
                      placeholder="Please choose supplier" 
                      label="partner_name" 
                      :show-labels="false"
                      :multiple="true" 
                      :close-on-select="false"
                      track-by="id"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="table-responsive text-nowrap1">
              <table class="table">
                <thead v-if="isSearch">
                  <tr>
                    <th class="table-padding text-center">Year-Month</th>
                    <th class="table-padding text-center">Product Name</th>
                    <th class="table-padding text-center">Supplier Name</th>
                    <th class="table-padding text-center">Customer Name</th>
                    <th class="table-padding text-center">Contract Code</th>
                    <th class="table-padding text-center">Invoice Code</th>
                    <th class="table-padding text-center">Qtty (pcs)</th>
                    <th class="table-padding text-center">Sales Amount</th>
                    <th class="table-padding text-center">Purchase Amount</th>
                    <th class="table-padding text-center">Profit Rate</th>
                    <th class="table-padding text-center">Profit Amount</th>
                  </tr>
                </thead>
                <tbody class="table-border-bottom-0">
                  <template v-for="(item_sum, index_sum) in stateData.listMonthItems" :key="index_sum">
                    <template v-for="(item, index) in stateData.listItems" :key="index">
                      <tr v-if="checkMonthYear(item_sum.year_month, item.year_month)" style="font-size: 0.8rem !important;">
                        <td class="table-padding">{{ item.year_month }}</td>
                        <td class="table-padding">
                          <div class="text-truncate" style="max-width: 100px;">
                            <span v-tooltip="item.product_name">
                              {{ item.product_name }}
                            </span>
                          </div>
                        </td>
                        <td class="table-padding">{{ item.supplier_name }}</td>
                        <td class="table-padding">{{ item.customer_name }}</td>
                        <td class="table-padding">{{ item.contract_code }}</td>
                        <td class="table-padding">{{ item.invoice_code }}</td>
                        <td class="table-padding text-end">{{ formatCurrency(item.total_quantity) }}</td>
                        <td class="table-padding text-end">{{ formatCurrency(item.total_sales_amount) }}</td>
                        <td class="table-padding text-end">{{ formatCurrency(item.total_purchase_amount) }}</td>
                        <td class="table-padding text-end">{{ item.total_profit_rate.toFixed(2) + " %" }}</td>
                        <td class="table-padding text-end">{{ formatCurrency(item.total_profit_amount) }}</td>
                      </tr>
                    </template>
                    <tr class="fw-bold table-success" style="font-size: 0.8rem !important;">
                      <td class="table-padding">{{ getYearMonth(item_sum.year_month) }}</td>
                      <td colspan="5" class="fw-bold text-center">SUMMARY OF MONTH</td>
                      <td class="table-padding text-end">{{ formatCurrency(item_sum.total_quantity) }}</td>
                      <td class="table-padding text-end">{{ formatCurrency(item_sum.total_sales_amount) }}</td>
                      <td class="table-padding text-end">{{ formatCurrency(item_sum.total_purchase_amount) }}</td>
                      <td class="table-padding text-end">{{ item_sum.total_profit_rate.toFixed(2) + " %" }}</td>
                      <td class="table-padding text-end">{{ formatCurrency(item_sum.total_profit_amount) }}</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <div class="text-end mt-5" v-if="stateData.totalItems > 0">
              <button class="btn btn-outline-warning" @click="handleExportFile">
                Export Excel File
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal modal-transparent show fade" v-if="isLoading" id="basicModal" style="display: block;">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <rise-loader :color="color" :size="size" style="text-align:center;"></rise-loader>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- / Content -->
</template>

<script>
import { mapState } from "vuex";
import RiseLoader from 'vue-spinner/src/RiseLoader.vue'
import flatPickr from 'vue-flatpickr-component';
import { configs } from '@/config/calendar.js';
import useValidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import { errorAlert } from "../../helper/error.js"
import { utilities } from "../../services/utilities.js";
import { saveAs } from 'file-saver';
import axios from 'axios';
import VueMultiselect from 'vue-multiselect'


export default {
  name: 'ShipmentRevenue',
  components: {
    RiseLoader,
    flatPickr,
    VueMultiselect,
  },
  data() {
    return {
      color: '#696cff',
      size: '20px',
      isLoading: false,
      domain: process.env.VUE_APP_DOMAIN_FILE_SERVER,
      calendarConfig: configs,
      v$: useValidate(),
      conditions: {
        start_date: "",
        end_date: "",
        invoice_no: "",
        contract_no: ""
      },
      error_date: false,
      isSearch: false,
      supplierSelected: null,
      customerSelected: null
    }
  },
  computed: {
    ...mapState({
      stateData: state => state.statisticRevenue.stateData
    }),
  },
  watch: {
    "supplierSelected" (newVal) {
      console.log("newVal: ", newVal);
    }
  },
  beforeCreate() {
    this.$store.dispatch('statisticRevenue/resetState');
  },
  created() {
    // Get list supplier
    this.$store.dispatch('statisticRevenue/getListSupplier').then(
      (response) => { 
        this.stateData.list_suppliers = response.listItems;
      },
      (error) => {
        console.log(error);
        this.$swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Can not get the supplier value !'
        });
      }
    );

    // Get list customer
    this.$store.dispatch('statisticRevenue/getListCustomer').then(
      (response) => { 
        this.stateData.list_customers = response.listItems;
      },
      (error) => {
        console.log(error);
        this.$swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Can not get the customer value !'
        });
      }
    );
  },
  methods: {
    handleSearch() {
      this.v$.$validate();
      if (!this.v$.$error) {
        if (this.conditions.end_date){
          if (!this.checkConditionDate()) {
            this.error_date = true;
            return;
          }
          else {
            this.error_date = false;
          }
        }
        
        this.isLoading = true;
        
        // Reset result list data
        this.$store.dispatch('statisticRevenue/resetState').then(
          () => {
            this.isSearch = true;
            // Handling search revenue
            this.$store.dispatch('statisticRevenue/searchRevenue', this.conditions).then(
              () => {
                this.isLoading = false;
              },
              error => {
                console.log(error);
                this.isLoading = false;
                errorAlert("error", "Network Error",'Can not get search data!');
              }
            );
          }
        );
      }
    },
    checkConditionDate() {
      let startDate = this.$moment(this.conditions.start_date.toString());
      let endDate = this.$moment(this.conditions.end_date.toString());
      return startDate.diff(endDate, 'days') <= 0;
    },
    formatCurrency(value) {
      return utilities.getFormatCurrency(value);
    },
    checkMonthYear(item_sum_ym, item_ym) {
      let year_month = this.$moment(item_ym.toString()).format("YYYY-MM");
      let year_month_sum = this.$moment(item_sum_ym.toString()).format("YYYY-MM");
      return year_month.toString() === year_month_sum.toString();
    },
    getYearMonth(ym) {
      if (!ym) return "";
      return this.$moment(ym.toString(), "YYYY-MM-DD").format('MMMM YYYY');
    },
    handleExportFile() {
      this.v$.$validate();
      if (!this.v$.$error) {
        if (this.conditions.end_date){
          if (!this.checkConditionDate()) {
            this.error_date = true;
            return;
          }
          else {
            this.error_date = false;
          }
        }
        
        this.isLoading = true;
        this.$store.dispatch('statisticRevenue/exportStatisticFile', this.conditions).then(
          (response) => {
            this.isLoading = false;
            this.downloadFile(response.file_url, "");
          },
          error => {
            console.log(error);
            this.isLoading = false;
            errorAlert("error", "Network Error",'Can not export file!');
          }
        );
      }
    },
    downloadFile(url, code) {
      let code_name = code ? code + "-" : ""
      axios
        .get(this.domain+url, {responseType: 'blob'})
        .then(response => {
            saveAs(response.data, code_name + this.getNameFile(url));
        })
    },
    getNameFile(file_name) {
      if (!file_name) return "";
      let file_type = file_name.split(".");
      let split_file =  file_name.split("/");
      return split_file[split_file.length-1].split("___")[0] +"."+file_type[file_type.length-1];
    }
  },
  validations() {
    return {
      conditions: {
        start_date: {
          required: helpers.withMessage('START DATE is required', required)
        }
      }
    }
  }
}
</script>

<style scoped>
  .border-label-primary {
    border: 3px solid #dedef5 !important;
  }
  .table-padding {
    padding: 0.8rem 0.2rem 0.8rem 0.2rem;
  }
</style>