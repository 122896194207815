import API from "@/config/api.js";
import PartnerService from "../services/partner.service"
// import {API_FORM} from "@/config/api.js";
// import moment from 'moment'


class FixedCostService {
  getListSupplier(page, size, type) {
    return PartnerService.getListData(page, size, type)
  }

  saveFixedCost(request) {
    return API.post('cost/add', request)
		.then(
			(response) => {
        return response.data;
			},
			(error) => {
        return Promise.reject(error);
			}
		)
  }

  getFxiedCostInfo(fixedCostID) {
		return API.get(`cost/${fixedCostID}`)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
	}

  updateFixedCost(request) {
		return API.put(`cost/edit/${request.id}`, request)
		.then(
			(response) => {
			return response.data;
			},
			(error) => {
			return Promise.reject(error);
			}
		)
	}


  searchFixedCost(conditions) {
    return API.get(`cost/search/?start_date=${conditions.start_date}&end_date=${conditions.end_date}&category_cost=${conditions.category_cost}&partner_id=${conditions.partner_id}&supplier_inv_code=${conditions.supplier_invoice_code}&status=${conditions.status}&page=${conditions.page}`)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
  }


  deleteFixedCost(fixedCostID) {
    return API.delete(`cost/delete/${fixedCostID}`)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
  }
}

export default new FixedCostService();