<template>
	<!-- Content -->
  <div class="container-xxl flex-grow-1 container-p-y">
    <h4 class="fw-bold py-3 mb-4">
        <span>Fixed Cost List</span>
        <div class="dt-buttons btn-add-fcost">
          <router-link to="/fixed-cost/add" data-i18n="Create New Fixed Cost" class="dt-button btn btn-primary">
            <i class="bx bx-plus me-2"></i>
            Create New
          </router-link>
        </div>
    </h4>
    <div class="row g-4 mb-4">
      <div class="col-sm-12 col-xl-12">
				<div class="card">
					<div class="card-body">
            <div class="col-12">
              <div class="row">
                <div class="col-sm-3">
                  <label class="form-label">Start Date</label>
                  <flat-pickr 
                    :config="calendarConfig" 
                    placeholder="Select a date" 
                    id="register_date"
                    name="register-date" 
                    class="form-control"
                    v-model="conditions.start_date" 
                  >
                  </flat-pickr>
                  <div class="error-msg" v-if="v$.conditions.start_date.$error"> 
                    {{ v$.conditions.start_date.$errors[0].$message }}
                  </div>
                </div>
                <div class="col-sm-3">
                  <label class="form-label">End Date</label>
                  <flat-pickr 
                    :config="calendarConfig" 
                    placeholder="Select a date" 
                    id="register_date"
                    name="register-date" 
                    class="form-control"
                    v-model="conditions.end_date" 
                  >
                  </flat-pickr>
                  <div class="error-msg" v-if="error_date"> 
                    Wrong End Date Value !
                  </div>
                  <div class="error-msg" v-if="v$.conditions.end_date.$error"> 
                    {{ v$.conditions.end_date.$errors[0].$message }}
                  </div>
                </div>
                <div class="col-sm-3">
                  <label class="form-label">Category Fixed Cost</label>
                  <select class="form-select" 
                    v-model="conditions.category_cost"
                  >
                    <option value="0">All</option>
                    <template v-for="(item, index) in categoryOptions" :key="index">
                      <option :value="item.id">
                        {{ item.category_name }}
                      </option>
                    </template>
                  </select>
                </div>
                <div class="col-sm-3">
                  <label class="form-label">Status</label>
                  <select 
                    class="form-select text-capitalize" 
                    v-model="conditions.status"
                  >
                    <option value="0">All</option>
                    <template v-for="(item, index) in statusOptions" :key="index">
                      <option :value="item.id">{{ item.name }}</option>
                    </template>
                  </select>
                </div>
                <div class="col-sm-6 mt-3">
                  <label for="" class="form-label">Supplier Name</label>
                  <VueMultiselect 
                    v-model="supplierSelected" 
                    :options="stateData.list_suppliers" 
                    placeholder="Please choose supplier" 
                    label="partner_name" 
                    :show-labels="false"
                    :multiple="true" 
                    :close-on-select="false"
                    track-by="id"
                  />
                </div>
                <div class="col-sm-4 mt-3">
                  <label class="form-label">Supplier Invoice Code</label>
                  <input type="text" 
                    class="form-control" 
                    placeholder="INV_001, INV_002,..."
                    v-model="supplier_inv_code_str"
                  >
                </div>
                <div class="col-sm-2 text-end mt-3">
                  <button class="btn btn-outline-primary w-75" 
                    style="margin-top: 1.9rem;"
                    @click="handleSearch"
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
					</div>
				</div>
			</div>
		</div>
    <div class="row">
			<!-- Fixed Cost List -->
			<div class="col-md-12">
				<div class="card">
					<div class="card-body" v-if="hadSearched">
            <div class="col-md-12 row mb-1">
              <div class="col-md-6 alert alert-warning text-center">
                <span class="fw-bold me-2">TOTAL REMAINING:</span>
                <span class="fw-bold me-2">VND</span>
                <span class="fw-bold">{{ formatCurrency(stateData.total_lists_amount.total_remaining) }}</span>
              </div>
              <div class="col-md-6 alert alert-success text-center">
                <span class="fw-bold me-2">TOTAL PAID:</span>
                <span class="fw-bold me-2">VND</span>
                <span class="fw-bold">{{ formatCurrency(stateData.total_lists_amount.total_paid) }}</span>
              </div>
            </div>
            <div class="col-md-12 row mb-3">
              <div class="col-md-2 text-center">
                <span class="badge bg-label-info">
                  {{ statusOptions[0].name }}
                </span>
                <div class="mt-2">
                  <span class="me-2 text-info">VND</span>
									<span class="text-info">{{ formatCurrency(stateData.total_lists_amount.total_unpaid) }}</span>
								</div>
              </div>
              <div class="col-md-3 text-center">
                <span class="badge bg-label-warning">
                  {{ statusOptions[1].name }}
                </span>
                <div class="mt-2">
									<span class="me-2 text-warning">VND</span>
									<span class="text-warning">{{ formatCurrency(stateData.total_lists_amount.total_payment_due_soon) }}</span>
								</div>
              </div>
              <div class="col-md-3 text-center">
                <span class="badge bg-label-danger">
                  {{ statusOptions[2].name }}
                </span>
                <div class="mt-2">
									<span class="me-2 text-danger">VND</span>
									<span class="text-danger">{{ formatCurrency(stateData.total_lists_amount.total_overdue_payment) }}</span>
								</div>
              </div>
              <div class="col-md-2 text-center">
                <span class="badge bg-label-success">
                  {{ statusOptions[3].name }}
                </span>
                <div class="mt-2">
									<span class="me-2 text-success">VND</span>
									<span class="text-success">{{ formatCurrency(stateData.total_lists_amount.total_paid_on_time) }}</span>
								</div>
              </div>
              <div class="col-md-2 text-center">
                <span class="badge bg-label-primary">
                  {{ statusOptions[4].name }}
                </span>
                <div class="mt-2">
									<span class="me-2 text-primary">VND</span>
									<span class="text-primary">{{ formatCurrency(stateData.total_lists_amount.total_paid_late) }}</span>
								</div>
              </div>
            </div> 
            <table class="table table-hover">
              <thead>
                <tr>
                  <th class="p-1">Category</th>
                  <th class="text-start p-1">Supplier Name</th>
                  <th class="p-1">Cost Name</th>
                  <th class="text-center p-1">Cost Amount</th>
                  <th class="text-center p-1">Due Date</th>
                  <th class="text-center p-1">Status</th>
                  <th class="text-center p-1">Actions</th>
                </tr>
              </thead>
              <tbody class="table-border-bottom-0">
                <tr v-for="(item, index) in stateData.listItems" :key="index">
                  <td class="width-20-percent p-2">{{ getCategoryName(item.category_cost) }}</td>
                  <td class="width-20-percent p-2">
                    <div>
                      <span v-tooltip="item.partner_name">
                        <strong>{{ item.partner_short_name }}</strong>
                      </span>
                    </div>
                  </td>
                  <td class="width-20-percent p-2">{{ item.cost_name }}</td>
                  <td class="text-end p-2">{{ formatCurrency(item.cost_amount) }}</td>
                  <td class="width-15-percent text-center p-2">{{ formatDate(item.payment_due_date) }}</td>
                  <td class="text-center p-2">
                    <span class="badge bg-label-info" v-if="item.status == 1">
                      {{ statusOptions[0].name}}
                    </span>
                    <span class="badge bg-label-warning" v-if="item.status == 2">
                      {{ statusOptions[1].name}}
                    </span>
                    <span class="badge bg-label-danger" v-if="item.status == 3">
                      {{ statusOptions[2].name}}
                    </span>
                    <span class="badge bg-label-success" v-if="item.status == 4">
                      {{ statusOptions[3].name}}
                    </span>
                    <span class="badge bg-label-primary" v-if="item.status == 5">
                      {{ statusOptions[4].name}}
                    </span>
                  </td>
                  <td class="p-1">
                    <div class="d-flex align-items-center">
                      <a href="#" 
                        class="text-body me-3" 
                        data-bs-toggle="offcanvas" 
                        data-bs-target="#offcanvasScroll"
                        @click="handleShowDetail(item.id)"
                      >
                        <i class="bx bx-show mx-1"></i>
                      </a>
                      <router-link :to='"fixed-cost/edit/" + item.id' class="text-body me-3">
                        <i class='bx bx-edit-alt'></i>
                      </router-link>
                      <a class="text-body icon-delete" @click="deleteFixedCost(item.id)">
                        <i class='bx bx-x-circle'></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <br/>
            <div class="row mx-2">
              <div class="col-sm-12 col-md-6">
                <div class="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">
                </div>
              </div>
              <div class="col-sm-12 text-center">
                <button type="button" 
                  class="btn rounded-pill btn-outline-primary" 
                  v-if="isShowButtonLoadMore"
                  @click="handleShowMoreItems"
                >
                  <span class="spinner-border text-white me-2" v-if="isLoadMore"></span>
                  Load more items...
                </button>
              </div>
            </div>
					</div>
				</div>
			</div>
    </div>

    <!-- OffCanvas from the right -->
    <div class="row">
      <div class="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" 
        id="offcanvasScroll" aria-labelledby="offcanvasScrollLabel" aria-modal="true" role="dialog" 
        style="visibility: visible; width:65%;">
        <div class="offcanvas-body my-auto mx-0 flex-grow-0" v-if="Object.entries(fixedCostDetail).length > 0">
          <div class="d-flex my-1">
            <div class="col-sm-7">
              <span class="fw-bold">{{ fixedCostDetail.cost_name }}</span>
            </div>
            <div class="col-sm-5 text-end">
              <div class=""> 
                <div v-if="fixedCostDetail.status == 1">
                  <span class="badge bg-label-info mb-1">
                    {{ statusOptions[0].name}}
                  </span>
                  <br/>
                  <span>{{ getNotifyMessage(1) }}</span>
                </div>
                <div v-if="fixedCostDetail.status == 2">
                  <span class="badge bg-label-warning mb-1">
                      {{ statusOptions[1].name}}
                  </span>
                  <br/>
                  <span>{{ getNotifyMessage(2) }}</span>
                </div>
                <div v-if="fixedCostDetail.status == 3">
                  <span class="badge bg-label-danger mb-1" >
                    {{ statusOptions[2].name}}
                  </span>
                  <br/>
                  <span>{{ getNotifyMessage(3) }}</span>
                </div>
                <div v-if="fixedCostDetail.status == 4">
                  <span class="badge bg-label-success mb-1">
                    {{ statusOptions[3].name}}
                  </span>
                  <br/>
                  <span>{{ getNotifyMessage(4) }}</span>
                </div>
                <div v-if="fixedCostDetail.status == 5">
                  <span class="badge bg-label-primary mb-1">
                    {{ statusOptions[4].name}}
                  </span>
                  <br/>
                  <span>{{ getNotifyMessage(5) }}</span>
                </div>
             </div>
            </div>
          </div>
          <h5 class="pb-2 border-bottom mb-3"></h5>
          <div class="info-container my-2">
            <div class="col-sm-12 my-2">
              <span class="fw-bold me-2">Issue Date: </span>
              <span>{{ formatDate(fixedCostDetail.issue_date) }}</span>
            </div>
            <div class="col-sm-12 my-2" v-if="fixedCostDetail.partner_info">
              <span class="fw-bold me-2">Supplier Name: </span>
              <span>{{ fixedCostDetail.partner_info.partner_name }}</span>
            </div>
            <div class="col-sm-12 my-2" v-if="fixedCostDetail.partner_info">
              <span class="fw-bold me-2">Supplier Code: </span>
              <span>{{ fixedCostDetail.partner_info.partner_code }}</span>
            </div>
            <div class="col-sm-12 my-2">
              <span class="fw-bold me-2">Category: </span>
              <span>{{ getCategoryName(fixedCostDetail.category_cost) }}</span>
            </div>
            <div class="col-sm-12 my-2">
              <span class="fw-bold me-2">Supplier Invoice No: </span>
              <span>{{ fixedCostDetail.supplier_inv_no }}</span>
            </div>
            <div class="col-sm-12 my-2">
              <span class="fw-bold me-2">Supplier Invoice Date: </span>
              <span>{{ formatDate(fixedCostDetail.supplier_inv_date) }}</span>
            </div>
            <div class="row my-2">
              <div class="col-md-6 mt-3 g-2">
                <div class="alert alert-primary" role="alert">
                  <span class="alert-heading fw-bold me-3">Estimated Cost Amount:</span>
                  <span class="fw-bold me-2">VND</span>
                  <span class="fw-bold">{{ formatCurrency(fixedCostDetail.cost_amount) }}</span>
                  <br />
                  <span class="alert-heading fw-bold me-3">Tax ({{ fixedCostDetail.cost_tax }}%) :</span>
                  <span class="fw-bold me-2">VND</span>
                  <span class="fw-bold">{{ formatCurrency(getTaxAmount()) }}</span>
                  <br />
                  <span class="alert-heading fw-bold me-3">Total Amount (+Tax):</span>
                  <span class="fw-bold me-2">VND</span>
                  <span class="fw-bold">{{ formatCurrency(getCostAmount()) }}</span>
                  <br />
                  <span class="fw-bold me-2">Payment Due Date:</span>
                  <span class="fw-bold">{{ formatDate(fixedCostDetail.payment_due_date) }}</span>
                </div>
              </div>
              <div class="col-md-6 mt-3 g-2" v-if="fixedCostDetail.actual_payment_date">
                <div class="alert alert-warning" role="alert">
                  <span class="alert-heading fw-bold me-2">Actual Cost Amount: </span> 
                  <span class="fw-bold me-2">VND</span>
                  <span class="fw-bold">{{ formatCurrency(fixedCostDetail.cost_actual_amount) }}</span>
                  <br />
                  <span class="alert-heading fw-bold me-3">Tax ({{ fixedCostDetail.cost_tax }}%):</span>
                  <span class="fw-bold me-2">VND</span>
                  <span class="fw-bold">{{ formatCurrency(getTaxActualAmount()) }}</span>
                  <br />
                  <span class="alert-heading fw-bold me-2">Total Amount (+Tax): </span> 
                  <span class="fw-bold me-2">VND</span>
                  <span class="fw-bold">{{ formatCurrency(getCostActualAmount()) }}</span>
                  <br />
                  <span class="fw-bold me-2">Actual Payment Date:</span>
                  <span class="fw-bold">{{ formatDate(fixedCostDetail.actual_payment_date) }}</span>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <span class="fw-bold me-2">Remark: </span>
              <span>{{ fixedCostDetail.remark }}</span>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-md-3">
              <router-link :to='"/fixed-cost/edit/" + fixedCostDetail.id'  data-i18n="Contract Registration" class="btn btn-primary w-100">
                Edit Fixed Cost
              </router-link>
            </div>
            <div class="col-md-3">
              <button type="button" class="btn btn-outline-secondary w-100" data-bs-dismiss="offcanvas">
                <i class='bx bx-arrow-back' ></i>
                Back to List
              </button>
            </div>
            <div class="col-md-6"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- Offcanvas from the right-->

    <!-- Modal -->
    <div class="modal modal-transparent show fade" v-if="isLoading" id="basicModal" style="display: block;">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <rise-loader :color="color" :size="size" style="text-align:center;"></rise-loader>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- / Content -->
</template>

<script>
import { mapState } from "vuex";
import RiseLoader from 'vue-spinner/src/RiseLoader.vue'
import flatPickr from 'vue-flatpickr-component';
import { configs } from '@/config/calendar.js';
import VueMultiselect from 'vue-multiselect'
import { CATEGORY_COST, COST_STATUS } from "../../config/common.js"
import { errorAlert } from "../../helper/error.js"
import { utilities } from "../../services/utilities.js";
import useValidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import showConfirmDeleteAlert from "../../helper/error.js"
import {deletedAlert} from "../../helper/error.js"


export default {
	name: 'FixedCostList',
  components: {
    RiseLoader,
    flatPickr,
    VueMultiselect
  },
	data() {
    return {
      page: 1,
      showPaging: false,
      color: '#696cff',
      size: '20px',
      isLoading: false,
      today: new Date(),
      calendarConfig: configs,
      supplierSelected: null,
      supplier_inv_code_str: "",
      hadSearched: false,
      error_date: false,
      conditions: {
        start_date: "",
        end_date: "",
        category_cost: 0,
        partner_id: [],
        supplier_invoice_code: [],
        status: 0
      },
      v$: useValidate(),
      fixedCostDetail: {},
      isShowButtonLoadMore: false,
      isLoadMore: false
		}
	},
  computed: {
    ...mapState({
      stateData: state => state.fixedCostList.stateData,
      totalItems: state => state.fixedCostList.stateData.totalItems
    }),
    categoryOptions() {
      return CATEGORY_COST;
    },
    statusOptions() {
      return COST_STATUS;
    }
  },
  watch: {
    "supplierSelected" (newVal) {
      if (newVal == null) return;

      this.conditions.partner_id.length = 0;
      newVal.forEach(item => {
        this.conditions.partner_id.push(item.id);
      });
    },
    "supplier_inv_code_str" () {
      this.conditions.supplier_invoice_code.length = 0;
    }
  },
  beforeCreate() {
    this.$store.dispatch('fixedCostList/resetState');
    this.page = 1;
  },
  created() {
    this.conditions.start_date = this.$moment(this.today).startOf('month').format('YYYY-MM-DD');
    this.conditions.end_date = this.$moment(this.today).endOf('month').format('YYYY-MM-DD');
    // Get list supplier
    this.$store.dispatch('statisticRevenue/getListSupplier').then(
      (response) => { 
        this.stateData.list_suppliers = response.listItems;
      },
      (error) => {
        console.log(error);
        this.$swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Can not get the supplier value !'
        });
      }
    );

    // Get list customer
    this.$store.dispatch('statisticRevenue/getListCustomer').then(
      (response) => { 
        this.stateData.list_customers = response.listItems;
      },
      (error) => {
        console.log(error);
        this.$swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Can not get the customer value !'
        });
      }
    );
  },
  methods: {
    handleSearch() {
      this.v$.$validate();
      if (!this.v$.$error) {
        if (!this.checkConditionDate()) {
          this.error_date = true;
          return;
        }
        else {
          this.error_date = false;
        }

        if (this.supplier_inv_code_str) {
          this.conditions.supplier_invoice_code.length = 0;

          this.supplier_inv_code_str.split(",").forEach(item => {
            this.conditions.supplier_invoice_code.push(item.trim());
          });
        }

        this.page = 1;
        this.conditions["page"] = this.page;
        this.isSearch = true;
        this.hadSearched = true;
        this.isLoading = true;
        
        this.stateData.listItems.length = 0;
        
        this.$store.dispatch('fixedCostList/searchFixedCost', this.conditions).then(
          (response) => {
            this.isLoading = false;
            this.stateData.currentPage = response.currentPage;
            this.stateData.totalItems = response.totalItems;
            this.stateData.totalPages = response.totalPages;
            this.stateData.total_lists_amount = response.total_lists_amount;
            if (response.listItems.length > 0) {
              // Estimate showing load more button
              if (this.stateData.totalPages > this.stateData.currentPage) {
                this.isShowButtonLoadMore = true;
                this.isLoadMore = false;
              }
              else {
                this.isShowButtonLoadMore = false;
                this.isLoadMore = false;
              }

              if (this.conditions.status != 0){
                response.listItems.forEach((item) => {
                  let status_id = item.status;                  
                  if (parseInt(this.conditions.status) == (parseInt(status_id))) {
                    item.status = status_id;
                    this.stateData.listItems.push(item);
                  }
                });
              }
              else {
                this.stateData.listItems = response.listItems; 
              }
            }
            else {
              this.isShowButtonLoadMore = false;
              this.stateData.listItems = response.listItems; 
            }
          },
          error => {
            console.log(error);
            this.isLoading = false;
            errorAlert("error", "Network Error",'Can not get search data!');
          }
        );
      }
    },
    handleShowMoreItems() {
      this.isLoadMore = true;
      this.page += 1;
      this.conditions["page"] = this.page;

      this.$store.dispatch('fixedCostList/searchFixedCost', this.conditions).then(
        (response) => {
          this.isLoading = false;
          this.stateData.currentPage = response.currentPage;
          this.stateData.totalItems = response.totalItems;
          this.stateData.totalPages = response.totalPages;
          if (response.listItems.length > 0) {
            // Estimate showing load more button
            if (this.stateData.totalPages > this.stateData.currentPage) {
              this.isShowButtonLoadMore = true;
              this.isLoadMore = false;
            }
            else {
              this.isShowButtonLoadMore = false;
              this.isLoadMore = false;
            }

            if (this.conditions.status != 0){
              response.listItems.forEach((item) => {
                let status_id = item.status;                  
                if (parseInt(this.conditions.status) == (parseInt(status_id))) {
                  item.status = status_id;
                  this.stateData.listItems.push(item);
                }
              });
            }
            else {
              response.listItems.forEach((item) => {
                this.stateData.listItems.push(item);
              });
            }
          }
          else {
            this.stateData.listItems = response.listItems; 
          }
        },
        error => {
          console.log(error);
          this.isLoading = false;
          errorAlert("error", "Network Error",'Can not get search data!');
        }
      );
    },
    getCategoryName(category_code_id) {
      if (category_code_id) {
        return this.categoryOptions.filter(item => item.id == category_code_id)[0].category_name;
      }
    },
    countRemainingDays(payment_due_date) {
      if (!payment_due_date) return 0;
      let due_date = this.$moment(payment_due_date.toString());
      return due_date.diff(this.today, 'days');
    },
    getNotifyMessage (value){
      let remaining_days = this.countRemainingDays(this.fixedCostDetail.payment_due_date);
      let str = remaining_days == 1 ? " day" : " days";
      let result = "";
      switch (value) {
        case 1:
        case 2:
          result = "Remaining Payment Time: " + remaining_days + str;
          break;
        case 3: 
          result = "Late Payment for " + Math.abs(remaining_days) + str;
          break;
        case 4: 
          result = "Payment has already paid";
          break;
        case 5:
          result = "Already paid, but late for " + Math.abs(remaining_days) + str;
          break;
        default:
          break;
      }
      return result;
    },
    formatDate(date) {
      if (date) {
        return utilities.changeFormatDate(date);
      }
    },
    formatCurrency(amount) {
      return utilities.getFormatCurrency(amount);
    },
    checkConditionDate() {
      let startDate = this.$moment(this.conditions.start_date.toString());
      let endDate = this.$moment(this.conditions.end_date.toString());
      return startDate.diff(endDate, 'days') <= 0;
    },
    deleteFixedCost(fixedCostID) {
      showConfirmDeleteAlert()
      .then(
        (result) => {
          if(!result) return;

          this.$store.dispatch('fixedCostList/deleteFixedCost', fixedCostID).then(
            () => { 
              this.isLoading = false;
              this.showPaging = true;
              deletedAlert();
              
              // Remove item from list
              this.stateData.listItems = this.stateData.listItems.filter(item => item.id != fixedCostID);
            },
            (error) => {
              this.isLoading = false;
              this.showPaging = false;
              let resp = error.response;
              if (resp.status == 406){
                errorAlert("error", "Delete Not Allowed", resp.data.detail);
              }
              else {
                errorAlert("error", "Network Error", "Failed to deleted Fixed Cost data!");
              }
              
            }
          );
        }
      )
    },
    handleShowDetail(fixedCostID) {
      this.isLoading = true;
      this.$store.dispatch('fixedCostList/getFxiedCostInfo', fixedCostID).then(
        (response) => { 
          this.isLoading = false;
          this.fixedCostDetail = response;
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
          this.fixedCostDetail = {};
          errorAlert("error","Network Error",'Can not get Fixed Cost information !');
        }
      );
    },
    getTaxAmount () {
      if (this.fixedCostDetail.cost_tax && this.fixedCostDetail.cost_amount) {
        return this.fixedCostDetail.cost_amount * this.fixedCostDetail.cost_tax / 100;
      }
    },
    getCostAmount () {
      if (this.fixedCostDetail.cost_tax && this.fixedCostDetail.cost_amount) {
        let tax = this.getTaxAmount();
        return this.fixedCostDetail.cost_amount + tax;
      }
    },
    getTaxActualAmount () {
      if (this.fixedCostDetail.cost_tax && this.fixedCostDetail.cost_actual_amount) {
        return this.fixedCostDetail.cost_actual_amount * this.fixedCostDetail.cost_tax / 100;
      }
    },
    getCostActualAmount () {
      if (this.fixedCostDetail.cost_tax && this.fixedCostDetail.cost_actual_amount) {
        let tax = this.getTaxActualAmount();
        return this.fixedCostDetail.cost_actual_amount + tax;
      }
    },
    // getTotalPaid() {
    //   if (this.stateData.listItems.length > 0) {
    //     let total = 0;
    //     this.stateData.listItems.forEach(item => {
    //       let status_id = item.status;
    //       if (status_id == this.statusOptions[3].id || status_id == this.statusOptions[4].id) {
    //         // total += item.cost_amount + (item.cost_amount * item.cost_tax / 100);
    //         total += item.cost_amount;
    //       }
    //     });
    //     return total;
    //   }
    // },
    // getTotalUnPaid() {
    //   if (this.stateData.listItems.length > 0) {
    //     let total = 0;
    //     this.stateData.listItems.forEach(item => {
    //       let status_id = item.status;
    //       if (status_id == this.statusOptions[0].id || 
    //           status_id == this.statusOptions[1].id || 
    //           status_id == this.statusOptions[2].id) {

    //         // total += item.cost_amount + (item.cost_amount * item.cost_tax / 100);
    //         total += item.cost_amount;
    //       }
    //     });
    //     return total;
    //   }
    // },
    // getAmountForStatus(status_id) {
    //   let total = 0;
    //   if (!status_id) return total;
    //   if (this.stateData.listItems.length > 0) {
    //     this.stateData.listItems.forEach(item => {
    //       if (item.status == status_id) {
    //         total += item.cost_amount;
    //       }
    //     });
    //   }
    //   return total;
    // }
  },
  validations() {
    return {
      conditions: {
        start_date: {
          required: helpers.withMessage('START DATE is required', required)
        },
        end_date: {
          required: helpers.withMessage('END DATE is required', required)
        }
      }
    }
  }
}
</script>

<style scoped>
  .btn-add-fcost {
    float: right;
    margin-top: -6px;
  }
  .text-white {
    color: white;
  }
</style>